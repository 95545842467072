// Config
import paymentTypeConstants from '@constants/paymentTypeConstants';

export const paymentTypeConfig: Record<string, string> = {
  [paymentTypeConstants.TOKENIZED_PAYMENT_CARD]: 'payment-details-item-tokenized-card',
  [paymentTypeConstants.CREDIT_CARD]: 'payment-details-item-card',
  [paymentTypeConstants.SAVED_PAYMENT_CARD]: 'payment-details-item-card',
};

export const paymentTypeLabels: Record<string, string> = {
  clearpay: 'Clearpay',
  giftCard: 'Gift card',
  giftVoucher: 'Gift voucher',
  posCredit: 'John Lewis & Partners Payment Plans',
  klarna: 'Klarna',
};
