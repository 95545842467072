// Types
import { FeatureFlagType } from 'types/FeatureFlag.types';

const unionWithDevFeatures = (bffFeatures: FeatureFlagType[]): FeatureFlagType[] => {
  const localFeatures: FeatureFlagType[] = window.env.FEATURES || [];

  const updatedLocalFeatures = localFeatures.map((localFeature) => {
    const bffFeature = bffFeatures.find((feature) => feature.id === localFeature.id && feature.impressionId);
    return bffFeature ? { ...localFeature, impressionId: bffFeature.impressionId } : localFeature;
  });

  return Array.from(
    new Map([...bffFeatures, ...updatedLocalFeatures].map((feature) => [feature.id, feature])).values(),
  );
};

export default unionWithDevFeatures;
