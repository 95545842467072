import { destroyForm } from 'jl-design-system/redux/actions/form/formActions';
import { push, replace } from 'connected-react-router';

import {
  APPS_ACCESS_TOKEN_ERROR,
  AUTH0_CALLBACK,
  AUTH0_CLAIM_ORDER_CALLBACK,
  AUTH0_HEALTH_CHECK,
  AUTH0_REGISTRATION_CALLBACK,
  AUTH0_UNAVAILABLE,
  LOGIN_RESET,
  ANALYTICS_UPDATE_PAGEINFO,
} from '../../../constants/actionConstants';
import {
  URL_AUTH0_HEALTH_CHECK,
  URL_CALLBACK_CLAIM_ORDER,
  URL_CALLBACK_LOGIN_AUTHENTICATED_V4,
  URL_CALLBACK_LOGIN_GUEST_V4,
  URL_CALLBACK_REGISTRATION,
} from '../../../constants/endpointConstants';
import {
  initLogging,
  reportErrorToNewRelic,
} from '../../../utils/logging/logging-utils';
import { removeStorageItem } from '../../../utils/storage/storage';
import { resetApp } from '../reset/resetActions';
import { GUEST_FORM_ID } from '../../../utils/form/configs/login';
import { getFallbackLoginPage } from '../login/loginActions';
import { toggleFullScreenSignUp } from '../create-account/createAccountActions';
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';

// constants
import routeConstants from '../../../constants/routeConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import appConstants from '../../../constants/appConstants';
import queryStrings from '../../../constants/queryStringConstants';

import { getFeatures } from '../bff/bffActions';
import createAuth0Client from '../../../utils/auth0/auth0Proxy';
import getAuth0Config, {
  getOrderClaimPasswordLessAuth0Config,
  getRegisterAccountAuth0Config,
} from '../../../utils/auth0/auth0Config';
import { initRavelin } from '../../../utils/ravelin/ravelin';
import { WEB } from '../../../constants/applicationTypeConstants';
import { getAppApplicationTypeSelector } from '../../reducers/app/appSelector';
import { initDeliveryPage } from '../delivery/deliveryActions';

export const auth0CallbackGuestV4 = body => ({
  type: AUTH0_CALLBACK,
  request: client => client({ path: URL_CALLBACK_LOGIN_GUEST_V4, config: { method: 'POST', body } }),
});

export const auth0CallbackAuthV4 = body => ({
  type: AUTH0_CALLBACK,
  request: client => client({ path: URL_CALLBACK_LOGIN_AUTHENTICATED_V4, config: { method: 'POST', body } }),
  body,
});

export const auth0ClaimOrderCallback = body => ({
  type: AUTH0_CLAIM_ORDER_CALLBACK,
  request: client => client({ path: URL_CALLBACK_CLAIM_ORDER, config: { method: 'POST', body } }),
});

export const auth0RegistrationCallback = body => ({
  type: AUTH0_REGISTRATION_CALLBACK,
  request: client => client({ path: URL_CALLBACK_REGISTRATION, config: { method: 'POST', body } }),
});

const onSuccess = () => async (dispatch) => {
  removeStorageItem('email');
  dispatch(resetApp(LOGIN_RESET));
  dispatch(destroyForm(GUEST_FORM_ID));

  dispatch(initLogging());
  dispatch(initDeliveryPage({ isDeliveryPageInitialised: true }));
};

const auth0Fallback = fallBack => async (dispatch) => {
  await dispatch(getFallbackLoginPage(fallBack));
  dispatch(replace({ pathname: routeConstants.LOGIN, search: `?${fallBack}=true` }));
};

export const webAuth0Fallback = () => async (dispatch) => {
  if (window.Auth0Client) {
    // Auth0 SDK failure means customer proceeds as guest, so we should remove window.Auth0lient so
    // getTokenSilently does not cause logging errors in api.js
    window.Auth0Client = undefined;
  }

  dispatch({ type: AUTH0_UNAVAILABLE });
  dispatch(auth0Fallback(routeConstants.WEB_AUTH0_FALLBACK_QUERY_PARAM));
};

export const customerAppsFallback = () => async (dispatch) => {
  dispatch({ type: APPS_ACCESS_TOKEN_ERROR });
  dispatch(auth0Fallback(routeConstants.CUSTOMER_APPS_FALLBACK_QUERY_PARAM));
};

export const handleAuth0GuestCallback = email => async (dispatch) => {
  const authAction = await dispatch(auth0CallbackGuestV4({ email }));

  if (authAction.type === `${AUTH0_CALLBACK}.SUCCESS`) {
    await dispatch(onSuccess());
    dispatch(initRavelin());
  }
};

export const onAuth0SdkException = (error, description) => (dispatch) => {
  const errorDescription = description || 'Some error occurred during interaction with auth0 SDK';
  dispatch(reportErrorToNewRelic({ error, errorDescription }));
};

export const auth0Callback = (accessToken, fromAuth0) => async (dispatch) => {
  const authAction = await dispatch(auth0CallbackAuthV4({
    accessToken,
    ...fromAuth0 && { fromAuth0 },
  }));

  if (authAction.type === `${AUTH0_CALLBACK}.SUCCESS`) {
    await dispatch(onSuccess());
    dispatch(initRavelin());
  }

  const fallbackErrors = [errorCodeConstants.AUTH0_ACCESS_TOKEN_ERROR, errorCodeConstants.AUTH0_UNAVAILABLE_ERROR];
  if (authAction.type === `${AUTH0_CALLBACK}.FAILED` && fallbackErrors.includes(authAction.error.code)) {
    await dispatch(webAuth0Fallback());
  }
};

export const handleAuth0AuthenticatedCallback = () => async (dispatch, getState) => {
  try {
    const auth0Client = await createAuth0Client(getAuth0Config(getState()));

    // preserve on window for use in api.js
    window.Auth0Client = auth0Client;

    try {
      await auth0Client.handleRedirectCallback();
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: handleRedirectCallback in handleAuth0AuthenticatedCallback'));
    }

    let accessToken;
    try {
      accessToken = await auth0Client.getTokenSilently({
        timeoutInSeconds: appConstants.AUTH0_TOKEN_TIMEOUT,
      });
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: getTokenSilently in handleAuth0AuthenticatedCallback'));
    }

    if (accessToken) {
      await dispatch(auth0Callback(accessToken, true));
    } else {
      dispatch(webAuth0Fallback());
    }

  } catch (err) {
    dispatch(onAuth0SdkException(err, 'auth0: handleRedirectCallback or getTokenSilently in handleAuth0AuthenticatedCallback'));
    dispatch(webAuth0Fallback());
  }
};

export const auth0HealthCheck = () => ({
  type: AUTH0_HEALTH_CHECK,
  request: client => client({ path: URL_AUTH0_HEALTH_CHECK, config: { method: 'GET' } }),
});

export const createAccountRedirect = () => async (dispatch, getState) => {
  const { type } = await dispatch(auth0HealthCheck());
  if (type === `${AUTH0_HEALTH_CHECK}.SUCCESS`) {
    try {
      const state = getState();
      const auth0Client = await createAuth0Client(getRegisterAccountAuth0Config(state));
      await auth0Client.loginWithRedirect(getRegisterAccountAuth0Config(state));
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: createAuth0Client or loginWithRedirect in createAccountRedirect'));
    }
  }
};

export const handleAuth0OrderClaimCallbackFailure = () => async (dispatch) => {
  // mimic a failed API call when there was a problem with the claim order passwordless link
  // or a failure with the auth0 SDK

  dispatch({
    type: `${AUTH0_CLAIM_ORDER_CALLBACK}.FAILED`,
    error: {
      code: errorCodeConstants.SAVE_ORDER_INVALID_LINK,
    },
  });

  dispatch(push(routeConstants.ORDER_CONFIRMATION));
};

export const updatePasswordlessClaimOrderAnalytics = () => async (dispatch) => {
  if (window?.jlData?.page?.pageInfo?.reportSuite) {
    return null;
  }

  // these values usually provided by backend but aren't available in sessionless passwordless failure, so patch them in here
  const reportSuite = window.env.AUTH0_CONFIG.reportSuite;
  const site = window.env.AUTH0_CONFIG.site;
  return dispatch(triggerAnalyticsEvent(ANALYTICS_UPDATE_PAGEINFO, {
    reportSuite,
    site,
  }));
};

export const handleAuth0OrderClaimCallback = () => async (dispatch, getState) => {
  const searchParams = new URLSearchParams(getState()?.router?.location?.search);
  const query = Object.fromEntries(searchParams);

  if (query.error) {
    // problem with the passwordless link the customer used: could be expired, invalid, or already used
    dispatch(handleAuth0OrderClaimCallbackFailure());
    return;
  }

  const orderInformation = decodeURIComponent(query.orderInformation?.replace(/ /g, '+'));

  try {
    const auth0Config = getOrderClaimPasswordLessAuth0Config(getState());
    const auth0Client = await createAuth0Client(auth0Config);
    const config = window.env.AUTH0_CONFIG;

    let accessToken;

    try {
      accessToken = await auth0Client.getTokenSilently({
        timeoutInSeconds: appConstants.AUTH0_TOKEN_TIMEOUT,
      });
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: getTokenSilently in handleAuth0OrderClaimCallback'));
    }

    if (!accessToken) {
      dispatch(handleAuth0OrderClaimCallbackFailure());
      return;
    }

    const orderClaimAction = await dispatch(auth0ClaimOrderCallback({
      accessToken,
      ...orderInformation && { orderInformation },
    }));

    if (
      orderClaimAction.type === `${AUTH0_CLAIM_ORDER_CALLBACK}.FAILED` &&
      (orderClaimAction.error?.code ?? '') === errorCodeConstants.UNRECOGNIZED_CUSTOMER
    ) {
      try {
        auth0Client.logout({
          clientId: config.clientId,
          logoutParams: {
            returnTo: auth0Config.cancelUrl,
          },
        });
      } catch (error) {
        dispatch(onAuth0SdkException(error, 'auth0: logout in handleAuth0OrderClaimCallback'));
      }
    } else {
      const isSaveOrderErrorNoSession =
        orderClaimAction.error?.code === errorCodeConstants.SAVE_ORDER_TECHNICAL_ERROR_NO_SESSION;

      if (isSaveOrderErrorNoSession) {
        await dispatch(updatePasswordlessClaimOrderAnalytics());
      }

      if (orderClaimAction.result?.validSession || (!orderClaimAction.result && !isSaveOrderErrorNoSession)) {
        await dispatch(getFeatures());
      }

      dispatch(push(routeConstants.ORDER_CONFIRMATION));
    }
  } catch (err) {
    await dispatch(onAuth0SdkException(err, 'auth0: getTokenSilently or createAuth0Client in handleAuth0OrderClaimCallback'));
    dispatch(handleAuth0OrderClaimCallbackFailure());
  }
};

export const handleAuth0CreateAccountCallback = () => async (dispatch, getState) => {
  const query = getState()?.router?.location?.search ?? '';
  const fullScreenSignUpFlow = query.includes(queryStrings.FULL_SCREEN_SIGNUP_FLOW);
  if (fullScreenSignUpFlow) {
    dispatch(toggleFullScreenSignUp(true));
  }

  try {
    const auth0Config = getRegisterAccountAuth0Config(getState());
    const auth0Client = await createAuth0Client(auth0Config);
    const config = window.env.AUTH0_CONFIG;

    try {
      await auth0Client.handleRedirectCallback();
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: handleRedirectCallback in handleAuth0CreateAccountCallback'));
    }

    const accessToken = await auth0Client.getTokenSilently({
      timeoutInSeconds: appConstants.AUTH0_TOKEN_TIMEOUT,
    });
    const response = await dispatch(auth0RegistrationCallback({ accessToken }));
    if (response.type === `${AUTH0_REGISTRATION_CALLBACK}.FAILED`) {
      try {
        auth0Client.logout({
          clientId: config.clientId,
          logoutParams: {
            returnTo: auth0Config.cancelUrl,
          },
        });
      } catch (error) {
        dispatch(onAuth0SdkException(error, 'auth0: logout in handleAuth0CreateAccountCallback'));
      }
    } else if (fullScreenSignUpFlow && getState()?.user?.showMyJLAccountPrompt) {
      dispatch(push(routeConstants.JOIN_MY_JL));
    } else {
      dispatch(push(routeConstants.ORDER_CONFIRMATION));
    }
  } catch (err) {
    dispatch(onAuth0SdkException(err, 'auth0: createAuth0Client in handleAuth0CreateAccountCallback'));
  }
};

export const restoreAuth0Client = () => async (dispatch, getState) => {
  if (window.Auth0Client) {
    return;
  }

  const restoreAuth0Applications = [WEB];
  if (!restoreAuth0Applications.includes(getAppApplicationTypeSelector(getState()))) {
    return;
  }

  if (getState().user?.isGuest) {
    return;
  }

  try {
    // we lost the Auth0Client at some point due to a page reload so restore it here
    const auth0Client = await createAuth0Client(getAuth0Config(getState()));

    // preserve on window for use in api.js
    window.Auth0Client = auth0Client;
  } catch (err) {
    // if it fails then it fails, not much more we can do without the customer logging in again
  }
};

export const auth0LoginToClaimOrder = () => async (dispatch, getState) => {
  const orderInformation = getState().confirmation?.orderInformation;
  const { type } = await dispatch(auth0HealthCheck());

  if (type === `${AUTH0_HEALTH_CHECK}.SUCCESS`) {
    try {
      const state = getState();
      const config = getOrderClaimPasswordLessAuth0Config(state);

      const auth0Client = await createAuth0Client(config);
      const email = state?.user?.email;
      const params = { authorizationParams: { email, orderInformation } };

      await auth0Client.loginWithRedirect(params);
    } catch (err) {
      dispatch(onAuth0SdkException(err, 'auth0: createAuth0Client or loginWithRedirect in auth0LoginToClaimOrder'));
    }
  }
};
