// Config
import { getAddressFromFormValues } from './addressHelpers';
import { deepEqual } from '@utils/object';

function isSameAddress({ formValues, address, addressToCheck }) {

  const newAddress = formValues ? getAddressFromFormValues(formValues) : address;

  return deepEqual(newAddress, addressToCheck);
}

export default isSameAddress;
