import { push, replace } from 'connected-react-router';

// Design System
import { setFieldValue } from 'jl-design-system/redux/actions/form/formActions';

// Types
import { FeatureFlagType } from 'types/FeatureFlag.types';
import { ProductProps } from 'types/Product.types';
import { AppDispatch, AppClient, AppGetState } from 'types/RootState.types';
import { ShowItemRemoveOverlayTypes } from './editBasketActions.types';

// Config
import {
  ANALYTICS_EDIT_BASKET_MODAL_OPEN,
  SET_EDIT_BASKET_BASE_ROUTE,
  REBATCH_ORDER,
  PUT_ITEM_QUANTITY,
  PUT_ITEMS_QUANTITIES,
  ANALYTICS_AMEND_BASKET_MODAL_OPEN,
  SHOW_ITEM_REMOVE_OVERLAY,
  HANDLE_EDIT_BASKET_QUANTITY_ERROR,
  HIDE_ITEM_REMOVE_OVERLAY,
  REMOVE_BASKET_ITEMS,
  GET_ITEMS,
  BASKET_ITEM_OUT_OF_STOCK,
  SHOW_BATCHING_FAILURE_MODAL,
  ANALYTICS_BATCH_ERROR,
  ANALYTICS_BATCH_ERROR_ITEMS_REMOVED,
  SHOW_REMOVE_BATCHING_FAILURE_ERROR,
  ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED,
  ANALYTICS_SHOW_COLLECTION_HELP_REMOVE,
  CLOSE_EDIT_BASKET_MODAL,
  SAVE_ITEM_FOR_LATER,
} from '@constants/actionConstants';
import {
  URL_PUT_ITEM_QUANTITY,
  URL_PUT_ITEMS_QUANTITIES,
  URL_GET_ITEMS,
  URL_REBATCH_ORDER,
  URL_REMOVE_ITEMS_FROM_BASKET,
  URL_REMOVE_TRADE_IN_ITEM,
  URL_SAVE_FOR_LATER,
  URL_DELIVERIES_V3,
} from '@constants/endpointConstants';
import errorCodeConstants from '@constants/errorCodeConstants';
import featureConstants from '@constants/featureConstants';
import routeConstants from '@constants/routeConstants';
import { getProcessedHandbackUrl, handbackToUrl } from '@hooks/useHandbackToUrl';
import { triggerAnalyticsEvent } from '@redux/actions/analytics/analyticsAction';
import { recordImpressions, showDisableSiteSpinner, showEmptyBasketNotification } from '@redux/actions/app/appActions';
import {
  initDeliveryPage,
  refreshDeliveryMethods,
  setDeliveryAddressAndGetDeliveryMethods,
} from '@redux/actions/delivery/deliveryActions';
import { hidePOSCreditBasketAmendModal, postPayments } from '@redux/actions/payment/paymentActions';
import {
  findCollectionPoint,
  selectCollectionPoint,
  getSavedCollectionPoints,
} from '@redux/actions/click-and-collect/clickAndCollectActions';
import { isApps } from '@redux/reducers/app/appReducer';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { getIsApplicationSelector } from '@redux/reducers/app/appSelector';
import { getOrderFormData } from '@redux/actions/payment/orderFormActions';
import { shouldShowCollectionHelpText } from '@utils/collection/collectionHelp';
import { isClickAndCollectChoice } from '@utils/delivery/deliveryHelper';
import getErrorMessageObject from '@utils/error/getErrorMessageObject';
import { getFormId, ITEM_QUANTITY_INPUT_ID } from '@utils/form/configs/itemQuantity';
import { isPaymentPage } from '@utils/helpers/pageType';
import { isEmptyObject } from '@utils/object';
import isItemFabric, { itemIsM2M } from '@utils/orderform/isItemFabric';
import { shouldRemoveAndSaveForLater } from '@utils/saveForLater/saveForLaterHelp';

export const recordFixedBasketFooterImpressionId = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const { config: { features = [] } = {} } = getState() ?? {};

  const featureFlag =
    features?.find((feature: FeatureFlagType) => feature.id === featureConstants.FIXED_BASKET_FOOTER) ??
    ({} as FeatureFlagType);

  const impressionId = featureFlag.impressionId;
  if (impressionId) {
    return dispatch(recordImpressions(impressionId));
  }
};

export const showEditBasketModal = ({
  baseRoutePath = '', condition,
}: {
  baseRoutePath?: string;
  condition?: string;
}) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_EDIT_BASKET_BASE_ROUTE,
    baseRoutePath,
  });

  const payload = condition ? { condition } : undefined;

  dispatch(push(routeConstants.EDIT_BASKET));
  dispatch(triggerAnalyticsEvent(ANALYTICS_EDIT_BASKET_MODAL_OPEN, payload));
};

export const rebatchOrder = (address: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const deliveriesV3FeatureActive = isFeatureActive(
    getState(),
    featureConstants.DELIVERY_V3,
  );

  const path = deliveriesV3FeatureActive ? URL_DELIVERIES_V3 : URL_REBATCH_ORDER;

  return dispatch({
    type: REBATCH_ORDER,
    request: (client: AppClient) => client({
      path,
      config: { method: 'POST' },
    }),
    address,
    orderHasBeenRebatched: true,
  });
};

export const rebatchOrderAfterBasketEdit = ({
  forceGetDeliveryPage = false,
  shouldInitDeliveryPage = false,
} = {}) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const orderNeedsToBeRebatched = getState()?.orderForm?.orderNeedsToBeRebatched;
  const address = getState()?.delivery?.confirmedDeliveryAddress;
  const selectedDeliveryChoiceId = getState()?.delivery?.selectedDeliveryChoiceId;
  const pathname = getState()?.router?.location?.pathname ?? '';
  const editFromPaymentPage = isPaymentPage({ pathname });

  if (orderNeedsToBeRebatched) {
    dispatch(showDisableSiteSpinner());

    const response = await dispatch(rebatchOrder(address));

    if (response.type === `${REBATCH_ORDER}.SUCCESS`) {
      const hasConfirmedDeliveryAddress = !!getState()?.delivery?.confirmedDeliveryAddress;
      const selectedDeliveryAddress = getState()?.user?.selectedDeliveryAddress;

      if (isClickAndCollectChoice(selectedDeliveryChoiceId)) {
        dispatch(replace(routeConstants.CLICK_AND_COLLECT));
        const searchTerm = getState()?.clickAndCollect?.collectionPointSearchTerm;
        const selectedCollectionPoint = getState()?.clickAndCollect?.selectedCollectionPoint;
        const savedCollectionPoints = getState()?.user?.collectionPoints;


        if (!isEmptyObject(savedCollectionPoints)) {
          // refresh saved collection points as collection charges may have changed
          await dispatch(getSavedCollectionPoints());
        }

        if (searchTerm) {
          // refresh collection point search results as collection charges may have changed
          await dispatch(findCollectionPoint(searchTerm, true));
        }

        if (selectedCollectionPoint) {
          // re-select selected collection point to get latest dates & collection charges
          await dispatch(selectCollectionPoint({
            collectionPoint: selectedCollectionPoint,
            refresh: true,
          }));
        }

      } else if (hasConfirmedDeliveryAddress) {
        if (editFromPaymentPage) {
          await dispatch(replace(routeConstants.DELIVERY_OPTIONS));
        } else if (!shouldInitDeliveryPage) {
          await dispatch(refreshDeliveryMethods());
        }
      } else if (selectedDeliveryAddress) {
        await dispatch(setDeliveryAddressAndGetDeliveryMethods({
          address: selectedDeliveryAddress,
          // TODO the line below can be removed once deliveryActions is typescripted
          formValues: undefined,
        }));
      }

      if (!editFromPaymentPage) {
        if (!shouldInitDeliveryPage) {
          const {
            orderForm: {
              submitOrderFailed,
            } = {},
            payment: {
              isExpressPayment,
            } = {},
          } = getState();

          if (submitOrderFailed && isExpressPayment) await dispatch(postPayments());
        } else {
          await dispatch(initDeliveryPage({ forceGetDeliveryPage }));
        }
      }
    }
  }
};

export const showItemRemoveOverlay = ({ isRemove = false, itemId }: ShowItemRemoveOverlayTypes) => ({
  type: SHOW_ITEM_REMOVE_OVERLAY,
  itemId,
  isRemove,
});

export const hideItemRemoveOverlay = (itemId: string) => ({
  type: HIDE_ITEM_REMOVE_OVERLAY,
  itemId,
});

export const getItems = ({ isAgeVerification = false, deliveriesV3FeatureActive = false } = {}) => ({
  type: GET_ITEMS,
  isAgeVerification,
  request: (client: AppClient) => client({
    path: URL_GET_ITEMS,
    config: { method: 'GET' },
  }),
  ...deliveriesV3FeatureActive && { deliveriesV3FeatureActive },
});

export const saveItems = (itemIds: any) => ({
  type: REMOVE_BASKET_ITEMS,
  actionType: SAVE_ITEM_FOR_LATER,
  itemIds: itemIds.ids,
  products: itemIds?.ids.map((id: string) => ({ productId: id })),
  request: (client: AppClient) => client({ path: URL_SAVE_FOR_LATER, config: { method: 'POST', body: itemIds } }),
});

export const removeItems = ({
  itemIds = [],
  isTradeIn = false,
  isChildItem = false,
  forceRemove = false,
  rebatchNotNeeded = false,
  isExpressCheckout = false,
}: {
  forceRemove?: boolean;
  isChildItem?: boolean;
  isExpressCheckout?: boolean;
  isTradeIn?: boolean;
  itemIds: string[];
  rebatchNotNeeded?: boolean;
}) => (dispatch: AppDispatch, getState: AppGetState) => {
  const isSignedIn = getState()?.user?.isSignedIn ?? false;
  const isM2M = getState().orderForm?.items?.some((
    item: ProductProps,
  ) => itemIds.includes(item.id) && itemIsM2M(item?.type));
  const shouldSaveForLater = shouldRemoveAndSaveForLater({
    isSignedIn,
    isChildItem,
    isTradeIn,
    isM2M,
    isExpressCheckout,
  });

  const itemIdsString = itemIds.toString();
  let path = URL_REMOVE_ITEMS_FROM_BASKET(itemIdsString);

  if (isTradeIn) {
    path = URL_REMOVE_TRADE_IN_ITEM(itemIds);
  }

  if (!shouldSaveForLater || forceRemove) {
    return dispatch({
      type: REMOVE_BASKET_ITEMS,
      request: (client: AppClient) => client({ path, config: { method: 'DELETE' } }),
      itemIds,
      products: itemIds?.map(id => ({ productId: id })),
      isTradeIn,
      ...rebatchNotNeeded && { rebatchNotNeeded },
    });
  }

  return dispatch(saveItems({ ids: itemIds }));
};

export const handleRemoveItem = ({
  itemId = '',
  lastItemInBasket = false,
  isChildItem = false,
  isTradeIn = false,
  isAgeVerification = false,
  isExpressCheckout = false,
  forceRemove = false,
}) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const stateBeforeRemove = getState();

  const response = await dispatch(removeItems({
    itemIds: [itemId],
    isTradeIn,
    isChildItem,
    isExpressCheckout,
    forceRemove,
  }));

  if (response.type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    if (shouldShowCollectionHelpText({
      id: itemId,
      isChildItem,
      state: stateBeforeRemove,
    })) {
      dispatch(triggerAnalyticsEvent(ANALYTICS_SHOW_COLLECTION_HELP_REMOVE));
    }

    const state = getState();
    if (lastItemInBasket) {
      const browseUrl = state?.bff?.browseUrl ?? '';
      const isApp = getIsApplicationSelector(state);
      const processedUrl = getProcessedHandbackUrl(browseUrl, isApp);

      handbackToUrl(undefined, processedUrl);

      if (isApps(state)) {
        dispatch(showEmptyBasketNotification());
      }
    } else {
      await dispatch(getOrderFormData({ isAgeVerification, isCustomerAction: true }));
    }
  }
};

export const updateItemQuantity = ({
  itemId = '',
  quantity = 0,
}: {
  itemId: string;
  quantity: string | number;
}) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const showEditBasketModal = getState()?.delivery?.showEditBasketModal;

  if (showEditBasketModal) {
    const response = await dispatch({
      type: PUT_ITEM_QUANTITY,
      request: (client: AppClient) => client({
        path: URL_PUT_ITEM_QUANTITY(itemId),
        config: {
          method: 'PUT', body: { quantity },
        },
      }),
      itemId,
    });

    if (response.type === `${PUT_ITEM_QUANTITY}.SUCCESS`) {
      dispatch(getOrderFormData({ isCustomerAction: true }));
    }

    if (response.type === `${PUT_ITEM_QUANTITY}.FAILED`) {

      if (response.error) {

        const errorCode = response?.error?.code;

        if (errorCode === errorCodeConstants.ITEM_NOT_IN_STOCK) {
          dispatch({
            type: BASKET_ITEM_OUT_OF_STOCK,
            itemId,
          });
        }

        const getItemsResponse = await dispatch(getOrderFormData({ isCustomerAction: true }));

        if (
          errorCode === errorCodeConstants.ITEM_QUANTITY_PURCHASE_LIMIT ||
          errorCode === errorCodeConstants.QUANTITY_REDUCED_TO_AVAILABLE_STOCK ||
          errorCode === errorCodeConstants.ALL_AVAILABLE_STOCK_ALREADY_ADDED ||
          errorCode === errorCodeConstants.ITEM_QUANTITY_INVALID
        ) {
          const items = getItemsResponse?.result?.orderForm?.items;
          const item = items.find((item: ProductProps) => item.id === itemId);
          const quantity = item?.quantity;
          const formId = getFormId(itemId);

          // ensure field value is updated with correct quantity
          if (quantity) {
            await dispatch(setFieldValue(formId, [ITEM_QUANTITY_INPUT_ID], quantity.toString()));
          }

          const error = getErrorMessageObject({
            error: response.error,
            vars: {
              isItemFabric: isItemFabric(item),
              stockLevel: item?.availability?.stockLevel,
              quantityLimit: item?.availability?.quantityLimit,
            },
          });
          dispatch({
            type: HANDLE_EDIT_BASKET_QUANTITY_ERROR,
            errorMessage: error.message,
            itemId,
          });
        }
      }
    }
  }
};

export const batchUpdateItemQuantity = (itemsQuantity: { [key: string]: number }) => async (
  dispatch: AppDispatch,
) => {
  const response = await dispatch({
    type: PUT_ITEMS_QUANTITIES,
    request: (client: AppClient) => client({
      path: URL_PUT_ITEMS_QUANTITIES,
      config: {
        method: 'PUT',
        body: {
          itemsQuantity,
        },
      },
    }),
  });

  if (response?.type?.includes('SUCCESS')) {
    await dispatch(rebatchOrderAfterBasketEdit());
    return dispatch(getOrderFormData());
  }

  return response;
};

export const showLimitedStockModal = (baseRoutePath = '') => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_EDIT_BASKET_BASE_ROUTE,
    baseRoutePath,
  });
  dispatch(triggerAnalyticsEvent(ANALYTICS_AMEND_BASKET_MODAL_OPEN));
};

export const showBatchingFailureModal = (
  batchingFailureIds: string[],
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const getItemsResponse = await dispatch(getItems());
  if (getItemsResponse.type === `${GET_ITEMS}.SUCCESS`) {
    const items = getItemsResponse?.result?.orderForm?.items ?? [];
    const batchingFailureItems = items?.filter((item: ProductProps) => batchingFailureIds.includes(item.id));
    const allItemsHaveBatchingFailure = items.length === batchingFailureIds.length;

    dispatch({
      type: SHOW_BATCHING_FAILURE_MODAL,
      batchingFailureItems,
      allItemsHaveBatchingFailure,
    });

    const analyticsProducts = getState()?.analytics?.analyticsData?.checkout?.product ?? [];

    dispatch(triggerAnalyticsEvent(ANALYTICS_BATCH_ERROR, {
      products: batchingFailureItems.map(
        (item: ProductProps) => analyticsProducts?.find(
          (product: { productId: string }) => product.productId === item.productId),
      ),
    }));
  }
};

export const removeBatchingFailureItems = (
  items: ProductProps[],
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const itemIds = items.map(item => item.id as string);
  const removeItemsResponse = await dispatch(removeItems({ itemIds, forceRemove: true, rebatchNotNeeded: true }));

  if (removeItemsResponse.type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    const analyticsProducts = getState()?.analytics?.analyticsData?.checkout?.product ?? [];
    const removedProducts = items.map(
      item => analyticsProducts.find((product: { productId: string }) => product.productId === item.productId),
    );

    dispatch(triggerAnalyticsEvent(ANALYTICS_BATCH_ERROR_ITEMS_REMOVED, { products: removedProducts }));
    dispatch(initDeliveryPage({ isDeliveryPageInitialised: true }));
  } else {
    dispatch({ type: SHOW_REMOVE_BATCHING_FAILURE_ERROR });
  }
};

export const removeItemsFromBasket = ({
  itemIds = [],
  shouldInitDeliveryPage = false,
  forceRemove = false,
}: {
  forceRemove?: boolean;
  itemIds: string[];
  shouldInitDeliveryPage?: boolean;
}) => async (dispatch: AppDispatch) => {
  await dispatch(removeItems({ itemIds, forceRemove }));
  await dispatch(rebatchOrderAfterBasketEdit({ shouldInitDeliveryPage }));
  dispatch(getOrderFormData());
};

export const removePOSCreditIneligibleItems = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const itemIds = getState()?.orderForm?.posCreditIneligibleItemIds ?? [];
  const response = await dispatch(removeItems({ itemIds }));
  const { type } = response;

  const address = getState()?.delivery?.confirmedDeliveryAddress;
  await dispatch(rebatchOrder(address));

  if (type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED, {
      products: itemIds.map(productId => ({
        productId,
      })),
    }));
    dispatch(getOrderFormData());
    dispatch(hidePOSCreditBasketAmendModal());
    dispatch(push(routeConstants.DELIVERY));
  }
};

export const closeEditBasketModal = () => async (dispatch: AppDispatch) => {
  dispatch({ type: CLOSE_EDIT_BASKET_MODAL });
};
