const setNestedProperty = (obj: Record<string, any>, path: string, value: any): Record<string, any> => {
  if (!path) return obj;

  const keys = path.split('.');
  keys.reduce((acc, key, index) => {
    const isLastKey = index === keys.length - 1;

    if (isLastKey) {
      acc[key] = value;
    } else {
      const nextKey = keys[index + 1];
      const shouldBeArray = !isNaN(Number(nextKey));

      if (acc[key] === undefined) {
        acc[key] = shouldBeArray ? [] : {};
      }

      return acc[key];
    }

    return acc;
  }, obj);

  return obj;
};

export default setNestedProperty;
