import { createSelector } from 'reselect';

// Config
import { vatRefundMessageState } from '@components/vat-refund-message';
import { getIsApplicationSelector } from '@redux/reducers/app/appSelector';
import {
  getConfirmationExpiredSignedInSelector,
  getConfirmationOrderSelector,
} from '@redux/reducers/confirmation/orderConfirmationSelector';
import {
  getJoinMyJLFailedSelector,
  getJoinMyJLSuccessSelector,
} from '@redux/reducers/create-account/createAccountSelector';
import { getOrderFormItemsSelector } from '@redux/reducers/order-form/orderFormSelector';
import {
  getUserIsGuestSelector,
  getShowMyJLAccountPromptSelector,
  getUserIsMyJLMemberSelector,
} from '@redux/reducers/user/userSelector';

const sidebarState = createSelector(
  [
    getConfirmationExpiredSignedInSelector,
    getIsApplicationSelector,
    getUserIsGuestSelector,
    getUserIsMyJLMemberSelector,
    getOrderFormItemsSelector,
    getConfirmationOrderSelector,
    getJoinMyJLFailedSelector,
    getJoinMyJLSuccessSelector,
    getShowMyJLAccountPromptSelector,
    vatRefundMessageState,
  ],
  (
    expiredSignedIn,
    isApplication,
    isGuest,
    isMyJLMember,
    items,
    order,
    joinMyJLFailed,
    joinMyJLSuccess,
    showMyJLAccountPrompt,
    showVatRefundMessage,
  ) => ({
    expiredSignedIn,
    isApplication,
    isGuest,
    isMyJLMember,
    items,
    joinMyJLFailed,
    joinMyJLSuccess,
    order,
    showMyJLAccountPrompt,
    showVatRefundMessage,
  }),
);

export default sidebarState;
