import { lazy } from 'react';

// Types
import { OrderCompleteNotificationProps as LazyComponentProps } from './OrderCompleteNotification.types';

// Components
import withSuspense, { lazyRetry } from '@components/suspense';

const LazyComponent = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "orderCompleteNotification" */ './OrderCompleteNotification')),
);

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'OrderCompleteNotification');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as OrderCompleteNotification };
