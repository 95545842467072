const getNestedProperties = <T extends Record<string, any>>(
  obj: T,
  path: string | undefined,
  defaultValue: any = undefined,
): any => {
  if (!path) return obj;

  return path.split('.').reduce((acc, key) => acc?.[key], obj) ?? defaultValue;
};

export default getNestedProperties;
