// Types
import { DeliveryOptions } from 'types/Delivery.types';

// Config
import { isEmptyObject } from '@utils/object';

function hasClickCollectBecomeAvailable(
  prevDeliveryOptionsAvailable: DeliveryOptions = {},
  deliveryOptions: DeliveryOptions = {},
) {
  if (isEmptyObject(prevDeliveryOptionsAvailable)) return false;

  const { clickCollectAvailable: prevClickCollectAvailable } = prevDeliveryOptionsAvailable;
  const { clickCollectAvailable } = deliveryOptions;

  return !!(!prevClickCollectAvailable && clickCollectAvailable);
}

export default hasClickCollectBecomeAvailable;
