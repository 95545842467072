import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

// Design System
import addressSearchReducer from 'jl-design-system/redux/reducers/addressSearch/addressSearchReducer';

// Config
import {
  addressBookAddressReducer,
  billingAddressReducer,
  deliveryAddressReducer,
  myjlAddressReducer,
  residentialAddressReducer,
} from './address';
import addressBookReducer from './addressBook/addressBookReducer';
import analyticsReducer from './analytics/analyticsReducer';
import appReducer from './app/appReducer';
import auth0CallbackReducer from './auth0-callback/auth0CallbackReducer';
import bffReducer from './bff/bffReducer';
import clickAndCollectReducer from './click-and-collect/clickAndCollectReducer';
import configReducer from './config/configReducer';
import orderConfirmationReducer from './confirmation/orderConfirmationReducer';
import createAccountReducer from './create-account/createAccountReducer';
import deliveryReducer from './delivery/deliveryReducer';
import diagnosticsReducer from './diagnostics/diagnosticsReducer';
import errorReducer from './error/errorReducer';
import loginReducer from './login/loginReducer';
import mobileAppsReducer from './mobileApps/mobileAppsReducer';
import {
  cardTypeReducer,
  giftCardReducer,
  giftVoucherReducer,
  orderFormReducer,
  paymentReducer,
} from './payment';
import userReducer from './user/userReducer';

const rootReducer = (history: History) => combineReducers({
  addressBook: addressBookReducer,
  addressBookAddress: addressBookAddressReducer,
  addressSearch: addressSearchReducer,
  analytics: analyticsReducer,
  app: appReducer,
  auth0Callback: auth0CallbackReducer,
  bff: bffReducer,
  billingAddress: billingAddressReducer,
  cardType: cardTypeReducer,
  clickAndCollect: clickAndCollectReducer,
  config: configReducer,
  confirmation: orderConfirmationReducer,
  createAccount: createAccountReducer,
  delivery: deliveryReducer,
  deliveryAddress: deliveryAddressReducer,
  diagnostics: diagnosticsReducer,
  error: errorReducer,
  form: reduxFormReducer,
  giftCard: giftCardReducer,
  giftVoucher: giftVoucherReducer,
  login: loginReducer,
  mobileApps: mobileAppsReducer,
  myjlAddress: myjlAddressReducer,
  orderForm: orderFormReducer,
  payment: paymentReducer,
  residentialAddress: residentialAddressReducer,
  router: connectRouter(history),
  user: userReducer,
});

export default rootReducer;
