import { Auth0Client } from '@auth0/auth0-spa-js';

// Design System
import env from 'jl-design-system/utils/env/env';
import redirect from 'jl-design-system/utils/redirect/redirect';

// Config
import { getStorageItem, removeStorageItem, setStorageItem } from '@utils/storage/storage';

/* eslint-disable no-console */
export default function createAuth0Client(auth0ClientConfig) {
  if (env.isClientLocal || env.isClientDev) {
    return {
      loginWithRedirect: (options = {}) => {
        console.info(
          'Add timeout before redirect to auth0 to fix issue when store not persisted due to fast redirection',
        );
        removeStorageItem('auth0AccessToken');

        return new Promise(() => {
          setTimeout(async () => {
            const { domain, authorizationParams: { orderInformation } = {} } = options ?? {};

            const { authorizationParams: { initialScreen } = {}, domain: auth0ClientConfigDomain } =
              auth0ClientConfig ?? {};

            if (domain) {
              const searchParams = new URLSearchParams(options.authorizationParams);
              redirect(`${domain}/authorize?${searchParams.toString()}`);
            } else if (orderInformation) {
              const orderInformationParam = `&orderInformation=${orderInformation}`;
              redirect(`${auth0ClientConfigDomain}?initialScreen=${initialScreen}${orderInformationParam}`);
            } else {
              redirect(`${auth0ClientConfigDomain}?initialScreen=${initialScreen}`);
            }
          }, 1000);
        });
      },
      handleRedirectCallback: () => {
        console.info('In stubbed handleRedirectCallBack function');
      },
      getTokenSilently: async () => {
        const auth0Token = getStorageItem('auth0AccessToken');
        if (auth0Token) {
          return auth0Token;
        }

        console.info('In stubbed getTokenSilently function');
        return fetch(`${auth0ClientConfig.domain}/oauth/token`)
          .then((response) => response.json())
          .then((data) => {
            setStorageItem({
              key: 'auth0AccessToken',
              value: data.access_token,
            });
            return data.access_token;
          });
      },
      logout: (logoutObj) => {
        console.info('In stubbed logout function');
        removeStorageItem('auth0AccessToken');
        redirect(logoutObj.logoutParams.returnTo);
      },
    };
  }
  return new Auth0Client(auth0ClientConfig);
}
/* eslint-enable no-console */
