/* eslint-disable css-modules/no-unused-class */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// Design System
import Tooltip from 'jl-design-system/components/tooltip/Tooltip';
import { UnderlinedButton } from 'jl-design-system/elements/button/Button';
import { setFieldValue } from 'jl-design-system/redux/actions/form/formActions';

// Types
import { AppDispatch } from 'types/RootState.types';
import { OrderItemProps } from './OrderItem.types';
import { DesignSystemSpacingTypes } from '@components/design-system/DesignSystem.types';

// Config
import orderItemState from './OrderItem.state';
import {
  getInformationList,
  getSubtotalText,
  getQuantityText,
  getQuantityTextFabricItem,
  getQuantitySelector,
  getReducedQuantityText,
  getReducedQuantityTextForFabricItem,
  getPromotionalText,
} from './OrderItemHelper';
import { shouldShowCollectionHelpText } from '@utils/collection/collectionHelp';
import { getFormId } from '@utils/form/configs/itemQuantity';
import { isEmptyObject } from '@utils/object';
import { shouldShowPersonalisationHelpText } from '@utils/personalisation/personalisationHelp';

// Components
import Brand from '@components/brand';
import Container from '@components/container';
import BodyText from '@components/body-text';
import Heading from '@components/heading';
import ProductImageWithMagnifier from '@components/product-image-with-magnifier';

// Styles
import styles from './order-item.scss';

const OrderItem = ({
  availability = {},
  basketView = false,
  batchedView = false,
  brand,
  child = false,
  displayImage,
  hideOutOfStockText = false,
  hideQuantities = false,
  hideSubtotals = false,
  id = '',
  imageUrl = '',
  information,
  isOrderConfirmation = false,
  isPosCreditItem = false,
  modal = false,
  name = '',
  partnerDiscountRate,
  personalised = false,
  preventEditing = false,
  priceReductionHistory,
  promotionalMessageTitle,
  quantity,
  reducedQuantity,
  removeItem,
  sidebar = false,
  subtotal = '',
  subtotalDiscounted,
  type,
  uom = '',
}: OrderItemProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { orderComplete, deliveries } = useSelector(orderItemState);
  const showCollectionHelp = shouldShowCollectionHelpText({ deliveries, isChildItem: child, id, orderComplete });
  const showPersonalisationMessage = shouldShowPersonalisationHelpText({
    isChildItem: child,
    orderComplete,
    personalised,
  });
  const [currentQuantity, setCurrentQuantity] = useState<string | number>();
  const getContainerClassnames = () =>
    cx(styles.container, {
      [styles.basketView]: basketView,
      [styles.batchedView]: batchedView,
      [styles.containerChild]: child,
      [styles.sidebar]: sidebar,
      [styles.modal]: modal,
      [styles.posCredit]: isPosCreditItem,
      [styles.orderConfirmation]: isOrderConfirmation,
    });

  const getItemDetailsContainerClassnames = () =>
    cx(styles.itemDetailsContainer, {
      [styles.imageMarginApplied]: !displayImage && !isOrderConfirmation && (!child || !sidebar),
      [styles.sideBarContainer]: sidebar,
    });

  const isChildSidebar = child && sidebar;
  const isItemFabric = uom !== '' && !type?.startsWith('m2m');
  const stepperQuantity = currentQuantity === undefined ? reducedQuantity : currentQuantity;
  const formId = getFormId(id);
  const handleQuantityChange = (newQuantity: string | number) => {
    setCurrentQuantity(newQuantity);
    dispatch(setFieldValue(formId, 'itemId', id));
    dispatch(setFieldValue(formId, 'itemQuantity', newQuantity));
  };

  const NameTag = isChildSidebar ? Heading : BodyText;
  let nameProps: {
    marginBottom: DesignSystemSpacingTypes;
    type?: string;
  } = {
    marginBottom: '2',
  };
  if (isChildSidebar) {
    nameProps = {
      marginBottom: '1',
      type: 'xs',
    };
  } else if (child && isOrderConfirmation) {
    nameProps = {
      marginBottom: '0',
    };
  }

  const isSingleItem = Number(reducedQuantity) === 1;
  const showPriceReductionHistory =
    !isChildSidebar &&
    (sidebar || basketView || isOrderConfirmation) &&
    !hideSubtotals &&
    !batchedView &&
    !!(promotionalMessageTitle || partnerDiscountRate);
  const showPartnerDiscountRate = !!(partnerDiscountRate && subtotalDiscounted);
  const showPromotionalMessageTitle =
    !!(partnerDiscountRate && subtotalDiscounted) || !isEmptyObject(priceReductionHistory);

  return (
    <div className={getContainerClassnames()} data-testid="items">
      <div className={styles.wrapper}>
        {displayImage && <ProductImageWithMagnifier imageUrl={imageUrl} name={name} showMagnifyingIcon={sidebar} />}

        <div className={getItemDetailsContainerClassnames()} data-testid="item-details-container">
          <div className={styles.itemType}>
            <Brand brand={brand} headingTag="h3" />
            <NameTag {...nameProps} tag="p" testId="order-item-name">
              {name}
            </NameTag>
            {showCollectionHelp && (
              <BodyText marginBottom="2">
                <strong>Collection unavailable</strong>
              </BodyText>
            )}
            <Container marginBottom="2" renderIf={showPersonalisationMessage}>
              <Tooltip
                anchor={sidebar ? 'right' : 'left'}
                buttonLabel="Includes personalisation"
                icon
                id={`personalisationTooltip-${id}`}
              >
                <BodyText marginBottom="2">
                  Once you’ve placed your order, please email your personalisation details to the supplier.
                </BodyText>
                <BodyText>
                  Details on how to do this can be found on the product details page or the order confirmation page.
                </BodyText>
              </Tooltip>
            </Container>
            {availability.preOrder && <BodyText marginBottom="2">Pre-order</BodyText>}
            {information && !batchedView && getInformationList(information, styles)}
          </div>

          {reducedQuantity ? (
            <>
              {(preventEditing || isSingleItem) && (
                <div className={styles.reducedQuantityContainer}>
                  {isItemFabric
                    ? getReducedQuantityTextForFabricItem(quantity, reducedQuantity, styles)
                    : getReducedQuantityText(quantity, reducedQuantity, styles)}
                </div>
              )}
              {!preventEditing &&
                !isSingleItem &&
                getQuantitySelector(
                  {
                    id,
                    type,
                    uom,
                    availability,
                  },
                  isItemFabric ? reducedQuantity : stepperQuantity,
                  styles,
                  isItemFabric ? undefined : handleQuantityChange,
                )}
            </>
          ) : (
            !hideQuantities && (
              <>
                {isChildSidebar ? (
                  <div className={styles.childSidebarQuantityAndTotal}>
                    {isItemFabric
                      ? getQuantityTextFabricItem(quantity, styles)
                      : getQuantityText({ bold: sidebar, quantity, styles })}
                    {!hideSubtotals &&
                      !batchedView &&
                      getSubtotalText({
                        brand,
                        sidebar,
                        subtotal,
                        subtotalDiscounted,
                        styles,
                        basketView: false,
                        isChildItem: true,
                        isOrderConfirmation,
                      })}
                  </div>
                ) : (
                  <>
                    {isItemFabric
                      ? getQuantityTextFabricItem(quantity, styles)
                      : getQuantityText({ bold: sidebar || isOrderConfirmation, quantity, styles })}
                    {!hideSubtotals &&
                      !batchedView &&
                      getSubtotalText({
                        brand,
                        sidebar,
                        subtotal,
                        subtotalDiscounted,
                        styles,
                        basketView: false,
                        isChildItem: false,
                        isOrderConfirmation,
                        priceReductionHistory,
                        promotionalMessageTitle,
                        partnerDiscountRate,
                      })}
                  </>
                )}
              </>
            )
          )}

          {!hideOutOfStockText && Number(availability?.stockLevel) === 0 && (
            <p className={styles.outOfStock}>
              Sorry, this item is no longer available. Please{' '}
              <UnderlinedButton onClick={removeItem}>remove</UnderlinedButton> this item to continue.
            </p>
          )}
        </div>
      </div>
      <Container renderIf={showPriceReductionHistory} textAlign="right">
        {getPromotionalText({
          brand,
          showPartnerDiscountRate,
          showPromotionalMessageTitle,
          styles,
          promotionalMessageTitle,
          partnerDiscountRate,
        })}
      </Container>
    </div>
  );
};

export default OrderItem;
