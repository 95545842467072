// Types
import { RootState } from 'types/RootState.types';

export function shouldSaveDefaultAddress(state: RootState) {
  const { user: { addressBook = [], isSignedIn = false } = {} } = state;

  const defaultAddress = addressBook.some((address) => address.defaultShipping);

  return !defaultAddress && isSignedIn;
}
