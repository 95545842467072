import { AnyAction } from 'redux';

// Types
import { DeliveryMethodDetailsType, DeliveryMethodType, DeliveryProps } from 'types/Delivery.types';
import { DeliveryState } from 'types/RootState.types';

// Config
import getDeliveriesState from './getDeliveriesState';
import hasClickCollectBecomeAvailable from './hasClickCollectBecomeAvailable';
import transformDeliveries from './transformDeliveries';
import {
  ANALYTICS_DELIVERY_INSTRUCTIONS_FOCUSED,
  REDUX_HYDRATE,
  GET_DELIVERY_PAGE,
  POST_DELIVERY_PAGE,
  POST_DELIVERY_PAGE_NO_ACTION,
  GET_DELIVERY_METHODS,
  RESET_DELIVERY_METHODS,
  PUT_DELIVERY_DETAILS,
  LOGIN_RESET,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  GET_PAYMENT_PAGE,
  INIT_PAYMENT_PAGE,
  PUT_DELIVERY_ADDRESS,
  CHANGE_DELIVERY_ADDRESS,
  PAYPAL_PAYMENT_CANCEL,
  PAYPAL_PAYMENT_FAILED,
  POST_ADDRESS_BOOK_CREATE,
  PUT_ADDRESS_BOOK_UPDATE,
  PUT_CC_DELIVERY_DETAILS,
  SET_DELIVERY_BATCHES_PROCESSING,
  SET_EDIT_BASKET_BASE_ROUTE,
  REBATCH_ORDER,
  PUT_ITEM_QUANTITY,
  REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET,
  ROUTER_LOCATION_CHANGE,
  SHOW_EMPTY_BASKET_NOTIFICATION,
  SET_SELECTED_SLOT_METHOD,
  SET_SELECTED_DATE_METHOD,
  SET_SELECTED_DATE_METHOD_PAGE,
  GET_PAYMENT_WALLET,
  ALLOW_INIT_DELIVERY_PAGE_CALLS,
  GET_ITEMS,
  GET_APPLE_PAY_EXPRESS_PAYMENT_REQUEST,
  APPLE_PAY_PAYMENT_CANCEL,
  APPLE_PAY_PAYMENT_FAILURE,
  SET_APPLE_PAY_ENHANCED_SHIPPING_IDENTIFIER,
  APPLY_REWARDS,
  GET_ORDERFORM_DELIVERIES,
  SHOULD_USE_FASTER_COLLECTION,
  SELECT_COLLECTION_POINT,
} from '../../../constants/actionConstants';
import brandConstants from '../../../constants/brandConstants';
import { STORE_SELECTED } from '../../../constants/clickCollectConstants';
import deliveryConstants, {
  DELIVERY_DETAILS_FORM_ID,
  clearabeeConstants,
  flowerConstants,
} from '../../../constants/deliveryConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import routeConstants from '../../../constants/routeConstants';
import { getNonGlobalError } from '../../../utils/error/parseError';
import { DELIVERY_ADDRESS_FORM_ID } from '../../../utils/form/configs/deliveryAddress';
import { ProductProps } from 'types/Product.types';

export const INITIAL_STATE = {
  pathname: '',
  getDeliveryPageApiCallComplete: false,
  confirmedDeliveryAddress: undefined,
  deliveryInstructionsFocus: false,
  deliveryOptions: {},
  deliveryMethodsApiCallComplete: undefined,
  deliveryCountries: [],
  putDeliveryAddressApiCallActive: false,
  putDeliveryAddressError: undefined,
  saveAddressError: undefined,
  selectedDeliveryChoiceId: undefined,
  showAddressBookModal: false,
  batchesProcessing: false,
  servicesThatNeedRemoving: undefined,
  undeliverableItemsInBasket: [],
  shouldReFetchDeliveryOptions: false,
  getBatchesDeliveryMethodsApiCallActive: false,
  // default editBasketModalBaseRoutePath so that delivery page edit basket errors can use it
  editBasketModalBaseRoutePath: routeConstants.DELIVERY,
  showClickCollectNowAvailableMessage: false,
  showAmendQuantityNotification: false,
  usePostToInitDeliveryPage: true,
  // proceedingToPayment covers delivery-details PUT followed by payment-page GET/POST
  proceedingToPayment: false,
  isDeliveryPageInitialised: false,
};

function getDeliveryType({
  countryCode,
  items,
  type,
}: {
  countryCode?: string;
  items?: ProductProps[];
  type?: string;
}) {
  if (countryCode && countryCode !== 'GB') {
    return deliveryConstants.INTERNATIONAL;
  }

  const hasPreOrderableItems = !!items?.find(
    (item) => item?.availability?.availabilityStatus === deliveryConstants.PREORDERABLE,
  );

  return hasPreOrderableItems && type !== deliveryConstants.SPECIAL_ORDER ? deliveryConstants.SPECIAL_ORDER : type;
}

export default function deliveryReducer(state: DeliveryState = INITIAL_STATE, action: AnyAction) {
  const actionType = action?.type || '';

  switch (actionType) {
    case LOGIN_RESET:
      return INITIAL_STATE;

    case REDUX_HYDRATE: {
      const payload = action?.payload?.delivery;
      const deliveries: DeliveryProps[] = payload?.deliveries ?? [];

      const transformedState = {
        ...payload,
        putDeliveryAddressApiCallActive: false,
        deliveries: deliveries?.map((delivery) => ({
          ...delivery,
          getDeliveryMethodsApiCallActive: false,
        })),
        proceedingToPayment: false,
      };

      return transformedState;
    }

    case `${POST_DELIVERY_PAGE_NO_ACTION}.LOADING`:
    case `${POST_DELIVERY_PAGE}.LOADING`:
    case `${GET_DELIVERY_PAGE}.LOADING`:
      return {
        ...state,
        getDeliveryPageApiCallComplete: false,
        servicesThatNeedRemoving: undefined,
      };

    case `${POST_DELIVERY_PAGE}.FAILED`:
    case `${GET_DELIVERY_PAGE}.FAILED`:
      if (action?.error?.code === errorCodeConstants.ORDER_FORM_EMPTY) {
        return {
          ...state,
          getDeliveryPageApiCallComplete: true,
          showEditBasketModal: false,
        };
      }
      return {
        ...state,
        getDeliveryPageApiCallComplete: true,
      };

    case `${PUT_ITEM_QUANTITY}.SUCCESS`: {
      const currentItemFocus = action?.itemId ?? {};
      return {
        ...state,
        currentItemFocus,
      };
    }

    case `${POST_DELIVERY_PAGE}.SUCCESS`: {
      const isDeliveryPageInitialised = action.isDeliveryPageInitialised;
      const deliveriesV3FeatureActive = action.deliveriesV3FeatureActive;

      const commonValues = {
        getDeliveryPageApiCallComplete: true,
        usePostToInitDeliveryPage: false,
        isDeliveryPageInitialised,
      };

      if (deliveriesV3FeatureActive) {
        return {
          ...state,
          ...commonValues,
          customer: action?.result?.customer,
        };
      }

      return {
        ...getDeliveriesState(state, action),
        deliveries: transformDeliveries(action?.result?.orderForm?.deliveries, state.items),
        ...commonValues,
      };
    }

    case `${GET_ORDERFORM_DELIVERIES}.SUCCESS`: {
      return {
        ...getDeliveriesState(state, action),
        deliveries: transformDeliveries(action?.result?.orderForm?.deliveries, state.items),
      };
    }

    case ALLOW_INIT_DELIVERY_PAGE_CALLS:
      return {
        ...state,
        isDeliveryPageInitialised: false,
      };

    case `${GET_DELIVERY_PAGE}.SUCCESS`: {
      return {
        ...getDeliveriesState(state, action),
        getDeliveryPageApiCallComplete: true,
      };
    }

    case `${GET_ITEMS}.SUCCESS`:
    case `${APPLY_REWARDS}.SUCCESS`: {
      const items = action?.result?.orderForm?.items;

      return {
        ...getDeliveriesState(state, action),
        deliveries: transformDeliveries(state.deliveries, items),
        items,
      };
    }

    case `${REBATCH_ORDER}.SUCCESS`: {
      const deliveryOptions = action?.result?.orderForm?.deliveryOptions ?? {};
      const deliveries = action?.result?.orderForm?.deliveries;

      return {
        ...getDeliveriesState(state, action),
        deliveries: transformDeliveries(deliveries, state.items),
        showAmendQuantityNotification: true,
        showClickCollectNowAvailableMessage: hasClickCollectBecomeAvailable(state.deliveryOptions, deliveryOptions),
      };
    }

    case `${PUT_DELIVERY_ADDRESS}.SUCCESS`: {
      const deliveries = action?.result?.orderForm?.deliveries;

      return {
        ...getDeliveriesState(state, action),
        deliveries: transformDeliveries(deliveries, state.items),
        changingAddress: false,
        putDeliveryAddressApiCallActive: false,
        putDeliveryAddressError: undefined,
      };
    }

    case `${GET_PAYMENT_PAGE}.FAILED`:
    case `${INIT_PAYMENT_PAGE}.FAILED`:
      if (action?.error?.code === errorCodeConstants.ORDER_FORM_EMPTY) {
        return {
          ...state,
          incompleteDeliveryDetailsError: getNonGlobalError(action),
          proceedingToPayment: false,
          showEditBasketModal: false,
        };
      }
      return {
        ...state,
        incompleteDeliveryDetailsError: getNonGlobalError(action),
        proceedingToPayment: false,
      };

    case `${GET_PAYMENT_PAGE}.SUCCESS`:
    case `${INIT_PAYMENT_PAGE}.SUCCESS`:
      return {
        ...state,
        incompleteDeliveryDetailsError: undefined,
        showClickCollectNowAvailableMessage: false,
        showAmendQuantityNotification: false,
        proceedingToPayment: false,
      };

    case CHANGE_DELIVERY_ADDRESS: {
      return {
        ...state,
        confirmedDeliveryAddress: undefined,
        changingAddress: true,
      };
    }

    case SET_SELECTED_DELIVERY_CHOICE_ID:
      return {
        ...state,
        selectedDeliveryChoiceId: action.id,
        incompleteDeliveryDetailsError: undefined,
      };

    case `${PUT_DELIVERY_ADDRESS}.LOADING`:
      return {
        ...state,
        putDeliveryAddressApiCallActive: true,
        shouldReFetchDeliveryOptions: false,
        shouldSetConfirmedDeliveryAddressAgain: false,
      };

    case `${PUT_DELIVERY_ADDRESS}.FAILED`: {
      const address = action?.address;
      const postcode = address?.address?.postcode;

      if (action?.error?.code === errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR) {
        return {
          ...state,
          putDeliveryAddressApiCallActive: false,
        };
      }

      return {
        ...state,
        putDeliveryAddressApiCallActive: false,
        putDeliveryAddressError: getNonGlobalError(action, state.putDeliveryAddressError, { postcode }),
        confirmedDeliveryAddress: undefined,
      };
    }

    case `${REBATCH_ORDER}.LOADING`: {
      return {
        ...state,
        servicesThatNeedRemoving: undefined,
      };
    }

    case `${GET_DELIVERY_METHODS}.LOADING`: {
      const delivery = state.deliveries?.find((deliveryRecord) => deliveryRecord?.id === action.batchId);
      const refresh = !!action.refresh;

      return {
        ...state,
        deliveries: state.deliveries?.map((d) => {
          if (delivery?.id === d.id) {
            if (refresh) {
              return {
                ...d,
                gvfSlotsLoading: true,
              };
            }

            return {
              ...d,
              getDeliveryMethodsApiCallActive: true,
            };
          }
          return d;
        }),
        getBatchesDeliveryMethodsApiCallActive: true,
        shouldReFetchDeliveryOptions: false,
        deliveryMethodsApiCallComplete: false,
      };
    }

    case `${GET_DELIVERY_METHODS}.FAILED`: {
      const address = action?.address;
      const delivery = state.deliveries?.find((deliveryRecord) => deliveryRecord?.id === action.batchId) || {};
      const postcode = address?.address?.postcode;
      const batchUndeliverableServices = action?.error?.code === errorCodeConstants.BATCH_UNDELIVERABLE_SERVICES;

      const deliveries = state.deliveries?.map((d) => {
        if (delivery.id === d.id) {
          let returnObj;

          if (batchUndeliverableServices) {
            const undeliverableServicesAffectedItems = state?.items?.map((d) => ({
              deliveryId: delivery.id,
              id: d.id,
              name: d.name,
            }));

            returnObj = {
              undeliverableServices: {
                items: undeliverableServicesAffectedItems,
              },
            };
          } else {
            returnObj = {
              // FIXME: This is no longer out of area postcode error
              // Need to check what possible errors can come back GET_DELIVERY_METHODS call.
              getDeliveryMethodsError: getNonGlobalError(action, d.getDeliveryMethodsError, { postcode }),
            };
          }

          return {
            ...d,
            ...returnObj,
            deliveryMethods: undefined,
            getDeliveryMethodsApiCallActive: false,
            gvfSlotsLoading: false,
          };
        }

        return d;
      });

      return {
        ...state,
        deliveries,
        putDeliveryAddressApiCallActive: false,
        confirmedDeliveryAddress: address,
        getBatchesDeliveryMethodsApiCallActive: deliveries?.reduce(
          (acc, batch) => (!acc ? batch.getDeliveryMethodsApiCallActive : acc) ?? false,
          false,
        ),
        deliveryContainsUndeliverableServices: batchUndeliverableServices,
        deliveryMethodsApiCallComplete: false,
      };
    }

    case `${GET_DELIVERY_METHODS}.SUCCESS`: {
      const batchId = action.batchId;
      const delivery = state.deliveries?.find((deliveryRecord) => deliveryRecord.id === batchId);

      const address = action.address || state.confirmedDeliveryAddress;
      const countryCode = action?.address?.address?.countryCode ?? '';

      const deliveryType = getDeliveryType({
        countryCode,
        type: delivery?.type,
        items: delivery?.items,
      });

      const deliveriesWithClearabee = state.deliveriesWithClearabee || [];
      const deliveriesWithFlowers = action.flowersFeatureDisabled ? [] : state.deliveriesWithFlowers || [];

      const transformedDeliveries = state.deliveries?.map((d) => {
        if (d.id === action.batchId) {
          let gvfValues = {};
          if (d.type === deliveryConstants.GREEN_VAN_FLEET) {
            const deliveryInstructionsAvailable =
              action?.result?.methods?.[0]?.fulfilmentOffers?.type === deliveryConstants.DELIVERY_TYPE_CALENDAR;

            gvfValues = {
              dateRange: action?.dateRange ?? 1,
              deliveryInstructionsAvailable,
              fromDate: action.fromDate ? action.fromDate : d.fromDate,
              gvfSlotsLoading: false,
              selectedDeliveryMethodDetails: action.refresh ? undefined : d.selectedDeliveryMethodDetails,
              items: delivery?.items,
              selectedMethodIsExpress: d.selectedMethodIsExpress || false,
            };
          }

          const analytics = (action?.result?.analytics?.checkout?.batch ?? []).find(
            (b: { batchNum: string | undefined }) => b.batchNum === d.id,
          );

          let dbsDown = false;
          if (
            !!action.fromDate &&
            action?.result?.methods?.[0]?.fulfilmentOffers?.type === deliveryConstants.DELIVERY_TYPE_STANDARD
          ) {
            // Customer tried to get earlier/later dates but DBS was down
            dbsDown = true;
          }

          const isClearabee = (d.items ?? [])?.some((item) => item?.brand === brandConstants.CLEARABEE);
          if (isClearabee) deliveriesWithClearabee.push(d.id ?? '');

          const isFlowerGift = !!(
            !action.flowersFeatureDisabled &&
            (d.items ?? [])?.some((item) => item?.productCode?.startsWith(flowerConstants.PRODUCT_CODE_PREFIX))
          );
          if (isFlowerGift) deliveriesWithFlowers.push(d.id ?? '');

          const methodsWithId = (action?.result?.methods || []).map((method: DeliveryMethodType, index: number) => ({
            ...method,
            id: `${d.id}-method-${index}`,
            ...(isClearabee && {
              charge: undefined,
              description: clearabeeConstants.DESCRIPTION,
              additionalInformation: clearabeeConstants.ADDITIONAL_INFORMATION,
            }),
            ...(isFlowerGift && {
              description: flowerConstants.DESCRIPTION,
              additionalInformation: flowerConstants.ADDITIONAL_INFORMATION,
            }),
          }));

          return {
            ...d,
            ...action.result,
            methods: methodsWithId,
            ...(analytics && {
              analytics: {
                batchNum: analytics.batchNum,
                deliveryProposition: analytics.deliveryProposition,
                deliveryType: analytics.deliveryType,
              },
            }),
            selectedMethod: d.selectedMethod || 0,
            isGiftReceiptAvailable: action.result.giftReceiptAvailable,
            deliveryDetailsFormId: `${DELIVERY_DETAILS_FORM_ID}-${d.id}`,
            getDeliveryMethodsApiCallActive: false,
            deliveryType,
            dbsDown,
            ...gvfValues,
            isClearabee,
            isFlowerGift,
          };
        }

        return d;
      });

      const getBatchesDeliveryMethodsApiCallActive = transformedDeliveries?.reduce(
        (acc, batch) => (!acc ? batch.getDeliveryMethodsApiCallActive : acc),
        false,
      );

      return {
        ...state,
        confirmedDeliveryAddress: address,
        deliveries: transformedDeliveries,
        getBatchesDeliveryMethodsApiCallActive,
        shouldReFetchDeliveryOptions: false,
        deliveryMethodsApiCallComplete: true,
        deliveryContainsUndeliverableServices: false,
        deliveriesWithClearabee,
        deliveriesWithFlowers,
      };
    }

    case `${PUT_DELIVERY_DETAILS}.LOADING`: {
      return {
        ...state,
        proceedingToPayment: true,
        giftMessageValidationFailed: false,
      };
    }

    case `${PUT_DELIVERY_DETAILS}.FAILED`: {
      const giftMessageValidationFailed = [
        errorCodeConstants.GIFT_MESSAGE_LINE_COUNT_EXCEEDED,
        errorCodeConstants.GIFT_MESSAGE_CHARACTER_COUNT_EXCEEDED,
      ].includes(action?.error?.code ?? '');

      return {
        ...state,
        proceedingToPayment: false,
        giftMessageValidationFailed,
      };
    }

    case `${PUT_DELIVERY_DETAILS}.SUCCESS`: {
      return {
        ...state,
        proceedingToPayment: true,
        giftMessageValidationFailed: false,
      };
    }

    case SET_DELIVERY_BATCHES_PROCESSING:
      return {
        ...state,
        batchesProcessing: action.isProcessing,
      };

    case `${POST_ADDRESS_BOOK_CREATE}.LOADING`:
      return {
        ...state,
        createAddressAPICallActive: true,
      };

    case `${POST_ADDRESS_BOOK_CREATE}.FAILED`:
    case `${PUT_ADDRESS_BOOK_UPDATE}.FAILED`: {
      return {
        ...state,
        createAddressAPICallActive: false,
        saveAddressError: getNonGlobalError({
          ...action,
          error: {
            code: errorCodeConstants.SAVE_ADDRESS_FAILED,
          },
        }),
      };
    }

    case `${POST_ADDRESS_BOOK_CREATE}.SUCCESS`:
    case `${PUT_ADDRESS_BOOK_UPDATE}.SUCCESS`: {
      return {
        ...state,
        createAddressAPICallActive: false,
        saveAddressError: undefined,
      };
    }

    case 'DELIVERY_ADDRESS_RESET_ADDRESS_SEARCH': {
      return {
        ...state,
        deliveryType: undefined,
      };
    }

    case '@@redux-form/CHANGE': {
      const formId = action?.meta?.form ?? '';
      const fieldId = action?.meta?.field ?? '';

      if (formId?.includes(DELIVERY_DETAILS_FORM_ID)) {
        if (fieldId === deliveryConstants.DELIVERY_V3_FIELD) {
          let parsedValues: DeliveryMethodDetailsType;

          try {
            parsedValues = JSON.parse(action.payload);
          } catch (e) {
            return state;
          }

          return {
            ...state,
            deliveries: state.deliveries?.map((delivery) => {
              if (delivery.id === parsedValues?.deliveryId) {
                return {
                  ...delivery,
                  selectedDeliveryMethodDetails: parsedValues,
                };
              }

              return delivery;
            }),
          };
        }
      }

      if (formId === DELIVERY_ADDRESS_FORM_ID) {
        if (fieldId === 'id') {
          return {
            ...state,
            saveAddressError: action.payload ? undefined : state.saveAddressError,
          };
        }
      }

      return state;
    }

    case '@@redux-form/FOCUS': {
      const fieldId = action?.meta?.field ?? '';
      if (fieldId === 'deliveryInstructionsOption') {
        return {
          ...state,
          deliveryInstructionsFocus: true,
        };
      }

      return {
        ...state,
        deliveryInstructionsFocus: false,
      };
    }

    case ANALYTICS_DELIVERY_INSTRUCTIONS_FOCUSED:
      return {
        ...state,
        deliveryInstructionsFocus: false,
      };

    case RESET_DELIVERY_METHODS:
      return {
        ...state,
        confirmedDeliveryAddress: undefined,
        incompleteDeliveryDetailsError: undefined,
        deliveries: state.deliveries?.map((delivery) => ({
          ...delivery,
          methods: undefined,
          //
          getDeliveryMethodsApiCallActive: false,
          getDeliveryMethodsError: undefined,
          isGiftReceiptAvailable: false,
          selectedDeliverySlots: {},
        })),
      };

    case ROUTER_LOCATION_CHANGE: {
      const pathname = action?.payload?.location?.pathname ?? '';

      if (pathname?.includes(routeConstants.EDIT_BASKET)) {
        return {
          ...state,
          showEditBasketModal: true,
        };
      }

      if (pathname?.includes(STORE_SELECTED)) {
        return {
          ...state,
          selectedDeliveryChoiceId: deliveryConstants.CLICK_AND_COLLECT,
          showAddressBookModal: false,
          showEditBasketModal: false,
          showSavedCollectionPointsModal: false,
        };
      }

      switch (pathname) {
        case routeConstants.DELIVERY: {
          return {
            ...state,
            selectedDeliveryChoiceId: deliveryConstants.DELIVERY,
            showAddressBookModal: false,
            showEditBasketModal: false,
          };
        }

        case routeConstants.DELIVERY_OPTIONS:
          return {
            ...state,
            showAddressBookModal: false,
            showEditBasketModal: false,
            selectedDeliveryChoiceId: deliveryConstants.DELIVERY,
          };

        case routeConstants.CLICK_AND_COLLECT_SEARCH_LIST:
        case routeConstants.CLICK_AND_COLLECT_SEARCH_MAP:
          return {
            ...state,
            selectedDeliveryChoiceId: deliveryConstants.CLICK_AND_COLLECT,
            showSavedCollectionPointsModal: false,
            showEditBasketModal: false,
          };

        case routeConstants.DELIVERY_ADDRESS_BOOK:
          return {
            ...state,
            showAddressBookModal: true,
          };

        case routeConstants.DELIVERY_ADDRESS_BOOK_NEW:
          return {
            ...state,
            showAddressBookModal: true,
          };

        case routeConstants.DELIVERY_ADDRESS_BOOK_EDIT:
          return {
            ...state,
            showAddressBookModal: true,
          };

        case routeConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS:
          return {
            ...state,
            showSavedCollectionPointsModal: true,
          };

        default:
          return {
            ...state,
            showAddressBookModal: false,
            showEditBasketModal: false,
            showSavedCollectionPointsModal: false,
          };
      }
    }

    case `${PUT_CC_DELIVERY_DETAILS}.SUCCESS`: {
      return {
        ...state,
        shouldSetConfirmedDeliveryAddressAgain: true,
      };
    }

    case SET_EDIT_BASKET_BASE_ROUTE:
      return {
        ...state,
        editBasketModalBaseRoutePath: action.baseRoutePath,
      };

    case SHOW_EMPTY_BASKET_NOTIFICATION:
      return {
        ...state,
        showEditBasketModal: false,
      };

    case SET_SELECTED_DATE_METHOD: {
      const deliveryToUpdate = state.deliveries?.find((deliveryRecord) => deliveryRecord.id === action.deliveryId);

      return {
        ...state,
        deliveries: state.deliveries?.map((d) => {
          if (deliveryToUpdate?.id === d.id) {
            return {
              ...d,
              selectedMethod: action.displayMethod,
            };
          }

          return d;
        }),
      };
    }

    case SET_SELECTED_SLOT_METHOD: {
      const deliveryToUpdate = state.deliveries?.find((deliveryRecord) => deliveryRecord.id === action.delivery.id);

      return {
        ...state,
        deliveries: state.deliveries?.map((d) => {
          if (deliveryToUpdate?.id === d.id) {
            return {
              ...d,
              selectedMethod: action.displayMethod,
              selectedMethodIsExpress: action.express,
            };
          }

          return d;
        }),
      };
    }

    case SET_SELECTED_DATE_METHOD_PAGE: {
      const deliveryToUpdate = state.deliveries?.find((deliveryRecord) => deliveryRecord.id === action.delivery.id);

      return {
        ...state,
        deliveries: state.deliveries?.map((d) => {
          if (deliveryToUpdate?.id === d.id) {
            const selectedPages = d.selectedPages || [];
            const id = action.method.id;

            const pageObject = {
              id,
              page: action.newPage,
              shouldAnimate: action.shouldAnimate,
            };

            const indexToReplace = selectedPages.findIndex((page) => page.id === id);

            if (indexToReplace >= 0) {
              selectedPages[indexToReplace] = pageObject;
            } else {
              selectedPages.push(pageObject);
            }

            return {
              ...d,
              selectedPages,
            };
          }

          return d;
        }),
      };
    }

    case REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET:
      return {
        ...state,
        itemsNeedingAgeVerification: [],
        postAgeVerificationApiCallsActive: true,
      };

    case `${REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET}.SUCCESS`:
      return {
        ...state,
        postAgeVerificationApiCallsActive: false,
        usePostToInitDeliveryPage: false,
        ...(action?.itemsSavedForLater && { showAgeVerificationSaveForLaterToast: true }),
      };

    case PAYPAL_PAYMENT_FAILED:
    case PAYPAL_PAYMENT_CANCEL:
      if (action.isPaypalExpress) {
        return {
          ...state,
          shouldSetConfirmedDeliveryAddressAgain: !!state.confirmedDeliveryAddress,
        };
      }

      return state;

    case `${GET_PAYMENT_WALLET}.SUCCESS`:
      return {
        ...state,
        postAgeVerificationApiCallsActive: false,
      };

    case APPLE_PAY_PAYMENT_CANCEL:
    case APPLE_PAY_PAYMENT_FAILURE:
      const expressBasketNeedsCleanup = action?.express && action?.enhancedFeatureActive;

      return {
        ...state,
        applePayExpressConfig: undefined,
        applePayEnhancedShippingIdentifier: undefined,
        ...(expressBasketNeedsCleanup && {
          shouldSetConfirmedDeliveryAddressAgain: !!state.confirmedDeliveryAddress,
        }),
      };

    case `${GET_APPLE_PAY_EXPRESS_PAYMENT_REQUEST}.SUCCESS`:
      const result = action?.result;

      return {
        ...state,
        applePayExpressConfig: {
          total: result?.total,
          modifiers: result?.modifiers,
        },
        applePayEnhancedShippingIdentifier: result?.modifiers?.[0]?.data?.additionalShippingMethods?.[0]?.identifier,
      };

    case SET_APPLE_PAY_ENHANCED_SHIPPING_IDENTIFIER:
      return {
        ...state,
        applePayEnhancedShippingIdentifier: action?.applePayEnhancedShippingIdentifier,
      };

    case SHOULD_USE_FASTER_COLLECTION:
      return {
        ...state,
        shouldUseFasterCollection: true,
      };

    case SELECT_COLLECTION_POINT:
      return {
        ...state,
        shouldUseFasterCollection: false,
      };

    default:
      return state;
  }

}
