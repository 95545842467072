// Config
import { actionsConfig } from '@utils/analytics';
import { getNestedProperties } from '@utils/object';

const isPlainObject = (value) => !!value && Object.getPrototypeOf(value) === Object.prototype;

const evalCondition = (action, actionConfig) => {
  const condition = actionConfig?.condition;
  if (condition) {
    const { prop, value } = condition;
    const actionPropValue = getNestedProperties(action, prop);

    if (isPlainObject(value)) {
      const newActionConfig = value[actionPropValue] || value.default;
      return evalCondition(action, newActionConfig);
    }

    const { condition: _, ...restConfig } = actionConfig ?? {};
    return actionPropValue === value ? evalCondition(action, restConfig) : undefined;
  }

  return actionConfig;
};

export default function getConfig(action, config = actionsConfig) {
  // console.warn('analyticsConfig:', action);

  let actionConfig;

  if (isPlainObject(action)) {
    const { type } = action;
    actionConfig = config[type];

    if (actionConfig) {
      if (Array.isArray(actionConfig.conditions)) {
        actionConfig = actionConfig.conditions.reduce((acc, config) => {
          const actionConfig = evalCondition(action, config);
          return actionConfig || acc;
        }, undefined);
      } else if (actionConfig.condition) {
        actionConfig = evalCondition(action, actionConfig);
      }
    }
  }

  return actionConfig;
}
