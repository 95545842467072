import { createSelector } from 'reselect';

// Config
import {
  getConfirmationIsClickCollectOrderSelector,
  getConfirmationIsMultiBatchSelector,
  getConfirmationOrderAmountsSelector,
  getConfirmationOrderDeliveriesSelector,
  getConfirmationPromoCodeAppliedSelector,
} from '@redux/reducers/confirmation/orderConfirmationSelector';
import {
  getApplyRewardsApiCallActiveSelector,
  getOrderFormAmountsSelector,
  getOrderFormIsClickCollectOrderSelector,
  getOrderFormIsGiftReceiptOrderSelector,
  getOrderFormPromoCodeAppliedSelector,
} from '@redux/reducers/order-form/orderFormSelector';

const orderTotalsOrderFormState = createSelector(
  [
    getOrderFormAmountsSelector,
    getOrderFormIsGiftReceiptOrderSelector,
    getOrderFormIsClickCollectOrderSelector,
    getOrderFormPromoCodeAppliedSelector,
    getApplyRewardsApiCallActiveSelector,
  ],
  (amounts, isGiftReceiptOrder, isClickCollectOrder, promotionalCode, applyRewardsApiCallActive) => ({
    amounts,
    applyRewardsApiCallActive,
    isGiftReceiptOrder,
    isClickCollectOrder,
    promotionalCode,
  }),
);

const orderTotalsConfirmationPagePropsState = createSelector(
  [
    getConfirmationOrderAmountsSelector,
    getConfirmationIsMultiBatchSelector,
    getConfirmationOrderDeliveriesSelector,
    getConfirmationIsClickCollectOrderSelector,
    getConfirmationPromoCodeAppliedSelector,
    getApplyRewardsApiCallActiveSelector,
  ],
  (amounts, isMultiBatch, deliveries, isClickCollectOrder, promotionalCode, applyRewardsApiCallActive) => ({
    amounts,
    applyRewardsApiCallActive,
    isGiftReceiptOrder: !isMultiBatch && deliveries?.some((delivery) => delivery.isGiftReceiptOrder),
    isClickCollectOrder,
    promotionalCode,
  }),
);

export { orderTotalsConfirmationPagePropsState, orderTotalsOrderFormState };
