// Config
import decodeBase64 from '@utils/helpers/base64Decode';

const browserInfo = () => {
  const iframeDOM = document.getElementById('browser-iframe')?.contentWindow?.document;
  let header;
  let clientIp;
  if (iframeDOM) {
    header = iframeDOM.getElementById('acceptHeader')?.value;
    clientIp = iframeDOM.getElementById('clientIp')?.value;
  }
  return {
    acceptHeader: decodeBase64(header,
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*'),
    javaScriptEnabled: true,
    language: navigator.language || navigator.userLanguage,
    screenColorDepth: window.screen.colorDepth,
    screenResolution: `${window.screen.width}X${window.screen.height}`,
    timezoneOffsetMinutes: new Date().getTimezoneOffset(),
    userAgent: navigator.userAgent,
    clientIp,
  };
};

export default browserInfo;
