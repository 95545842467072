/* eslint-disable css-modules/no-unused-class */

// Design System
import InputManageCursor from 'jl-design-system/elements/input-manage-cursor/InputManageCursor';

// Types
import { InputSecurityCodeProps } from './InputSecurityCode.types';

// Components
import Container from '@components/container';

// Styles
import styles from './security-code-types.scss';

const InputSecurityCode = ({ inputWrapperClassName = '', ...props }: InputSecurityCodeProps) => (
  <Container className={styles[inputWrapperClassName]} testId="field-wrapper">
    <InputManageCursor {...props} data-testid="input-manage-cursor-field" />
  </Container>
);

export default InputSecurityCode;
