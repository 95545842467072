// Types
import { AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import {
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED,
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED,
  ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA,
} from '../../../constants/actionConstants';

export const triggerAnalyticsEvent = (type: string, payload = {}) => ({
  type,
  ...payload,
});

export const triggerAnalyticsForUncompletedActions = (
  uncompletedActions: string | string[],
) => (dispatch: AppDispatch) => {
  if (uncompletedActions.includes('linkOrderToAccount') && uncompletedActions.includes('savePersonalDetails')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED));
  } else if (uncompletedActions.includes('linkOrderToAccount')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED));
  } else if (uncompletedActions.includes('savePersonalDetails')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED));
  } else if (uncompletedActions.includes('savePaymentCard')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED));
  }
};

// TODO replace deliveryActions.setUpdatedProductAndPaymentAnalytics with this as they do almost exactly the same thing
export const orderFormProductAnalyticsUpdate = (
  paymentAnalytics: any,
  productAnalytics: {
    sku?: string;
  }[],
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    delivery: {
      deliveries = [],
      items = [],
    } = {},
  } = getState() ?? {};

  const updatedProductAnalytics = items.map((item: {
    id?: string;
    skuId?: string;
  }) => {
    const matchedProduct = productAnalytics.find((product: {
      sku?: string;
    }) => item?.skuId === product.sku);

    if (matchedProduct) {
      const delivery = deliveries.find(delivery =>
        delivery?.items?.some(deliveryItem => deliveryItem?.id === item?.id),
      );

      return {
        ...matchedProduct,
        batchNum: delivery?.id,
      };
    }

    return null;
  });

  dispatch({
    type: ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA,
    paymentAnalytics,
    productAnalytics: updatedProductAnalytics,
  });
};

export default triggerAnalyticsEvent;
