// Config
import deliveryConstants, { undeliverableReasonConstants } from '@constants/deliveryConstants';
import { isEmptyObject } from '@utils/object';

export default function getUndeliverableItems(deliveries = [], items = []) {
  const restrictedItemsInDeliveries = deliveries
    .map((d) =>
      d.items
        ? d.items.filter((item) => item.undeliverableReasons?.includes(undeliverableReasonConstants.RESTRICTED))
        : [],
    )
    .reduce((acc, items) => acc.concat(items.map((item) => item.id)), []);
  const undeliverableDeliveries = deliveries.filter((d) => d.type === deliveryConstants.UNDELIVERABLE);

  if (!isEmptyObject(restrictedItemsInDeliveries)) {
    const restrictedItemIds = new Set(restrictedItemsInDeliveries);
    const matchedItems = [];

    items.forEach((item) => {
      if (restrictedItemIds.has(item.id)) {
        matchedItems.push(item);
      }

      if (item.childItems) {
        item.childItems.forEach((childItem) => {
          if (restrictedItemIds.has(childItem.id)) {
            matchedItems.push(childItem);
          }
        });
      }
    });

    if (!isEmptyObject(matchedItems)) return matchedItems;
  }

  if (!isEmptyObject(undeliverableDeliveries)) {
    return undeliverableDeliveries
      .map((d) =>
        items
          .filter((item) => d.items.map((delivery) => delivery.id).includes(item.id))
          .map((preOrderItem) => {
            const undeliverableItems = d.items?.filter((deliverableItem) => deliverableItem.id === preOrderItem.id);
            const undeliverableReasons = (undeliverableItems && undeliverableItems[0]?.undeliverableReasons) || [];
            return {
              ...preOrderItem,
              ...(undeliverableReasons?.length && { undeliverableReasons }),
              ...(d.streetDate && { streetDate: d.streetDate }),
            };
          }),
      )
      .flat();
  }

  return null;
}
