import { useSelector } from 'react-redux';

// Design System
import { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { SidebarProps } from './Sidebar.types';

// Config
import sidebarState from './Sidebar.state';
import urlConstants from '@constants/urlConstants';
import { useHandbackToUrl } from '@hooks/useHandbackToUrl';
import { getItemQuantities } from '@redux/reducers/payment/orderFormReducer';

// Components
import Container from '@components/container';
import MyAccountLink from '@components/my-account-link';
import { MyJohnLewis } from '@components/my-john-lewis';
import MembershipNotification from '@components/my-john-lewis/myjl-member-notification';
import OrderSummary, { OrderSummaryTitle } from '@components/order-summary';
import OrderTotals from '@components/order-totals';
import PaymentDetails from '@components/payment-details';
import VatRefundMessage from '@components/vat-refund-message';

// Styles
import styles from './sidebar.scss';

export const SidebarContent = ({ isDeliveryPage = false, isOrderConfirmationPage = false }: SidebarProps) => {
  const {
    expiredSignedIn,
    isApplication,
    isGuest,
    items = [],
    order,
    showVatRefundMessage,
  } = useSelector(sidebarState);
  const handbackProps = useHandbackToUrl(urlConstants.HOME_PAGE_URL);

  if (isOrderConfirmationPage) {
    const showMyAccountLink = !isGuest && !isApplication && !expiredSignedIn;
    const totalQuantity = Object.values(getItemQuantities(items ?? [])).reduce<number>((sum, value) => sum + value, 0);

    return (
      <Container testId="sidebar-content">
        <Container background="white" className={styles.wrapper} marginBottom="2">
          <OrderSummaryTitle label="Order Summary" totalQuantity={totalQuantity} />
          <OrderTotals confirmationPage sidebarView />
          <PaymentDetails payments={order?.payments} />
        </Container>

        {showVatRefundMessage && <VatRefundMessage />}

        <Container className={styles.actions}>
          {handbackProps && (
            <PrimaryLink
              className={styles.primaryLink}
              data-testid="sidebar-continue-shopping"
              external
              {...handbackProps}
            >
              Continue shopping
            </PrimaryLink>
          )}

          {showMyAccountLink && (
            <MyAccountLink className={styles.secondaryLink} level="secondary" text="View online orders" />
          )}
        </Container>
      </Container>
    );
  }

  const renderOrderTotals = () => {
    return <OrderTotals isDeliveryPage={isDeliveryPage} sidebarView />;
  };

  return (
    <Container background="white" className={styles.wrapper} testId="sidebar-content">
      <OrderSummary items={items} sidebarView />
      {renderOrderTotals()}
    </Container>
  );
};

const Sidebar = ({ isDeliveryPage = false, isOrderConfirmationPage = false }: SidebarProps) => {
  const { isMyJLMember, joinMyJLFailed, joinMyJLSuccess, showMyJLAccountPrompt } = useSelector(sidebarState);

  return (
    <aside className={styles.container} data-testid="sidebar-container">
      <div className={styles.innerContainer}>
        {isMyJLMember && <MembershipNotification />}
        <SidebarContent isDeliveryPage={isDeliveryPage} isOrderConfirmationPage={isOrderConfirmationPage} />

        {!joinMyJLFailed && showMyJLAccountPrompt && isOrderConfirmationPage && (
          <MyJohnLewis joinMyJLSuccess={joinMyJLSuccess} sidebarView />
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
