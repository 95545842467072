import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import { Message } from 'jl-design-system/components/message/Message';
import Link from 'jl-design-system/elements/link/Link';
import getRegisteredFieldValues from 'jl-design-system/form/helpers/getRegisteredFieldValues';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import loginState from './Login.state';
import urlConstants from '@constants/urlConstants';
import { useHandbackToUrl } from '@hooks/useHandbackToUrl';
import { submitAndHandleLogin } from '@redux/actions/login/loginActions';
import setPageTitle from '@utils/helpers/pageTitle';

// Components
import Container from '@components/container';
import Heading from '@components/heading';
import LoginChoice from '@components/login-choice';

const Login = () => {
  useEffect(() => {
    setPageTitle('Sign in');
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const { auth0Failure, loginApiCallActive, loginForm, showEmailValidationError, userEmail } = useSelector(loginState);
  const handbackProps = useHandbackToUrl(urlConstants.BASKET_PAGE_URL);

  const onFormSubmit = (data = {}) => {
    // ensure only registered fields are submitted in form
    const body = { ...getRegisteredFieldValues(data, loginForm?.registeredFields) };
    return dispatch(submitAndHandleLogin(body));
  };

  return (
    <Container background="white" maxWidth="sm" padding="3" testId="login-container">
      <Heading label="Welcome to secure checkout" marginBottom="3" testId="login-title" type="s" />

      {auth0Failure && (
        <Container marginBottom="2">
          <Message
            body={
              <>
                You can{' '}
                <Link external {...handbackProps}>
                  return to your basket
                </Link>{' '}
                and try to sign in again, or you can check out as a guest.
              </>
            }
            dataTestId="fallback-error-message"
            title="Sorry, we can't sign you in at the moment"
            type="error"
          />
        </Container>
      )}

      <LoginChoice
        auth0Failure={auth0Failure}
        loginApiCallActive={loginApiCallActive}
        onFormSubmit={onFormSubmit}
        showEmailValidationError={showEmailValidationError}
        userEmail={userEmail}
      />
    </Container>
  );
};

export default Login;
