import { lazy } from 'react';

// Types
import { EditBasketModalProps as LazyComponentProps } from './EditBasketModal.types';

// Components
import withSuspense, { lazyRetry } from '@components/suspense';

const LazyComponent = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "editBasketModal" */ './EditBasketModal')),
);

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'EditBasketModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as EditBasketModal };
