import { push } from 'connected-react-router';
import routeConstants from '@constants/routeConstants';
import errorCodeConstants from '@constants/errorCodeConstants';

import { triggerAnalyticsEvent } from '@redux/actions/analytics/analyticsAction';
import {
  recordCollectionPaymentPageTimings,
  recordDeliveryPaymentPageTimings,
  recordPaymentTimings,
} from '@redux/actions/timing/timingActions';
import {
  ANALYTICS_DELIVERY_OPTIONS,
  ANALYTICS_SUBMIT_ORDER_ERROR,
  APPLE_PAY_SELECTED,
  GET_PAYMENT_PAGE,
  GOOGLE_PAY_DEFAULT,
  GOOGLE_PAY_ENABLED,
  INIT_PAYMENT_PAGE,
  SAVED_CARD_SELECTED,
  VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE,
  GET_ORDERFORM_DELIVERIES,
} from '@constants/actionConstants';
import {
  URL_PAYMENT_PAGE,
  URL_DELIVERIES_V3,
} from '@constants/endpointConstants';
import { canMakeGooglePayPayments } from '@redux/actions/payment/googlePayActions';
import { getItems, showBatchingFailureModal } from '@redux/actions/edit-basket/editBasketActions';
import {
  getDeliveryMethodsAnalytics,
} from '../delivery/deliveryActions';
import { selectClickAndCollectStoreAnalytics } from '@utils/analytics/utils/getCollectionAnalytics';
import handleInconsistentOrderFormState from '@utils/orderform/handleInconsistentOrderFormState';
import paymentTypeConstants from '@constants/paymentTypeConstants';
import featureConstants from '@constants/featureConstants';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { isClickAndCollectChoice, isDeliveryChoice } from '@utils/delivery/deliveryHelper';
import { shouldTriggerApplyRewards } from '@utils/payment/shouldApplyRewards';

import { applyRewards } from './rewardsActions';
import determineSelectedPaymentType from './selectedPaymentTypeActions';
import { getPayPalClientToken } from './payPalPaymentActions';

const dispatchPayOptionAnalytics = customerCanMakeGooglePayPayments => async (dispatch, getState) => {
  // `payment.selectedPaymentType` should be taken from actual state after success InitPaymentPage
  const selectedPaymentType = getState().payment?.selectedPaymentType;
  if (selectedPaymentType?.includes(paymentTypeConstants.SAVED_PAYMENT_CARD)) {
    dispatch(triggerAnalyticsEvent(SAVED_CARD_SELECTED));
  }

  const applePaySelected = selectedPaymentType?.includes(paymentTypeConstants.APPLE_PAY);
  if (applePaySelected) {
    dispatch(triggerAnalyticsEvent(APPLE_PAY_SELECTED));
  }

  if (customerCanMakeGooglePayPayments) {
    dispatch(triggerAnalyticsEvent(GOOGLE_PAY_ENABLED));
    if (selectedPaymentType?.includes(paymentTypeConstants.GOOGLE_PAY)) {
      dispatch(triggerAnalyticsEvent(GOOGLE_PAY_DEFAULT));
    }
  }
};

export const initPaymentPage = (params = {}) => async (dispatch, getState) => {
  const {
    getOnly,
    eventDetails,
    pushAfter,
    shouldRecordDeliveryTimings,
  } = params;

  const {
    app,
    clickAndCollect,
    orderForm,
    delivery,
    payment,
  } = getState();

  if (payment?.getPaymentPageCallActive) {
    // try to stop mysterious duplicate calls to payment-page
    return {};
  }

  const selectedCollectionPoint = clickAndCollect?.selectedCollectionPoint;
  const deliveries = delivery?.deliveries ?? [];
  const confirmedDeliveryAddress = delivery?.confirmedDeliveryAddress;
  const selectedDeliveryChoiceId = delivery?.selectedDeliveryChoiceId;
  const orderNeedsToBeRebatched = orderForm?.orderNeedsToBeRebatched;

  const disableInitPaymentPage = orderNeedsToBeRebatched ||
    (app?.orderComplete ?? false) ||
    (app?.orderProcessing ?? false);

  if (disableInitPaymentPage) {
    return {};
  }

  const notReadyForPayment = (
    (isDeliveryChoice(selectedDeliveryChoiceId) && !confirmedDeliveryAddress) ||
    (isClickAndCollectChoice(selectedDeliveryChoiceId) && !selectedCollectionPoint) ||
    !selectedDeliveryChoiceId
  );

  if (notReadyForPayment) {
    const pathname = isClickAndCollectChoice(selectedDeliveryChoiceId) ?
      routeConstants.CLICK_AND_COLLECT : routeConstants.DELIVERY;
    dispatch(push({ pathname }));
    return {};
  }

  if (isDeliveryChoice(selectedDeliveryChoiceId)) {
    dispatch(getDeliveryMethodsAnalytics(delivery?.deliveries));
  }

  if (isClickAndCollectChoice(selectedDeliveryChoiceId)) {

    const searchTerm = clickAndCollect?.collectionPointSearchTerm;
    const collectionDates = clickAndCollect?.collectionPointDates;
    const analytics = selectClickAndCollectStoreAnalytics(
      selectedCollectionPoint,
      searchTerm,
      collectionDates,
    );

    dispatch(triggerAnalyticsEvent(ANALYTICS_DELIVERY_OPTIONS, {
      analytics,
    }));
  }

  const customerCanMakeGooglePayPayments = getState().payment?.canMakeGooglePayPayments;
  if (customerCanMakeGooglePayPayments) {
    await dispatch(canMakeGooglePayPayments());
  }

  const paymentPageResponse = await dispatch({
    type: getOnly ? GET_PAYMENT_PAGE : INIT_PAYMENT_PAGE,
    request: client => client({
      path: URL_PAYMENT_PAGE,
      config: { method: getOnly ? 'GET' : 'POST' },
    }),
    selectedCollectionPoint,
    deliveries,
    ...pushAfter && { pushAfter },
    ...eventDetails && { eventDetails },
  });

  if (!paymentPageResponse?.type?.includes('FAILED')) {
    dispatch(getOrderFormData({
      isCustomerAction: true,
    }));
  }

  if (paymentPageResponse.type === `${INIT_PAYMENT_PAGE}.SUCCESS`) {

    if (shouldRecordDeliveryTimings) {
      if (isClickAndCollectChoice(selectedDeliveryChoiceId)) {
        dispatch(recordCollectionPaymentPageTimings());
      }

      if (isDeliveryChoice(selectedDeliveryChoiceId)) {
        dispatch(recordDeliveryPaymentPageTimings());
      }
    }

    dispatch(recordPaymentTimings());

    await dispatch(determineSelectedPaymentType());

    const isPayPalDisabled = isFeatureActive(getState(), featureConstants.DISABLE_PAYPAL);

    if (!isPayPalDisabled && payment && !payment.payPalClientToken) {
      const showError = false;
      dispatch(getPayPalClientToken(showError));
    }
  }

  if (!pushAfter) {
    dispatch(dispatchPayOptionAnalytics(customerCanMakeGooglePayPayments));
  }

  const error = paymentPageResponse?.error?.code ?? '';
  if (error === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE) {
    await dispatch(handleInconsistentOrderFormState());
    return {};
  }

  if (pushAfter) {
    dispatch(push(routeConstants.PAYMENT));
    dispatch(dispatchPayOptionAnalytics(customerCanMakeGooglePayPayments));
  }

  return paymentPageResponse;
};

export const getPaymentPage = eventDetails => (initPaymentPage({ getOnly: true, eventDetails }));

export const getPaymentPageAfterSubmitOrderError = submitOrderAction => async (dispatch) => {
  const response = await dispatch(initPaymentPage({
    getOnly: true,
    eventDetails: {
      posCreditSubmitOrderError:
        submitOrderAction.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false,
    },
  }));

  if (response.type === `${GET_PAYMENT_PAGE}.SUCCESS`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_SUBMIT_ORDER_ERROR, { error: submitOrderAction.error }));
  }
};

export const closeVoucherUnderspendWarningModal = () => ({
  type: VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE,
});

export const refreshItems = (
  lineItemTotalBeforePutAddress,
  deliveriesAfterPutAddress = [],
) => async (dispatch) => {
  const newLineItemTotal = deliveriesAfterPutAddress.flatMap(delivery => delivery?.items)?.length;
  const lineItemTotalHasChanged = newLineItemTotal !== lineItemTotalBeforePutAddress;

  if (lineItemTotalHasChanged) return dispatch(getOrderFormData());

  return {};
};

export const getOrderFormData = ({
  isAgeVerification = false,
  isCustomerAction = false,
  deliveriesV3FeatureActive = false,
} = {}) => async (dispatch, getState) => {
  if (shouldTriggerApplyRewards(getState())) {
    const rewardsResponse = await dispatch(applyRewards({ isCustomerAction, deliveriesV3FeatureActive }));

    if (rewardsResponse) return rewardsResponse;
  }

  return dispatch(getItems({ isAgeVerification, deliveriesV3FeatureActive }));
};

export const getOrderFormDeliveries = ({ method = 'POST' } = {}) => async (dispatch) => {
  const response = await dispatch({
    type: GET_ORDERFORM_DELIVERIES,
    request: client => client({ path: URL_DELIVERIES_V3, config: { method } }),
  });

  if (response?.type?.includes('FAILED') && response?.error?.code === errorCodeConstants.BATCHING_FAILURE) {
    const batchingFailureIds = response?.error?.inconsistentBatchProducts ?? [];
    await dispatch(showBatchingFailureModal(batchingFailureIds));
  }

  return response;
};
