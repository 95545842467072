// Config
import getCorrelationId from '@utils/helpers/correlationId';

export const URL_CONFIGURATION = 'v1/configuration';
export const URL_FEATURES = 'v1/features';

// web handover
export const URL_WEB_HANDOVER = 'v2/sessions/web/handover';

export const URL_APPS_HANDOVER = 'v2/sessions/app/handover';
export const URL_REFRESH_SESSION = 'v2/sessions';

// delivery
export const URL_GET_DELIVERY_PAGE = 'v2/orderforms/delivery-page';
export const URL_POST_DELIVERY_PAGE = 'v1/orderforms/delivery-page';
export const URL_DELIVERY_PAGE = 'v3/orderforms/delivery-page';
export const URL_PUT_DELIVERY_DETAILS = 'v3/orderforms/deliveries/details';
export const URL_REMOVE_ITEMS_FROM_BASKET = (itemIds) => `v1/orderforms/items?ids=${itemIds}`;
export const URL_SAVE_FOR_LATER = 'v1/orderforms/items/save-for-later';

export const URL_GET_DELIVERIES_METHODS_V3 = ({ id, from }) =>
  from ? `v3/orderforms/deliveries/${id}/methods?from=${from}` : `v3/orderforms/deliveries/${id}/methods`;

export const URL_POST_NEW_DELIVERY_ADDRESS = 'v3/orderforms/deliveries/delivery-address';
export const URL_PUT_SAVED_DELIVERY_ADDRESS = (id) => `v3/orderforms/deliveries/delivery-address/${id}`;

export const URL_REBATCH_ORDER = 'v2/orderforms/deliveries';
export const URL_DELIVERIES_V3 = 'v3/orderforms/deliveries';

// trade-in
export const URL_REMOVE_TRADE_IN_ITEM = (itemId) => `v1/orderforms/trade-ins/${itemId || ''}`;

// click and collect
export const URL_COLLECTION_DETAILS = 'v1/orderforms/collection/details';

export const URL_COLLECTION_POINTS = (collectionPointSearchTerm) =>
  `v3/collection-points?q=${collectionPointSearchTerm || ''}`;

export const URL_COLLECTION_DATES = 'v3/collection-points/collection-dates';

export const URL_COLLECTION_POINT_BY_ID = (collectionPointId) => `v3/collection-points?id=${collectionPointId}`;

export const URL_DELETE_COLLECTION_POINT = (collectionPointId) => `v1/customers/collection-points/${collectionPointId}`;

// item quantity
export const URL_PUT_ITEM_QUANTITY = (itemId) => `v2/orderforms/items/${itemId}`;
export const URL_PUT_ITEMS_QUANTITIES = 'v2/orderforms/items';
export const URL_GET_ITEMS = 'v1/orderforms/items';

// preferences
export const URL_PREFERENCES_PAYMENT_CARD = 'v1/customers/preferences/payment-card';
export const URL_PREFERENCES_DELIVERY = 'v1/customers/preferences/delivery';
export const URL_PREFERENCES_COLLECTION = 'v1/customers/preferences/collection';
export const URL_DELETE_FULFILMENT_PREFERENCE = 'v2/customers/preferences/preferred-fulfilment-option';
export const URL_PREFERENCES_PAYMENT_METHOD = 'v1/customers/preferences/payment';

// confirmation
export const URL_CONFIRMATION_PAGE = 'v1/orders/confirmation-page';
export const URL_SIGN_OUT_USER_V3 = 'v3/sessions/sign-out';

export const URL_JOIN_MY_JOHN_LEWIS = 'v1/customers/my-jl-subscription';

// save order
export const URL_SAVE_ORDER = 'v1/customers/orders';

// address-book
export const URL_ADDRESSES = 'v1/customers/addresses';
export const URL_REMOVE_ADDRESS = (addressId) => `v1/customers/addresses/${addressId}`;

// collection points
export const URL_CUSTOMER_COLLECTION_POINTS = 'v1/customers/collection-points';

// payment cards
export const URL_CUSTOMER_PAYMENT_CARDS = 'v1/customers/payment-cards';
export const URL_CUSTOMER_PAYMENT_CARD_DELETE = (cardId) => `v2/customers/payment-cards/${cardId}`;

// login
export const URL_LOGIN_PAGE_AUTH0_FALLBACK = (fallback) => `v1/orderforms/login-page?${fallback}=true`;
export const URL_LOGIN_SESSIONS_V2 = 'v2/sessions';
export const URL_LOGIN_SESSIONS_V2_WITH_LOGIN_TYPE = (loginType) => `v2/sessions?loginType=${loginType}`;

// payment - gift-card and voucher
export const URL_GIFT_CARD_APPLY = 'v2/orderforms/payments/gift-card';
export const URL_GIFT_CARD_REMOVE = (id) => `v1/orderforms/payments/${id || ''}`;
export const URL_GIFT_VOUCHER = 'v2/orderforms/payments/gift-voucher';
export const URL_GIFT_TOKENS = 'v2/orderforms/payments/gifting/tokens';

// payment - card-payment
export const URL_CARD_PAYMENT_BILLING_ADDRESS = 'v1/orderforms/payments/billing-address';

// payment - card-payment - ocp
export const URL_OCP_GET_PAYMENT_ID = 'v1/orderforms/payments/tokenized-credit-card';
export const URL_OCP_GET_PAYMENT_ID_CLEARPAY = 'v1/orderforms/payments/clearpay';
export const URL_OCP_GET_PAYMENT_ID_KLARNA = 'v1/orderforms/payments/klarna';
export const URL_OCP_GET_CORRELATION_ID = 'v1/correlation-id';
export const URL_OCP_GET_SUBMISSION_SCRIPT_V3 = (paymentId) => `v3/payments/${paymentId}/submission-script`;
export const URL_OCP_SUBMIT_3DS_METHOD = (paymentId) => `v3/payments/${paymentId}/authentication/method`;
export const URL_OCP_SUBMIT_CARD_DETAILS = (paymentId) => `v3/payments/${paymentId}`;
export const URL_OCP_AUTHENTICATION = (ocpTargetUrl) => `${ocpTargetUrl}/authentication`;
export const URL_OCP_GET_3DS_INFO = 'v1/orderforms/payments/tokenized-card-3dsecure-info';

// payment - saved card OCP
export const URL_OCP_PAYMENT_WITH_SAVED_CARD = 'v1/orderforms/payments/saved-tokenized-credit-card';

// payment - order form
export const URL_PAYMENT_PAGE = 'v2/orderforms/payment-page';

// payment - payment-actions
export const URL_MARKETING_PREFERENCES_V3 = 'v3/customers/marketing-preferences';
export const URL_PAYMENTS = 'v1/orderforms/payments';

// payment - paypal
export const URL_GET_PAYPAL_CLIENT_TOKEN = 'v3/payments/paypal/token';
export const URL_AUTHENTICATE_PAYPAL_PAYMENT = 'v3/payments/paypal';
export const URL_PAYPAL_EXPRESS_REQUEST = 'v2/payments/paypal/express-payment-request';

// payment - promo-code
// URL_PROMOTIONS will redirect to payments endpoint in backend before returning to UI
export const URL_PROMOTIONS = 'v1/orderform/promotions';
// URL_PROMOTIONS_V2 will not redirect to payments endpoint in backend, payments must be called manually by UI if required
export const URL_PROMOTIONS_V2 = 'v2/orderform/promotions';
export const URL_REMOVE_PROMOTION = (promotionId) => `v1/orderform/promotions/${promotionId || ''}`;

// payment - submit-order
export const URL_SUBMIT_ORDERS = 'v2/orders';
export const URL_SUBMIT_ORDERS_RETRY = 'v2/orders?retry=true';
export const URL_SUBMIT_ORDERS_POS_CREDIT = 'v1/orders/pos-credit';

// customer
export const URL_UPDATE_CUSTOMER = 'v1/customers';

// partner-discount
export const URL_PARTNER_DISCOUNT = 'v1/orderforms/partner-discount';

export const URL_CALLBACK_LOGIN_GUEST_V4 = 'v4/callback/guest';
export const URL_CALLBACK_LOGIN_AUTHENTICATED_V4 = 'v4/callback/login';
export const URL_CALLBACK_CLAIM_ORDER = 'v4/callback/claim-order';
export const URL_CALLBACK_REGISTRATION = 'v4/callback/registration';
// apple pay
export const URL_CREATE_APPLE_PAY_PAYMENT = 'v1/orderforms/payments/apple-pay';
export const URL_REQUEST_APPLE_PAY_SESSION = 'v1/apple-pay/payment-session';
export const URL_APPLE_PAY_EXPRESS_REQUEST = 'v1/payments/apple-pay/express-payment-request';

// google pay
export const URL_CREATE_GOOGLE_PAY_PAYMENT = 'v1/orderforms/payments/google-pay';

// payment - pos-credit
export const URL_POS_CREDIT = 'v1/payments/pos-credit';

// auth0
export const URL_AUTH0_HEALTH_CHECK = 'v1/health/auth0';

export const EMAIL_VALIDATION_SERVICE_INT =
  'https://api.integration.project4.com/customer-identity/email-validator/validate';
export const EMAIL_VALIDATION_SERVICE_PROD = 'https://api.johnlewis.com/customer-identity/email-validator/validate';

// age verification
export const URL_AGE_VERIFICATION_SESSION = 'v1/customers/age-verification/sessions';
export const URL_AGE_VERIFICATION_CALLBACK = 'v1/customers/age-verification/callback';

// rewards
export const URL_REWARDS_APPLY = 'v1/orderform/rewards/apply';
export const URL_REWARDS_REMOVE = (campaignId) => `v1/orderform/rewards/${campaignId}`;

// impressions
export const URL_RECORD_IMPRESSIONS = 'v1/impressions';

export const GET_OCP_HEADERS = (state) => {
  const correlationId = getCorrelationId();

  const sessionId = state?.bff?.sessionId ?? '';
  const omsOrderId = state?.orderForm?.omsOrderId ?? '';
  const orderFormId = state?.orderForm?.id ?? '';

  return {
    JSESSIONID: sessionId,
    'JL-BASKET-ID': orderFormId,
    'JL-OMS-ORDER-ID': omsOrderId,
    'JL-CALLING-APP-V1': 'OCC',
    Accept: 'application/vnd.jl-payments-v3+json',
    'Content-Type': 'application/vnd.jl-payments-v3+json',
    'JL-UI-CORRELATION-ID-V1': correlationId,
    'Accept-Language': '*',
  };
};

// express
export const URL_SET_PARTIAL_DELIVERY_ADDRESS = 'v1/orderforms/deliveries/express-pay/partial-delivery-address';
export const URL_SET_FULL_DELIVERY_ADDRESS = 'v1/orderforms/deliveries/express-pay/delivery-address';
