import { createSelector } from 'reselect';

// Design System
import env from 'jl-design-system/utils/env/env';

// Config
import { getOrderFormItemsSelector } from '@redux/reducers/order-form/orderFormSelector';

const PLACEMENT = 'OSPOrderConfirmation1';

const getProductAndSkuIdsSelector = createSelector([getOrderFormItemsSelector], (items = []) => {
  const productIds = items.map((item) => item.productId);
  const skuIds = items.map((item) => item.skuId);
  return { productIds, skuIds };
});

const getRecommendationUrlParamsSelector = createSelector(
  [getProductAndSkuIdsSelector],
  ({ productIds, skuIds }) => {
    const params = new URLSearchParams({
      placement: PLACEMENT,
      productId: productIds.join(),
      skuId: skuIds.join(),
    }).toString();
    return params;
  },
);

export const getRecommendationElementIdSelector = () => `jl-recommendations-panel-esi-${PLACEMENT.toLowerCase()}`;

const recommendationsState = createSelector([getRecommendationUrlParamsSelector], (params) => {
  if (env.isClientNonProd) {
    return `${window.location.origin}/stubs/esi/recommendations/?${params}`;
  }
  return `${window.publicJLSiteDomain}/esi/recommendations/?${params}`;
});

export default recommendationsState;
