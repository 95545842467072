import { format, isBefore, isEqual } from 'date-fns';

// Types
import { AppClient, AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import { USER_AGE_CHECK, UPDATE_CUSTOMER_DOB, GET_PAYMENT_WALLET } from '@constants/actionConstants';
import { URL_CUSTOMER_PAYMENT_CARDS, URL_UPDATE_CUSTOMER } from '@constants/endpointConstants';

export const updateCustomerDOB = (body: { dateOfBirth: string }) => ({
  type: UPDATE_CUSTOMER_DOB,
  request: (client: AppClient) => client({ path: URL_UPDATE_CUSTOMER, config: { method: 'PUT', body } }),
});

export const fetchPaymentWallet = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const userState = getState().user ?? {};
  const isSignedIn = userState.isSignedIn ?? false;
  const notFetched = !(userState.fetchedPaymentWallet ?? false);
  const isPartnerDiscountEnabled = userState.partnerDiscountEnabled ?? false;

  if (isSignedIn && notFetched) {
    return dispatch({
      type: GET_PAYMENT_WALLET,
      request: (client: AppClient) =>
        client({
          path: URL_CUSTOMER_PAYMENT_CARDS,
          config: {
            method: 'GET',
          },
        }),
      isPartnerDiscountEnabled,
    });
  }

  return {};
};

export const checkAgeRestrictionDate =
  (date: string | Date, updateCustomer?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const userState = getState().user ?? {};
    const ageCheckDate = userState.ageCheckDate ?? '';
    const isSignedIn = userState.isSignedIn ?? false;

    if (isEqual(date, ageCheckDate) || isBefore(date, ageCheckDate)) {
      if (isSignedIn && updateCustomer) {
        await dispatch(updateCustomerDOB({ dateOfBirth: format(date, 'YYYY-MM-DD') }));
      }

      dispatch({ type: `${USER_AGE_CHECK}.SUCCESS` });

      return;
    }

    dispatch({ type: `${USER_AGE_CHECK}.FAILED`, dob: date });
  };
