// Design System
import { scrollToElement as designSystemScrollToElement } from 'jl-design-system/utils/scroll/scrollTo';

const DELAY_MSECS = 100;

function scrollToElement({
  config = {},
  focus = undefined,
  id,
}: {
  config?: ScrollIntoViewOptions;
  focus?: boolean | string;
  id: string;
}) {
  // TODO make this test an exported utility from focusManager.js in the Design System
  if (document.body.classList.contains('jl-focusmanager-show')) return {};

  window.setTimeout(() => {
    const selector = `[data-scroll="${id}"]`;
    designSystemScrollToElement(selector, focus, config);
  }, DELAY_MSECS);

  return { type: 'SCROLL_TO_ELEMENT' };
}

export default scrollToElement;
