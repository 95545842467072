/* eslint-disable css-modules/no-unused-class */
import { useEffect, useRef, useState } from 'react';

// Types
import { LoadingWrapperProps } from './LoadingWrapper.types';

// Components
import LoadingDeliveryDetails from './loading-delivery-details';
import LoadingElement from './loading-element';
import LoadingOrderItems from './loading-order-items';
import Container from '../container';

// Styles
import styles from './loading-wrapper.scss';

const LoadingWrapper = ({
  children,
  className,
  componentType = 'loadingElement',
  isLoading = false,
  preserveChildren = false,
  totalItems = 1,
  type = 'light',
}: LoadingWrapperProps) => {
  const [loadingItems, setLoadingItems] = useState<number>(totalItems > 0 ? totalItems : 1);
  const [showLoading, setShowLoading] = useState<boolean>(isLoading);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (totalItems > 0) {
      setLoadingItems(totalItems);
    }
  }, [totalItems]);

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    } else if (showLoading && !timerRef.current) {
      timerRef.current = setTimeout(() => {
        setShowLoading(false);
        timerRef.current = null;
      }, 700);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isLoading, showLoading]);

  const componentMap = {
    deliveryDetails: LoadingDeliveryDetails,
    loadingElement: LoadingElement,
    orderItem: LoadingOrderItems,
  };

  const SelectedComponent = componentMap[componentType];

  if (preserveChildren) {
    return (
      <Container relative>
        {showLoading && <SelectedComponent className={className} loadingItems={loadingItems} type={type} />}
        <Container className={showLoading ? styles.isLoading : ''}>{children}</Container>
      </Container>
    );
  }

  return showLoading ? <SelectedComponent className={className} loadingItems={loadingItems} type={type} /> : children;
};

export default LoadingWrapper;
