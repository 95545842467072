import { useSelector } from 'react-redux';

// Config
import recommendationsState, { getRecommendationElementIdSelector } from './Recommendations.state';

// Components
import ESIInclude from '@components/esi-include/ESIInclude';

const Recommendations = () => {
  const src = useSelector(recommendationsState);
  const id = getRecommendationElementIdSelector();

  return <ESIInclude id={id} src={src} />;
};

export default Recommendations;
