// Types
import { ProductProps } from 'types/Product.types';

// Config
import deliveryConstants from '../../constants/deliveryConstants';
import { isEmptyObject } from '@utils/object';

export default function getUnavailableItems(items: ProductProps[] = []): {
  allItemsInBasketUnavailable: boolean;
  unavailableItemsInBasket: ProductProps[];
} {
  const unavailableItemsInBasket = items.filter(
    item => item.availability?.availabilityStatus === deliveryConstants.UNAVAILABLE,
  );

  const allItemsInBasketUnavailable = !!(
    !isEmptyObject(unavailableItemsInBasket) && unavailableItemsInBasket.length === items.length
  );

  return {
    allItemsInBasketUnavailable,
    unavailableItemsInBasket,
  };
}
