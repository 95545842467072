// Design System
import AddressWithSearchForm from 'jl-design-system/components/address-with-search-form/AddressWithSearchForm';
import Link from 'jl-design-system/elements/link/Link';
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';

// Types
import { MyJohnLewisDetailsProps } from './MyJohnLewisDetails.types';

// Config
import { MYJL_ADDRESS } from '@constants/actionConstants';
import routeConstants from '@constants/routeConstants';
import getLoqateMocks from '@non-prod-utils/utils/loqate/getLoqateMocks';
import { shouldMockLoqate } from '@utils/address/addressHelpers';

// Components
import Container from '@components/container';
import DisplayAddress from '@components/display-address/DisplayAddress';

// Styles
import styles from './my-john-lewis-details.scss';

const MyJohnLewisDetails = ({
  disablePostcodeSearch = false,
  formId = '',
  getFormConfig = () => {},
  loqateApiKey = '',
  onEnterManualAddress = () => {},
  onLoqateNetworkTimeoutCallback = () => {},
  onLoqateSearch = () => {},
  onMyJlAddressFormSubmit = () => {},
  onMyJlEditAddress = () => {},
  sessionId,
  showFullScreenSignup = false,
  simpleForm = false,
  title = '',
  triggerLoqateTimeoutEnterAddressManuallyAnalytics = () => {},
  userAddress = {},
}: MyJohnLewisDetailsProps) => {
  const { addressee, address, phoneNumber } = userAddress;

  const initialValues = getFormInitialValuesFromAddress(userAddress);

  const link = showFullScreenSignup
    ? routeConstants.JOIN_MY_JL_EDIT
    : routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL_EDIT;

  return (
    <Container className={styles.container} testId="my-john-lewis-details">
      <p>{title}</p>

      {simpleForm && (
        <Container flex={{ alignItems: 'end', gap: '2' }} marginBottom="3">
          <DisplayAddress
            address={address}
            addressee={addressee}
            displayAddressTitle="Membership details"
            inline
            phoneNumber={phoneNumber}
          />

          <Link className={styles.link} data-testid="myjl-edit-address" onClick={onMyJlEditAddress} to={link}>
            Edit address
          </Link>
        </Container>
      )}

      <AddressWithSearchForm
        addressId={MYJL_ADDRESS}
        combineExtraAddressLines
        destroyOnUnmount={false}
        disablePostcodeSearch={disablePostcodeSearch}
        formId={formId}
        getConfig={getFormConfig}
        getLoqateMocks={getLoqateMocks}
        initialValues={initialValues}
        jlpid={sessionId}
        loqateApiKey={loqateApiKey}
        maxAddressLines={3}
        multiFormValidation
        onAddressFormSubmit={onMyJlAddressFormSubmit}
        onEnterManualAddress={onEnterManualAddress}
        onLoqateNetworkTimeoutCallback={onLoqateNetworkTimeoutCallback}
        onLoqateSearch={onLoqateSearch}
        onLoqateTimeoutEnterAddressManuallyCallback={triggerLoqateTimeoutEnterAddressManuallyAnalytics}
        preventLoqateScroll
        replaceGbTerritoryCountryCodes={false}
        shouldMockLoqate={shouldMockLoqate()}
        useLoqateAddressSearch
        useV2Message
      />
    </Container>
  );
};

export default MyJohnLewisDetails;
