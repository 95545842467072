import { createSelector } from 'reselect';

// Config
import { BILLING_ADDRESS_FORM_ID } from '@utils/form/configs/billingAddress';

const getBillingAddressFormValues = state => state.form?.[BILLING_ADDRESS_FORM_ID]?.values;
const getBillingAddressInitialValues = state => state?.billingAddress?.initialValues;

const mergeBillingAddressSources = createSelector(
  [getBillingAddressFormValues, getBillingAddressInitialValues, (_, prioritiseFormValues) => prioritiseFormValues],
  (billingAddressFormValues, billingAddressInitialValues, prioritiseFormValues) => {
    if (prioritiseFormValues) {
      return { ...billingAddressInitialValues, ...billingAddressFormValues };
    }
    return { ...billingAddressFormValues, ...billingAddressInitialValues };
  },
);

export default mergeBillingAddressSources;
