import { lazy } from 'react';

// Types
import { PosCreditSessionExpiredErrorProps as LazyComponentProps } from './PosCreditSessionExpiredError.types';

// Components
import withSuspense, { lazyRetry } from '@components/suspense';

const LazyComponent = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "posCreditSessionExpiredError" */ './PosCreditSessionExpiredError')),
);

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'PosCreditSessionExpiredError');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as PosCreditSessionExpiredError };
