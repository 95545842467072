
// Types
import { TradeInDetailsProps } from './TradeInDetails.types';
import { ShowItemRemoveOverlayTypes } from '@redux/actions/edit-basket/editBasketActions.types';

// Components
import TradeInItem from './trade-in-item';
import Container from '@components/container';

const TradeInDetails = ({
  handleRemoveItem = () => {},
  hideItemRemoveOverlay = () => {},
  isModalView = false,
  isProcessing = false,
  showItemRemoveOverlay = ({}: ShowItemRemoveOverlayTypes) => {},
  showTradeInBoxInOrderConfirmation = false,
  tradeInItems = [],
}: TradeInDetailsProps) =>
  tradeInItems?.length > 0 && (
    <Container borderBottom="grey-15" marginX={isModalView ? '0' : '3'} testId="trade-in-items-container">
      {tradeInItems.map((item, ind) => (
        <TradeInItem
          key={`trade-in-item-${ind + 1}`}
          handleRemoveItem={handleRemoveItem}
          hideItemRemoveOverlay={hideItemRemoveOverlay}
          index={ind}
          isModalView={isModalView}
          isProcessing={isProcessing}
          itemId={item.id}
          itemName={item.deviceName}
          itemPaymentAmount={item.payment?.amount}
          itemPaymentType={item.payment?.type}
          showItemRemoveOverlay={showItemRemoveOverlay}
          showTradeInBoxInOrderConfirmation={showTradeInBoxInOrderConfirmation}
        />
      ))}
    </Container>
  );

export default TradeInDetails;
