import { differenceInMilliseconds } from 'date-fns';
//
import {
  SAVE_TIME_TO_DELIVERY_PAGE,
  SAVE_TIME_TO_DELIVERY_METHODS,
  SAVE_TIME_TO_COLLECTION_DATES,
  SAVE_TIME_TO_AUTH0TOKEN,
  DELIVERY_TIMINGS_RECORDED,
  SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED,
  PAYMENT_TIMINGS_RECORDED,
  CONFIRMATION_TIMINGS_RECORDED,
  SAVE_TIME_PLACE_ORDER_CLICKED,
} from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import { isClickAndCollectChoice, isDeliveryChoice } from '@utils/delivery/deliveryHelper';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';
import { isEmptyObject } from '@utils/object';

export const shouldRecordDeliveryTimings = () => (dispatch, getState) => {
  const {
    app: {
      timestamps,
      fromAuth0,
    } = {},
    user: {
      isSignedIn,
    } = {},
  } = getState();

  return Boolean(!!timestamps && isSignedIn && !fromAuth0);
};

export const getPrimaryPaymentType = (orderDetails = {}) => {
  const {
    payments = [],
  } = orderDetails;

  const primaryPaymentTypes = [
    paymentTypeConstants.SAVED_PAYMENT_CARD,
    paymentTypeConstants.TOKENIZED_PAYMENT_CARD,
    paymentTypeConstants.APPLE_PAY,
    paymentTypeConstants.GOOGLE_PAY,
    paymentTypeConstants.CLEARPAY,
    paymentTypeConstants.POS_CREDIT,
    paymentTypeConstants.PAYPAL,
  ];

  const primaryPayment = payments.find(payment => primaryPaymentTypes.includes(payment.type));

  // paid total or partial order with a primary payment type
  if (primaryPayment) return primaryPayment.type;

  // paid for order entirely with secondary payment types e.g. gift card or voucher
  return 'none';
};

export const recordConfirmationTimings = () => (dispatch, getState) => {
  const {
    app: {
      timestamps,
      confirmationTimingsRecorded,
    } = {},
    confirmation: {
      orderDetails,
    } = {},
  } = getState() || {};

  if (isEmptyObject(timestamps)) return;

  if (confirmationTimingsRecorded) return;

  const {
    timePlaceOrderClicked,
  } = timestamps;

  const actionName = 'checkout-timing';
  const attributes = {
    action: 'payment-to-order-confirmation-page',
    timeToOrderConfirmationPage: differenceInMilliseconds(Date.now(), timePlaceOrderClicked) / 1000,
    primaryPaymentType: getPrimaryPaymentType(orderDetails),
  };

  sendNewRelicCustomEvent(actionName, attributes);
  dispatch({
    type: CONFIRMATION_TIMINGS_RECORDED,
  });
};

export const recordPaymentTimings = () => (dispatch, getState) => {
  const {
    timestamps,
    paymentTimingsRecorded,
  } = getState().app || {};

  if (isEmptyObject(timestamps)) return;

  if (paymentTimingsRecorded) return;

  const {
    timeContinueToPaymentClicked,
  } = timestamps;

  const actionName = 'checkout-timing';
  const attributes = {
    action: 'delivery-to-payment-page',
    timeToPaymentPage: differenceInMilliseconds(Date.now(), timeContinueToPaymentClicked) / 1000,
  };

  sendNewRelicCustomEvent(actionName, attributes);
  dispatch({
    type: PAYMENT_TIMINGS_RECORDED,
  });
};

export const recordDeliveryTimings = ({
  action,
  extraAttributes = {},
}) => (dispatch, getState) => {
  const {
    timestamps,
    deliveryTimingsRecorded,
  } = getState().app || {};

  if (deliveryTimingsRecorded) return;

  if (!isEmptyObject(timestamps)) {
    const {
      timeToCheckoutSpinner,
      timeToReactLoaded,
      timeToDeliveryPage,
      timeToAuth0Complete,
    } = timestamps;

    const actionName = 'checkout-timing';
    const attributes = {
      action,
      timeToCheckoutSpinner: timeToCheckoutSpinner / 1000,
      timeToReactLoaded: timeToReactLoaded / 1000,
      timeToDeliveryPage: timeToDeliveryPage / 1000,
      ...timeToAuth0Complete && { timeToAuth0Complete: timeToAuth0Complete / 1000 },
      ...extraAttributes,
    };

    sendNewRelicCustomEvent(actionName, attributes);
    dispatch({
      type: DELIVERY_TIMINGS_RECORDED,
    });
  }
};

export const saveTimeToAuth0TokenTimestamp = () => ({
  type: SAVE_TIME_TO_AUTH0TOKEN,
  timeToAuth0Complete: Date.now(),
});

export const saveTimeToDeliveryPageTimestamp = () => ({
  type: SAVE_TIME_TO_DELIVERY_PAGE,
  timeToDeliveryPage: Date.now(),
});

export const saveTimeToDeliveryMethodsTimestamp = () => ({
  type: SAVE_TIME_TO_DELIVERY_METHODS,
  timeToDeliveryMethods: Date.now(),
});

export const saveTimeToCollectionDatesTimestamp = () => ({
  type: SAVE_TIME_TO_COLLECTION_DATES,
  timeToCollectionDates: Date.now(),
});

export const saveTimeContinueToPaymentClicked = () => ({
  type: SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED,
  timeContinueToPaymentClicked: Date.now(),
});

export const saveTimePlaceOrderClicked = () => ({
  type: SAVE_TIME_PLACE_ORDER_CLICKED,
  timePlaceOrderClicked: Date.now(),
});

export const recordCollectionPaymentPageTimings = () => (dispatch, getState) => {
  if (!dispatch(shouldRecordDeliveryTimings())) return;

  const {
    app: {
      timestamps: {
        timeToCollectionDates,
        timeContinueToCheckoutClicked,
      } = {},
    } = {},
  } = getState();

  dispatch(recordDeliveryTimings({
    action: 'basket-to-collection-to-payments',
    extraAttributes: {
      timeToCollectionDates: timeToCollectionDates / 1000,
      timeToPaymentsPage: (Date.now() - timeContinueToCheckoutClicked) / 1000,
    },
  }));
};

export const recordDeliveryPaymentPageTimings = () => (dispatch, getState) => {
  if (!dispatch(shouldRecordDeliveryTimings())) return;

  const {
    app: {
      timestamps: {
        timeToDeliveryMethods,
        timeContinueToCheckoutClicked,
      } = {},
    } = {},
  } = getState();

  dispatch(recordDeliveryTimings({
    action: 'basket-to-delivery-to-payments',
    extraAttributes: {
      timeToDeliveryMethods: timeToDeliveryMethods / 1000,
      timeToPaymentsPage: (Date.now() - timeContinueToCheckoutClicked) / 1000,
    },
  }));
};

export const recordCollectionDateTimings = () => (dispatch, getState) => {
  if (!dispatch(shouldRecordDeliveryTimings())) return;

  const {
    app: {
      timestamps: {
        timeContinueToCheckoutClicked,
      } = {},
    } = {},
  } = getState();

  dispatch(saveTimeToCollectionDatesTimestamp());

  dispatch(recordDeliveryTimings({
    action: 'basket-to-collection-dates',
    extraAttributes: {
      timeToCollectionDates: (Date.now() - timeContinueToCheckoutClicked) / 1000,
    },
  }));
};

export const recordDeliveryMethodTimings = () => (dispatch, getState) => {
  if (!dispatch(shouldRecordDeliveryTimings())) return;

  const {
    app: {
      timestamps: {
        timeContinueToCheckoutClicked,
      } = {},
    },
  } = getState();

  dispatch(saveTimeToDeliveryMethodsTimestamp());

  dispatch(recordDeliveryTimings({
    action: 'basket-to-delivery-methods',
    extraAttributes: {
      timeToDeliveryMethods: (Date.now() - timeContinueToCheckoutClicked) / 1000,
    },
  }));
};

export const recordInitDeliveryPageTimings = () => (dispatch, getState) => {
  if (!dispatch(shouldRecordDeliveryTimings())) return;

  const {
    delivery: {
      selectedDeliveryChoiceId,
    },
    user: {
      collectionPointsSaved,
      defaultAddress,
    },
  } = getState();

  dispatch(saveTimeToDeliveryPageTimestamp());

  const isCollection = isClickAndCollectChoice(selectedDeliveryChoiceId);
  const isDelivery = isDeliveryChoice(selectedDeliveryChoiceId);

  if (isDelivery && defaultAddress) {
    // timings will be recorded further along
    return;
  }

  if (isCollection && collectionPointsSaved) {
    // timings will be recorded further along
    return;
  }

  dispatch(recordDeliveryTimings({
    action: isCollection ? 'basket-to-collection' : 'basket-to-delivery',
  }));
};
