import { HTMLAttributes, useMemo } from 'react';
import cx from 'classnames';

// Types
import { HeadingProps } from './Heading.types';

// Config
import { getSpacingClass, headingThemes } from '@components/design-system/DesignSystem.constants';
import { getDataAttributes } from '@utils/helpers/dataAttributes';

const Heading = ({
  children,
  className,
  label,
  marginBottom = '0',
  maskContentSquare = false,
  maskPercy = false,
  maskText = false,
  tag: Tag = 'h1',
  testId,
  type = 'm',
  uppercase = false,
  ...otherProps
}: HeadingProps & HTMLAttributes<HTMLElement>) => {
  const themeClass = useMemo(() => headingThemes[type] || headingThemes.m, [type]);

  const marginBottomClass = useMemo(
    () => getSpacingClass({ type: 'margin', position: 'bottom', size: marginBottom, backupSize: '0' }),
    [marginBottom],
  );

  const headingClass = useMemo(
    () =>
      cx(themeClass, marginBottomClass, className, {
        uppercase,
      }),
    [className, marginBottomClass, themeClass, uppercase],
  );

  const memoTag = useMemo(
    () =>
      (label || children) && (
        <Tag
          className={headingClass}
          {...getDataAttributes({ maskContentSquare, maskPercy, maskText, testId })}
          {...otherProps}
        >
          {label || children}
        </Tag>
      ),
    [Tag, headingClass, label, children, maskContentSquare, maskPercy, maskText, testId, otherProps],
  );

  return memoTag;
};

export default Heading;
