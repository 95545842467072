import getAddressFieldsConfig from 'jl-design-system/form/configs/address';

import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
  SEARCH_FOR_ANOTHER_ADDRESS_BUTTON,
  POSTCODE,
} from 'jl-design-system/form/fields';
import { SecondaryButton } from 'jl-design-system/elements/button/Button';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export const DELIVERY_ADDRESS_FORM_ID = 'deliveryAddressForm';

export function getDeliveryAddressFormConfig(params) {
  const {
    addressLines,
    maxAddressLines,
    actions = {},
    configExtras = {},
    postcodeSearchFormVisible,
    editMode,
    enableGBCounty,
  } = params;

  const {
    searchForAnotherAddress,
  } = actions;

  const {
    changeAddressButton,
    autoFocusOnFirstField,
  } = configExtras;

  const searchForAnotherAddressButton = {
    ...SEARCH_FOR_ANOTHER_ADDRESS_BUTTON,
    component: SecondaryButton,
    props: {
      ...SEARCH_FOR_ANOTHER_ADDRESS_BUTTON.props,
      children: 'Search for another address',
      'data-testid': 'search-for-address-button',
      onClick: searchForAnotherAddress,
      size: 'small',
    },
  };

  const renderAddressFields = !postcodeSearchFormVisible || editMode;

  const ADDRESS_FIELDS = renderAddressFields ? getAddressFieldsConfig({
    // when we get address fields we want GB fields even if country code is channel island e.g. GG, IM, JE
    countryCode: 'GB',
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
    postcodeReadOnly: false,
    renderAddressFields,
    allowAsciiOnly: true,
    enableGBCounty,
  }) : [];

  if (!editMode && ADDRESS_FIELDS.length > 1) {
    const index = ADDRESS_FIELDS.findIndex(field => field?.id === POSTCODE?.id);
    if (index >= 0) {
      ADDRESS_FIELDS[index].fieldExtra = searchForAnotherAddressButton;
    }
  }

  const PHONE_NUMBER = getInternationalPhoneNumberConfig({
    instruction: 'We’ll send delivery updates by text to UK mobile numbers and call if we have any queries',
  });

  // TODO this doesn't work
  if (autoFocusOnFirstField) {
    FIRST_NAME_DATA_STANDARDS.autoFocus = true;
  }

  return {
    id: DELIVERY_ADDRESS_FORM_ID,
    submitButton: 'Use this address',
    ...(changeAddressButton ? { submitButtonExtra: changeAddressButton } : {}),
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
    ],
  };
}
