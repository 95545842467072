import { getDay } from 'date-fns';

// Design system
import formatDayPickerDays from 'jl-design-system/utils/dayPicker/dayPicker';

// Config
import daysBetweenDateAndStartOfWeek from '@utils/helpers/date/daysBetweenDateAndStartOfWeek';
import {
  COLLECT_PLUS,
  COLLECT_PLUS_OWNER_ID,
  ROYAL_MAIL_OWNER_ID,
  CO_OP_MIDCOUNTIES_OWNER_ID,
  EVRI_OWNER_ID,
} from '@constants/clickCollectConstants';

export function getUnavailableStores(stores = []) {
  return stores.slice(0, 5).map((item, index) => ({ ...item, index: index + 1 }))
    .filter(store => (store.unavailabilityReason ?? []).length > 0);
}

export function getUnavailableStoresAnalyticsInfo(item) {
  return item.storeName
    .concat(':', item.unavailabilityReason.replace('due to ', ''), ':', item.index)
    .toLocaleLowerCase();
}

export function buildAvailabilityByDayString(dates) {

  const formattedDates = formatDayPickerDays(dates, 7, true);

  const range = [...(formattedDates[0] ?? []), ...(formattedDates[1] ?? [])];

  const weekDays = ['mon:', 'tue:', 'wed:', 'thu:', 'fri:', 'sat:', 'sun:'];

  // Determine if the date range starts on an available or unavailable date and
  // align date information with weekDays accordingly
  const firstActiveDayIndex = range.findIndex(item => item.active);
  const firstActiveDayOfWeek = getDay(range[firstActiveDayIndex]?.date);
  let targetWeekDay = firstActiveDayIndex > 0 ? 0 : firstActiveDayOfWeek - 1;

  range.forEach((date) => {
    const endOfWeekReached = targetWeekDay >= 7;
    if (endOfWeekReached) {
      targetWeekDay -= 7;
    }

    const code = date.active ? 'a' : 'u';
    weekDays[targetWeekDay] = `${weekDays[targetWeekDay]}${code}`;
    targetWeekDay += 1;
  });

  return weekDays.join('_');
}

export function selectClickAndCollectStoreAnalytics(
  store,
  searchTerm,
  collectionDates = [],
  selectedIndex,
) {
  let collectionDateAnalytics = {};

  if (collectionDates.length > 0) {
    const dates = collectionDates.filter(date => date.isAvailable).map(
      filteredDate => filteredDate.date,
    );

    const firstDeliverySlot = daysBetweenDateAndStartOfWeek(dates[0]);

    collectionDateAnalytics = {
      ...collectionDateAnalytics,
      dateRange: '1',
      availabilityByDay: buildAvailabilityByDayString(dates, 7),
      firstDeliverySlot,
      clickandCollectType: 'named day',
      slotSelected: '',
    };
  }

  const deliveryPropositionValues = {
    [COLLECT_PLUS]: {
      method: 'col:cp',
      message: 'collect+',
    },
    default: {
      method: 'col:cc',
      message: 'click & collect',
    },
  };

  const getValues = (owner, valueType) => {
    let ownerType = {};
    if (owner === COLLECT_PLUS) {
      ownerType = deliveryPropositionValues[COLLECT_PLUS];
    } else ownerType = deliveryPropositionValues.default;

    return ownerType[valueType];
  };

  const getDeliveryPropositionValues = (ownerId, type) => {
    switch (ownerId) {
      case COLLECT_PLUS_OWNER_ID:
      case ROYAL_MAIL_OWNER_ID:
      case EVRI_OWNER_ID:
        return getValues(COLLECT_PLUS, type);
      case CO_OP_MIDCOUNTIES_OWNER_ID:
      default:
        return getValues('default', type);
    }
  };

  const deliveryProposition = [{
    selected: 1,
    message: 'Click and Collect',
    deliveryId: store.id,
    method: getDeliveryPropositionValues(store.ownerId, 'method'),
    message: getDeliveryPropositionValues(store.ownerId, 'message'),
    searchTerm,
    ...collectionDateAnalytics,
    ...(selectedIndex && { shopSelected: selectedIndex }),
  }];

  return {
    checkout: {
      batch: [
        {
          deliveryProposition,
        },
      ],
    },
  };
}
