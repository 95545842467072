// Design System
import env from 'jl-design-system/utils/env/env';

import { appendScript, browserInfo } from '../../../utils';
import {
  setSelectedPaymentType,
  setPaymentProcessing,
  triggerPlaceOrderAndPayAnalytics,
  triggerTransactionStatusAnalytics,
  pendingChallege,
  pendingBrowserCheck,
} from './paymentActions';
import { submitOrderAndRedirect } from './submitOrderActions';
import getCardDetailsPayloadFromFormValues from '../../../utils/helpers/cardDetails';
import analyticsConstants from '../../../constants/analyticsConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import paymentStatusConstants from '../../../constants/paymentStatusConstants';
import supportedCreditCardTypes from '../../../constants/supportedCreditCardTypes';
import featureConstants from '../../../constants/featureConstants';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';
import getCorrelationId from '../../../utils/helpers/correlationId';
import isAddressValid from '../../../utils/addressBook/isAddressValid';
import { isFeatureActive } from '../../reducers/config/configReducer';
import { androidAppSaveSessionStorage } from '../../../utils/app/androidAppSaveSessionStorage';
import initKount from './kount';

import {
  ANALYTICS_SAVE_NEW_CARD_CHECKED,
  ANALYTICS_SAVE_NEW_CARD_UNCHECKED,
  BIN_NUMBER_UPDATE,
  CARD_PAYMENT,
  OCP_GET_3DS_INFO,
  OCP_GET_PAYMENT_ID,
  OCP_GET_SUBMISSION_SCRIPT,
  OCP_SUBMIT_3DS_METHOD,
  OCP_SUBMIT_3DS_METHOD_ERROR,
  SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS,
  SET_CARD_PAYMENT_3DSECURE_INVALID_STATUS,
  SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS,
  SET_OCP_3DS_FAIL_ERROR,
  SET_STRIPE_3DS_FAIL_ERROR,
  UPDATE_BILLING_ADDRESS,
} from '../../../constants/actionConstants';
import {
  URL_CARD_PAYMENT_BILLING_ADDRESS,
  URL_OCP_GET_PAYMENT_ID,
  URL_OCP_GET_SUBMISSION_SCRIPT_V3,
  URL_OCP_SUBMIT_3DS_METHOD,
  URL_OCP_GET_3DS_INFO,
  URL_OCP_PAYMENT_WITH_SAVED_CARD,
  URL_OCP_GET_PAYMENT_ID_CLEARPAY,
  URL_OCP_GET_PAYMENT_ID_KLARNA,
} from '../../../constants/endpointConstants';
import { initPaymentPage } from './orderFormActions';
import triggerAnalyticsEvent from '../analytics/analyticsAction';

export const updateBillingAddress = ({
  body,
  nonce = null,
  shouldRequestBillingAddressFromApplePay = false,
  isApplePaySelected = false,
  isGooglePay = false,
}) => {
  const billingAddressArgs = {};

  if (nonce) {
    billingAddressArgs.billingAddressFromPaypal = body;
    billingAddressArgs.paypalNonce = nonce;
  }

  if (shouldRequestBillingAddressFromApplePay) {
    billingAddressArgs.applePayBillingAddress = body;
  }

  if (isGooglePay) {
    billingAddressArgs.googlePayBillingAddress = body;
  }

  return {
    type: UPDATE_BILLING_ADDRESS,
    request: client => client({ path: URL_CARD_PAYMENT_BILLING_ADDRESS, config: { method: 'PUT', body } }),
    ...(isApplePaySelected && { billingAddress: body }),
    ...billingAddressArgs,
  };
};

export const ocpGetPaymentId = (params) => {
  const {
    body,
    savedCardPayment,
    selectedPaymentType,
  } = params;

  let endpoint;

  if (selectedPaymentType === paymentTypeConstants.CLEARPAY) {
    endpoint = URL_OCP_GET_PAYMENT_ID_CLEARPAY;
  } else if (selectedPaymentType === paymentTypeConstants.KLARNA) {
    endpoint = URL_OCP_GET_PAYMENT_ID_KLARNA;
  } else {
    endpoint = savedCardPayment ? URL_OCP_PAYMENT_WITH_SAVED_CARD : URL_OCP_GET_PAYMENT_ID;
  }

  return {
    type: OCP_GET_PAYMENT_ID,
    request: client => client({ path: endpoint, config: { method: 'POST', body } }),
  };
};

export const ocpGetSubmissionScriptV3 = (paymentId, hostUrl, headers) => ({
  type: OCP_GET_SUBMISSION_SCRIPT,
  request: client => client({
    path: URL_OCP_GET_SUBMISSION_SCRIPT_V3(paymentId),
    config: {
      method: 'GET',
      headers: {
        ...headers,
        Accept: 'application/vnd.jl-payments-v3+json',
      },
    },
    hostUrl,
  }),
});

export const ocpSubmitCardDetails = (silentPost, cardDetails, postLocation, publicKey,
  additionalPayload, orderFormId, omsOrderId, correlationId, sessionId, dispatch) => (
  new Promise((resolve) => {

    const onError = (error, responseObject) => {
      let json;
      try {
        json = JSON.parse(error);
        // IE 11 does not support responseType = json, so we need to parse again
        if (typeof json === 'string') {
          json = JSON.parse(json);
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}

      const statusCode = responseObject?.status ?? '';
      sendNewRelicCustomEvent('ocpSubmissionScriptResponse', {
        ...json,
        correlationId,
        orderFormId,
        postLocation,
        sessionId,
        statusCode,
        error,
      });

      const code = json?.code ?? '';
      const authenticationStatus = json?.authenticationStatus ?? '';
      dispatch(triggerTransactionStatusAnalytics('FAILED', { errorCode: code, authenticationStatus }));

      resolve({ error: true });
    };
    const onSuccess = (response, responseObject) => {
      let json;
      try {
        json = JSON.parse(response);
        // IE 11 does not support responseType = json, so we need to parse again
        if (typeof json === 'string') {
          json = JSON.parse(json);
        }
      } catch (e) {
        onError(response, responseObject);
        return;
      }

      sendNewRelicCustomEvent('ocpSubmissionScriptResponse', {
        ...json,
        statusCode: 200,
        correlationId,
        orderFormId,
        postLocation,
        sessionId,
      });

      const transactionStatus = json?.status ?? '';
      const encryptedBinNumber = json?.encryptedBinNumber ?? '';
      const authenticationStatus = json?.authenticationStatus ?? '';

      dispatch(triggerTransactionStatusAnalytics(transactionStatus, { authenticationStatus }));
      dispatch(triggerAnalyticsEvent(BIN_NUMBER_UPDATE, { encryptedBinNumber }));

      resolve(json);
    };

    const headers = {
      'JL-BASKET-ID': orderFormId,
      'JL-OMS-ORDER-ID': omsOrderId,
      JSESSIONID: sessionId,
    };

    silentPost(cardDetails, additionalPayload, onSuccess, onError, publicKey, postLocation, headers);
  })
);

export const ocpGet3dsInfo = () => ({
  type: OCP_GET_3DS_INFO,
  request: client => client({ path: URL_OCP_GET_3DS_INFO, config: { method: 'GET' } }),
});

export const ocpSubmit3DSMethod = (paymentId, body, hostUrl, sessionId, orderFormId, omsOrderId) => ({
  type: OCP_SUBMIT_3DS_METHOD,
  request: client => client({
    path: URL_OCP_SUBMIT_3DS_METHOD(paymentId),
    config: {
      method: 'PUT',
      headers: {
        JSESSIONID: sessionId,
        'JL-BASKET-ID': orderFormId,
        'JL-OMS-ORDER-ID': omsOrderId,
        'JL-CALLING-APP-V1': 'OCC',
        Accept: 'application/vnd.jl-payments-v3+json',
        'Content-Type': 'application/vnd.jl-payments-v3+json',
        'Accept-Language': '*',
      },
      body,
    },
    hostUrl,
  }),
});

export const complete3DSMethod = success => async (dispatch, getState) => {
  const state = getState();

  const paymentId = state.payment?.ocpPaymentId;
  const host = state.orderForm?.ocpIntegration?.baseUrl;
  const sessionId = state.bff?.sessionId ?? '';
  const orderFormId = state.orderForm?.id ?? '';
  const omsOrderId = state.orderForm?.omsOrderId ?? '';
  const body = { browserCheckStatus: success ? 'COMPLETED' : 'NOT_COMPLETED' };

  if (!success) {
    sendNewRelicCustomEvent('browserCheckNotCompleted', {
      orderFormId,
      paymentId,
      sessionId,
    });
  }

  const {
    type,
    error: { code, authenticationStatus: authenticationStatusOnError } = {},
    result: { status, authenticationStatus: authenticationStatusOnSuccess } = {},
  } = await dispatch(ocpSubmit3DSMethod(paymentId, body, host, sessionId, orderFormId, omsOrderId));
  dispatch(setPaymentProcessing(false));

  if (type === `${OCP_SUBMIT_3DS_METHOD}.SUCCESS`) {
    dispatch(triggerTransactionStatusAnalytics(status, { authenticationStatus: authenticationStatusOnSuccess }));
    if (status === paymentStatusConstants.PENDING_AUTHORISATION) {
      dispatch(submitOrderAndRedirect());
    }

    // Persist session storage in android app to handle customer leaving app and returning during 3DS challenge
    androidAppSaveSessionStorage(state);
  }

  if (type === `${OCP_SUBMIT_3DS_METHOD}.FAILED`) {
    await dispatch(initPaymentPage());
    dispatch(triggerTransactionStatusAnalytics('FAILED', {
      errorCode: code,
      authenticationStatus: authenticationStatusOnError,
    }));
    dispatch({
      type: OCP_SUBMIT_3DS_METHOD_ERROR,
      error: { code: errorCodeConstants.CLIENT_OCP_PAYMENT_ERROR },
    });
  }
};

export const handleAuthentication = ({
  id,
  submitCardDetailsAction,
} = {}) => async (dispatch) => {
  const { authentication, status, browserCheck, challengeCheck } = submitCardDetailsAction;

  if (status === paymentStatusConstants.PENDING_BROWSER_CHECK) {
    const { actionUrl, formData } = browserCheck;
    return dispatch(pendingBrowserCheck(id, actionUrl, formData));
  }

  if (status === paymentStatusConstants.PENDING_CHALLENGE) {
    const { actionUrl, formData } = challengeCheck;
    return dispatch(pendingChallege(actionUrl, formData));
  }

  if (authentication) {
    const { paReq, acsUrl } = authentication;
    const get3dsInfoAction = await dispatch(ocpGet3dsInfo());
    const { type, result: { termUrl } = {} } = get3dsInfoAction;

    if (type === `${OCP_GET_3DS_INFO}.SUCCESS` && termUrl) {

      return dispatch({
        type: `${CARD_PAYMENT}.SUCCESS`,
        result: {
          ...submitCardDetailsAction,
          creditCard3DSecureInfo: {
            is3DSecureAvailable: true,
            creditCard3DSecureFormId: '3DSForm',
            creditCard3DSecureMerchantUrl: acsUrl,
            creditCard3DSecureFormData: {
              MD: '',
              TermUrl: termUrl,
              PaReq: paReq,
            },
          },
        },
      });
    }
  } else {
    return dispatch({
      type: `${CARD_PAYMENT}.SUCCESS`,
      result: {
        ...submitCardDetailsAction,
        creditCard3DSecureInfo: {
          is3DSecureAvailable: false,
        },
      },
    });
  }

  return undefined;
};

export const submitCardPaymentOCP = (
  cardDetails = {},
  baseUrl = '',
  savedCardPayment = false,
  cardToBeStored = false,
  billingAddress,
  cardDetailsPayload,
) => async (dispatch, getState) => {
  const state = getState();

  const sessionId = state.bff?.sessionId ?? '';
  const orderFormId = state.orderForm?.id ?? '';
  const omsOrderId = state.orderForm?.omsOrderId ?? '';

  dispatch({ type: `${CARD_PAYMENT}.LOADING` });

  const { number: cardNumber = '', cardholderName = '', expiryDate = '', securityCode = '', cardType = '' } = cardDetails;

  const ocpGetPaymentIdBody = savedCardPayment ? {
    addressId: billingAddress?.id,
    cardId: cardDetails.cardId,
  } : {
    addressId: billingAddress?.id,
    cardType,
    cardholderName,
  };

  let getPaymentIdAction;

  const ocpGetPaymentIdParams = {
    body: ocpGetPaymentIdBody,
    savedCardPayment,
    selectedPaymentType: state.payment?.selectedPaymentType,
  };

  if (!isFeatureActive(getState(), featureConstants.DISABLE_KOUNT)) {
    getPaymentIdAction = await dispatch(initKount({
      cardDetailsPayload,
      cardToBeStored,
      grandTotalRaw: getState().orderForm?.amounts?.grandTotalRaw,
      savedCardPayment,
      callbackFunction: ocpGetPaymentId,
      callbackParams: ocpGetPaymentIdParams,
    }));
  } else {
    getPaymentIdAction = await dispatch(ocpGetPaymentId(ocpGetPaymentIdParams));
  }

  const {
    type,
    result: {
      id,
      authenticationMethodNotificationUrl,
      challengeNotificationUrl,
    } = {},
  } = getPaymentIdAction;

  if (type === `${OCP_GET_PAYMENT_ID}.SUCCESS`) {
    const correlationId = getCorrelationId();

    await dispatch(triggerPlaceOrderAndPayAnalytics(id));

    const getSubmissionScriptHeaders = {
      JSESSIONID: sessionId,
      'JL-BASKET-ID': orderFormId,
      'JL-OMS-ORDER-ID': omsOrderId,
      'JL-UI-CORRELATION-ID-V1': correlationId,
      'JL-CALLING-APP-V1': 'OCC',
      'Accept-Language': '*',
    };

    const getSubmissionScriptAction = await dispatch(ocpGetSubmissionScriptV3(id, baseUrl, getSubmissionScriptHeaders));

    const { type, result = {} } = getSubmissionScriptAction;

    if (type === `${OCP_GET_SUBMISSION_SCRIPT}.SUCCESS`) {

      const { script = '' } = result;
      if (env.isClient) {
        appendScript(script);

        const silentPost = window?.JLEncrypt?.silentPost_3DSV2;

        if (silentPost) {
          const { targetUrl: postLocation, publicKey, nonce } = result;

          let payload;
          if (savedCardPayment) {
            payload = {
              cardExpiryDate: cardDetails.expiryDate,
              csc: cardDetails.securityCode,
              nameOnCard: cardDetails.cardholderName,
            };
          } else {
            // format MM-YY to 20YY-MM
            const parsedDate = `20${expiryDate.substr(2)}-${expiryDate.substr(0, 2)}`;
            payload = {
              cardNumber,
              cardExpiryDate: parsedDate,
              csc: securityCode,
              nameOnCard: cardholderName,
            };
          }
          if (nonce) {
            payload.nonce = nonce;
          }

          const additionalPayload = {
            browserInfo: browserInfo(),
            authenticationMethodNotificationUrl,
            challengeNotificationUrl,
            toBeStored: cardToBeStored,
          };

          const submitCardDetailsAction =
            await ocpSubmitCardDetails(silentPost, payload, postLocation, publicKey, additionalPayload, orderFormId,
              omsOrderId, correlationId, sessionId, dispatch);

          const { error } = submitCardDetailsAction;

          if (!error) {
            return dispatch(handleAuthentication({ id, submitCardDetailsAction }));
          }
        }
      }
    }
  }

  if (type === `${OCP_GET_PAYMENT_ID}.FAILED`) {
    const { error: { code } } = getPaymentIdAction;

    if (code === errorCodeConstants.CARD_PAYMENT_ALREADY_EXISTS) {
      dispatch(initPaymentPage());
      dispatch(setSelectedPaymentType({ paymentType: paymentTypeConstants.PAYPAL }));

      return dispatch({
        type: `${CARD_PAYMENT}.FAILED`,
        error: { code: errorCodeConstants.CLIENT_CARD_PAYMENT_ALREADY_EXISTS_OCP },
      });
    }
  }

  dispatch(initPaymentPage());

  return dispatch({
    type: `${CARD_PAYMENT}.FAILED`,
    error: { code: errorCodeConstants.CLIENT_OCP_PAYMENT_ERROR },
  });

};

export const submitCardPayment = params => async (dispatch) => {
  const {
    cardDetailsPayload,
    billingAddressPayload,
    savedCardPayment,
    cardToBeStored,
    ocpBaseUrl,
  } = params;

  // submit card payment
  const { creditCardDetails } = cardDetailsPayload;
  const payload = savedCardPayment ? cardDetailsPayload : creditCardDetails;
  const {
    type,
    result,
  } = await dispatch(submitCardPaymentOCP(
    payload,
    ocpBaseUrl,
    savedCardPayment,
    cardToBeStored,
    billingAddressPayload,
    cardDetailsPayload,
  ));
  const threeDSMethodRequired = result?.threeDSMethodInfo?.threeDSMethodRequired ?? false;
  const is3DSecureAvailable = result?.creditCard3DSecureInfo?.is3DSecureAvailable ?? false;

  if (type === `${CARD_PAYMENT}.SUCCESS` && !threeDSMethodRequired && !is3DSecureAvailable) {
    dispatch(submitOrderAndRedirect());
  }
};

export const submitAndHandleCardPayment = ({
  cardDetails,
  billingAddress,
  ocpBaseUrl,
  savedCardPayment = false,
  cardToBeStored,
}) => async (dispatch, getState) => {
  if (billingAddress) {
    const sessionId = getState().bff?.sessionId;

    if (!billingAddress.address?.addressLine1) {
      // Handles scenario where customer has left text in loqate search field but not selected address
      // https://www.jlpit.com/jira/browse/MARV-9522
      sendNewRelicCustomEvent('missing billing address', {
        sessionId,
      });

      return;
    }

    if (!isAddressValid(billingAddress)) {
      // Billing address is invalid for as yet unknown reasons
      // Log to newrelic so we can investigate later, and allow customer to continue
      sendNewRelicCustomEvent('invalid billing address', {
        sessionId,
      });
    }
  }

  let billingAddressPayload = billingAddress;

  if (billingAddress) {
    const response = await dispatch(updateBillingAddress({ body: billingAddress }));
    if (response.type === `${UPDATE_BILLING_ADDRESS}.FAILED`) {
      return;
    }

    if (response.result?.id) {
      // the backend saved a new address to the customer profile and returned the new ID to us
      billingAddressPayload = {
        ...billingAddress,
        id: response.result.id,
      };
    }
  }

  let cardDetailsPayload;

  if (savedCardPayment) {
    cardDetailsPayload = cardDetails;
  } else {
    cardDetailsPayload = getCardDetailsPayloadFromFormValues({
      cardType: getState().cardType?.name,
      formValues: cardDetails,
    });
  }

  const submitCardPaymentParams = {
    ocpBaseUrl,
    billingAddressPayload,
    savedCardPayment,
    cardToBeStored,
    cardDetailsPayload,
  };

  await dispatch(submitCardPayment(submitCardPaymentParams));
};

export const setCardPayment3DSecureCancelledStatus = () => async (dispatch, getState) => {
  const threeDSFailureCount = (getState().app?.threeDSFailureCount ?? 0) + 1;
  const cardType = getState().cardType?.name;
  const selectedPaymentType = getState().payment?.selectedPaymentType;

  const cardPaymentType = selectedPaymentType === paymentTypeConstants.CREDIT_CARD ?
    analyticsConstants.CC_NEW
    : analyticsConstants.CC_SAVED;
  const newdayCardType = cardType === supportedCreditCardTypes.NEW_DAY ? cardPaymentType : undefined;

  dispatch({
    type: SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS,
    threeDSFailureCount,
    cardType,
    newdayCardType,
  });
};

export const setCardPaymentPrepaidCancelledStatus = () => ({
  type: SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS,
});

export const setCardPayment3DSecureInvalidStatus = value => dispatch => dispatch({
  type: SET_CARD_PAYMENT_3DSECURE_INVALID_STATUS,
  value,
});

export const setOcp3dsFailError = () => ({
  type: SET_OCP_3DS_FAIL_ERROR,
});

export const setStripe3dsFailError = () => ({
  type: SET_STRIPE_3DS_FAIL_ERROR,
});

export const setStripePaymentFailError = () => ({
  type: `${CARD_PAYMENT}.FAILED`,
  error: { code: errorCodeConstants.CLIENT_STRIPE_PAYMENT_ERROR },
});

export const triggerUseSavedCardAnalytics = checked => async (dispatch) => {
  dispatch({
    type: checked ? ANALYTICS_SAVE_NEW_CARD_CHECKED : ANALYTICS_SAVE_NEW_CARD_UNCHECKED,
  });
};
