// Config
import queryStrings from '@constants/queryStringConstants';
import { isCustomerApp, isWeb } from '@redux/reducers/app/appReducer';
import { sendNewRelicCustomEvent } from '@utils/logging/logging-utils';

const onAuth0ConfigError = (state) => {
  const {
    bff: { sessionId },
  } = state;
  const timestamp = Date.now();
  const { pathname } = window.location;
  const { userAgent } = window.navigator;

  sendNewRelicCustomEvent('auth0ConfigError', {
    timestamp,
    path: pathname,
    userAgent,
    sessionId,
    errorDescription: 'Auth0 config missing in state using auth0 config from window.env',
  });
};

export function getOrderClaimPasswordLessAuth0Config(state) {
  const config = window.env.AUTH0_CONFIG;
  const initialScreen = isCustomerApp(state)
    ? config.passswordLessClaimOrderCustomerAppInitialScreen
    : config.passwordLessClaimOrderInitialScreen;

  return {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      audience: config.audience,
      redirect_uri: config.callbackClaimOrderUrl,
      scope: config.scope,
      prompt: config.prompt,
      cancelUrl: config.cancelClaimOrderUrl,
      initialScreen,
    },
  };
}

export function getRegisterAccountAuth0Config(state) {
  const config = window.env.AUTH0_CONFIG;
  const initialScreen = isCustomerApp(state)
    ? config.createAccountCustomerAppInitialScreen
    : config.createAccountInitialScreen;

  return {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      audience: config.audience,
      redirect_uri: `${config.callbackCreateAccountUrl}?${queryStrings.FULL_SCREEN_SIGNUP_FLOW}`,
      scope: config.scope,
      prompt: isCustomerApp(state) ? config.prompt : null,
      newSignUpFlow: true,
      cancelUrl: `${config.cancelCreateAccountUrl}?${queryStrings.CREATE_ACCOUNT_CANCELLED}`,
      email: state?.user?.email,
      initialScreen,
    },
  };
}

export default function getAuth0Config(state) {
  const stateConfig = state.config?.auth0ClientConfiguration;

  if (stateConfig && typeof stateConfig === 'object') {
    return stateConfig;
  }

  return (() => {
    onAuth0ConfigError(state);
    const configFallback = window.env.AUTH0_CONFIG;

    return {
      domain: configFallback.domain,
      clientId: configFallback.clientId,
      authorizationParams: {
        audience: configFallback.audience,
        redirect_uri: configFallback.callbackAuthenticatedUrl,
        scope: configFallback.scope,
        prompt: configFallback.prompt,
        initialScreen: configFallback.checkoutInitialScreen,
        auth0_password_reset: true,
        guest_checkout_url: configFallback.callbackGuestUrl,
        passwordless_checkout: isWeb(state),
      },
    };
  })();
}
