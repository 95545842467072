export default {
  GUEST: 'SIGNED_IN_ANONYMOUSLY',
  AUTHENTICATED: 'SIGNED_IN_WITH_PASSWORD',
  MY_JL_JOINED: 'JOINED',
  MY_JL_NOT_JOINED: 'NOT_JOINED',
  MY_JL_UNKNOWN: 'UNKNOWN',
  MY_JL_ACCEPTED: 'ACCEPTED_TO_JOIN',
  LOYALTY_PARTNERSHIP: 'MY_PARTNERSHIP',
  LOYALTY_MY_JL: 'MY_JL',
  LOYALTY_NOT_A_MEMBER: 'NOT_A_MEMBER',
  FULFILMENT_PREFERENCE_DELIVERY: 'DELIVERY',
  FULFILMENT_PREFERENCE_COLLECTION: 'COLLECTION',
  FULFILMENT_PREFERENCE_UNKNOWN: 'UNKNOWN',
  PAYMENT_PREFERENCE_PAYPAL: 'PAYPAL',
  PAYMENT_PREFERENCE_APPLE_PAY: 'APPLE_PAY',
  PAYMENT_PREFERENCE_GOOGLE_PAY: 'GOOGLE_PAY',
  PAYMENT_PREFERENCE_SAVED_CARD: 'SAVED_CARD',
  PAYMENT_PREFERENCE_UNKNOWN: 'UNKNOWN',
  PAYMENT_PREFERENCE_KLARNA: 'KLARNA',
  PAYMENT_PREFERENCE_CLEARPAY: 'CLEARPAY',
};
