import { useSelector } from 'react-redux';

// Design System
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import Link from 'jl-design-system/elements/link/Link';
import reload from 'jl-design-system/utils/reload/reload';

// Config
import { useHandbackToUrl } from '@hooks/useHandbackToUrl';
import { getBasketUrlSelector } from '@redux/reducers/bff/bffSelector';

// Components
import BodyText from '@components/body-text';
import Container from '@components/container';
import Heading from '@components/heading';

const GenericError = () => {
  const basketUrl = useSelector(getBasketUrlSelector);
  const handbackProps = useHandbackToUrl(basketUrl);

  return (
    <Container maxWidth="sm" paddingX="2" textAlign="center">
      <Heading label="Sorry, something went wrong" marginBottom="2" testId="generic-error-title" type="s" />

      <BodyText marginBottom="3" testId="generic-error-text">
        We are currently experiencing technical problems and your order hasn&apos;t been placed yet. Please reload
        checkout and try again, or return to your basket if the problem persists.
      </BodyText>

      <Container>
        <PrimaryButton inline onClick={reload}>
          Reload checkout
        </PrimaryButton>
      </Container>

      <Link external {...handbackProps}>
        Go to your basket
      </Link>
    </Container>
  );
};

export default GenericError;
