//
import { isZeroAmount } from '../../../utils';
import { REMOVE_GIFT_OPTION, GIFT_OPTIONS_CHANGE_OPTION } from '../../../constants/actionConstants';
import {
  GIFT_VOUCHER_SECURITY_CODE,
  GIFT_VOUCHER_SERIAL_NUMBER,
  GIFT_CARD_NUMBER,
  GIFT_CARD_PIN,
} from '../../../utils/form/configs/giftFields';
import {
  APPLY_GIFT_CARD_FORM_ID,
  APPLY_GIFT_VOUCHER_FORM_ID,
} from '../../../utils/form/configs/giftOptions';

import { getNonGlobalError } from '../../../utils/error/parseError';
import { isEmptyObject } from '@utils/object';

export default ({
  INITIAL_STATE,
  ACTION_NAME,
}) => (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case `${ACTION_NAME}.LOADING`:
      return {
        ...state,
        applyGiftPaymentFailed: false,
        formSubmitFailed: false,
        isLoading: true,
      };
    case `${ACTION_NAME}.SUCCESS`:
      return {
        ...state,
        applyGiftPaymentFailed: false,
        errorMessage: undefined,
        formSubmitFailed: false,
        isLoading: false,
      };
    case `${ACTION_NAME}.FAILED`: {
      return {
        ...state,
        applyGiftPaymentFailed: true,
        errorMessage: getNonGlobalError(
          action,
          state.errorMessage,
          { giftOptionType: ACTION_NAME.includes('GIFT_CARD') ? 'gift card' : 'gift voucher' },
          true,
        ),
        formSubmitFailed: true,
        isLoading: false,
      };
    }
    case `${REMOVE_GIFT_OPTION}.SUCCESS`: {
      return {
        ...state,
        errorMessage: undefined,
      };
    }
    case GIFT_OPTIONS_CHANGE_OPTION:
      return {
        ...state,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export const getGiftOptionWithRemainingBalance = (
  { orderForm: { payments = [] } = {} },
  paymentType,
) => payments.reduce((acc, payment = {}) => {
  if (!payment.isRemoved && payment.type === paymentType) {
    const remainingBalance = payment?.details?.remainingBalance;
    if (!isZeroAmount(remainingBalance) && !isZeroAmount(payment.amount)) {
      return payment.details;
    }
  }
  return acc;
}, {});

export const validateGiftOptionsInputDataLength = (values = {}) => {
  const {
    giftVoucherSecurityCode = '',
    giftVoucherSerialNumber = '',
    giftCardNumber = '',
    giftCardPin = '',
  } = values;

  const isValidGiftVoucher = giftVoucherSecurityCode.length === GIFT_VOUCHER_SECURITY_CODE.minLength
    && giftVoucherSerialNumber.length === GIFT_VOUCHER_SERIAL_NUMBER.minLength;

  const isValidGiftCard = giftCardNumber.length === GIFT_CARD_NUMBER.minLength
    && giftCardPin.length === GIFT_CARD_PIN.maxLength;

  return isValidGiftVoucher || isValidGiftCard;
};

export const getErrorMessage = (formId, {
  anyTouched,
  active,
  syncErrors,
  submitErrors,
  fields,
} = {}) => {
  if (!anyTouched || (isEmptyObject(syncErrors) && isEmptyObject(submitErrors))) {
    return '';
  }

  if (syncErrors) {
    const touchedSyncErrorFields = Object.keys(syncErrors).reduce((acc, key) => (
      fields[key]?.touched ? [...acc, key] : acc
    ), []);

    if (!touchedSyncErrorFields.length) {
      return '';
    }
    // show error for active field
    if (touchedSyncErrorFields?.includes(active)) {
      return syncErrors[active];
    }

    // show error for inactive field
    if (touchedSyncErrorFields.length === 1) {
      return syncErrors[touchedSyncErrorFields[0]];
    }

    // combined message when both fields are in error and none active
    if (formId === APPLY_GIFT_CARD_FORM_ID) {
      return 'Please enter the 19-digit card number and the 4-digit PIN on the back of your gift card';
    }

    if (formId === APPLY_GIFT_VOUCHER_FORM_ID) {
      return 'Please enter the 7 letter code at the top left and the 10 digit number at the bottom right of your voucher';
    }

    return null;
  }

  // get first submit error
  return Object.keys(submitErrors).reduce((acc, key) => (!acc ? submitErrors[key] : acc), '');
};

export const singleLettersOnlyRegex = /^[A-Za-z]{1}$/;
export const giftCardStartString = '6371';

export const getHint = ({
  giftVoucherSecurityCode = '',
  giftVoucherSerialNumber = '',
  giftCardNumberKey = '',
  giftCardNumberValue = '',
} = {}) => {

  if (
    giftVoucherSecurityCode.startsWith(giftCardStartString) ||
    giftVoucherSerialNumber.startsWith(giftCardStartString)
  ) {
    return 'gift card';
  }

  if (
    giftCardNumberKey &&
    singleLettersOnlyRegex.test(giftCardNumberKey) &&
    !giftCardNumberValue.startsWith(giftCardStartString)
  ) {
    return 'gift voucher';
  }

  return '';
};

export const suggestPrepaidCard = (giftCardNumberValue = '') => {
  const giftCardEnteredInvalid =
    !giftCardNumberValue.startsWith(giftCardStartString) &&
    (giftCardNumberValue.length > 15 && giftCardNumberValue.length < 19);

  if (giftCardEnteredInvalid) {
    return 'your details as a prepaid card';
  }

  return '';
};
