import { useMemo } from 'react';

// Design System
import Link, { SecondaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { MyAccountLinkProps } from './MyAccountLink.types';

const getAccountLink = (siteDomain: string): string => {
  const domainMap: Record<string, string> = {
    johnlewis: '//account.johnlewis.com',
    integration: '//account.integration.project4.com',
  };

  return Object.entries(domainMap).find(([key]) => siteDomain.includes(key))?.[1] || '';
};

const MyAccountLink = ({
  className,
  inline = false,
  isApps = false,
  level,
  text = 'My Account',
}: MyAccountLinkProps) => {
  if (isApps) {
    return text;
  }

  const siteDomain = window.publicJLSiteDomain;
  const link = useMemo(() => getAccountLink(siteDomain), [siteDomain]);
  const LinkComponent = level === 'secondary' ? SecondaryLink : Link;
  const linkProps = level === 'secondary' ? { inline } : {};

  return (
    <LinkComponent
      className={className}
      data-testid={`my-account-link-${level || 'link'}`}
      external
      target="_blank"
      to={link}
      {...linkProps}
    >
      {text}
    </LinkComponent>
  );
};

export default MyAccountLink;
