// Types
import { AddressRecordProps } from 'types/Address.types';

// Config
import isAddressValid, { getAddressValidationErrors } from './isAddressValid';
import { countryCodeIsInternational } from '../address/addressHelpers';
import errorCodeConstants from '../../constants/errorCodeConstants';

export function validateAddress(addressRecord: AddressRecordProps) {
  return {
    ...addressRecord,
    invalid: !isAddressValid(addressRecord),
  };
}

function validateDeliveryAddress(addressRecord: AddressRecordProps) {
  const { countryCode } = addressRecord.address ?? {};

  let unavailabilityError = addressRecord?.unavailabilityError;

  const errors = getAddressValidationErrors(addressRecord, true);
  const postcodeIneligible = errors?.postcode;

  if (postcodeIneligible) {
    unavailabilityError = {
      code: errorCodeConstants.CLIENT_ADDRESS_INELIGIBLE_DELIVERY_POSTCODE,
      message: postcodeIneligible === 'BFPO' ?
        'This address is disabled as we do not deliver to BFPO addresses'
        : "This address is disabled as you have an item in your order that can't be delivered to this address",
    };
  } else if (countryCodeIsInternational(countryCode)) {
    unavailabilityError = {
      message: 'This address is disabled as we do not offer international delivery',
    };
  } else if (addressRecord?.partnerStore) {
    unavailabilityError = {
      message: "This address is disabled as it can't be delivered to",
    };
  } else if (unavailabilityError) {
    unavailabilityError = {};
  }

  let validatedAddress = addressRecord;

  if (unavailabilityError) {
    validatedAddress = {
      ...addressRecord,
      unavailabilityError,
      notAvailableForDelivery: !!unavailabilityError.message,
    };
  }

  return validatedAddress;
}

export function validateAddressBookRecords(addressBook: AddressRecordProps[]) {
  return addressBook.map((addressRecord) => {
    const address = validateAddress(addressRecord);
    const deliveryAddress = validateDeliveryAddress(address);

    return {
      ...deliveryAddress,
    };
  });
}
