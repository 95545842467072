import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import 'url-search-params-polyfill';

// Design System
import env from 'jl-design-system/utils/env/env';
import FormAutoFill from 'jl-design-system/components/form/_dev/FormAutoFill';

// Types
import { AppDispatch } from 'types/RootState.types';
import { AppProps } from 'App.types';

// Config
import appState from './App.state';
import routeConstants from './constants/routeConstants';
import { useWindowSize } from './context/window/WindowSizeContext';
import { useHandbackToUrl } from './hooks/useHandbackToUrl';
import { initMonetateScript, appendGTMScript } from './redux/actions/app/appActions';
import { restoreAuth0Client } from './redux/actions/auth0-callback/auth0CallbackActions';
import { rebatchOrderAfterBasketEdit } from './redux/actions/edit-basket/editBasketActions';
import { loadIovationScripts } from './redux/actions/payment/paymentActions';
import { isDeliveryPage, isOrderConfirmationPage, isPaymentPage } from './utils/helpers/pageType';
import { initLogging } from './utils/logging/logging-utils';

// Components
import KlarnaContainer from './components/add-payment-details/klarna-container';
import AgeChecker from './components/age-checker';
import BatchingFailureModal from './components/batching-failure-modal/BatchingFailureModal';
import ConnectivityErrorModal from './components/connectivity-error-modal';
import Container from './components/container';
import DevConsole from './non-prod-utils/components/dev-console';
import EditBasketModal from './components/edit-basket-modal';
import EmptyBasketNotification from './components/empty-basket-notification';
import ESIBanner from './components/esi-banner';
import GenericError from './views/generic-error';
import Header from './components/header';
import LeaveCheckoutModal from './components/leave-checkout-modal';
import LimitedStockModal from './components/limited-stock-modal';
import LoadingScreen from './components/loading-screen';
import OrderCompleteNotification from './components/order-complete-notification';
import OrderTimeoutNotification from './components/order-timeout-notification';
import PosCreditSessionExpiredError from './components/pos-credit-session-expired-error';
import Recommendations from './components/recommendations';
import SessionExpired from './views/session-expired';
import SessionExpiryCounter from './components/session-expiry-counter/SessionExpiryCounter';
import SessionTimeoutRedirect from './components/session-timeout-redirect/SessionTimeoutRedirect';
import SessionTimeoutWarning from './components/session-timeout-warning';
import Sidebar from './components/sidebar';
import UnavailableItemsModal from './components/unavailable-items-modal';
import OutOfStockModal from './components/out-of-stock-modal';
import ToastRack from './components/toast-rack';
import PaymentWalletStubsModal from './non-prod-utils/components/payment-wallet-stubs/modal';
import PaymentWalletStubs from './non-prod-utils/components/payment-wallet-stubs';

// styles
import styles from './app.scss';

const App = ({ children, prevPathnameStub }: AppProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    basketUrl = '',
    browseUrl = '',
    centerVertical = false,
    editBasketModalBaseRoutePath = '',
    globalError,
    isGuest = true,
    isInvalidPartnerDiscount = false,
    isLoading = false,
    leaveCheckoutModalBaseRoutePath = '',
    loadPaymentWalletStubs = false,
    loadingMessage = '',
    orderComplete = false,
    orderConfirmationPageSuccess = false,
    orderNeedsToBeRebatched = false,
    outOfStockItems = [],
    pathname = '',
    paymentWalletStubName,
    platformType = '',
    posCreditSessionExpired = false,
    sessionExpired = false,
    shouldShowKlarnaContainer = false,
    shouldShowMinimalSaveOrder = false,
    showBatchingFailureModal = false,
    showConnectivityErrorModal = false,
    showDisableSiteSpinner = false,
    showEditBasketModal = false,
    showEmptyBasketNotification = false,
    showExpressPayments = false,
    showFullScreenSignup = false,
    showHeader = true,
    showLeaveCheckoutModal = false,
    showLimitedStockModal = false,
    showRecommendations = false,
    showUnavailableItemsModal = false,
    showUserActiveContent = false,
    submitOrderTimeout = false,
  } = useSelector(appState);
  const handbackPropsBrowse = useHandbackToUrl(browseUrl);
  const handbackPropsBasket = useHandbackToUrl(basketUrl);

  const { sidebarIsVisible } = useWindowSize();
  const prevPathnameRef = useRef<string | undefined>();

  useEffect(() => {
    if (window?.history.scrollRestoration === 'auto') {
      window.history.scrollRestoration = 'manual';
    }

    dispatch(initLogging());
    dispatch(restoreAuth0Client());
    dispatch(loadIovationScripts());
    dispatch(initMonetateScript());
    dispatch(appendGTMScript());
  }, []);

  useEffect(() => {
    const prevPathname = prevPathnameStub || prevPathnameRef.current;

    if (
      prevPathname?.includes(routeConstants.EDIT_BASKET) &&
      !pathname?.includes(routeConstants.EDIT_BASKET) &&
      orderNeedsToBeRebatched
    ) {
      dispatch(rebatchOrderAfterBasketEdit());
    }

    if (!isGuest) dispatch(restoreAuth0Client());

    dispatch(loadIovationScripts());
    dispatch(initMonetateScript());
    dispatch(appendGTMScript());

    prevPathnameRef.current = pathname;
  }, [pathname, isGuest, orderNeedsToBeRebatched]);

  let mainContent = children;

  const isPathExcluded = ![
    routeConstants.ORDER_CONFIRMATION,
    routeConstants.CALLBACK_ORDER_CLAIM,
    routeConstants.CALLBACK_CREATE_ACCOUNT,
    routeConstants.JOIN_MY_JL,
  ].some((routeConstant) => pathname?.includes(routeConstant));
  const showOrderCompleteNotification = orderComplete && isPathExcluded;

  const shouldShowHeader =
    showHeader && ![routeConstants.APPS_GUEST_HANDOVER, routeConstants.APPS_AUTHENTICATED_HANDOVER].includes(pathname);
  if (globalError) {
    mainContent = <GenericError />;
  } else {
    if (sessionExpired) {
      mainContent = <SessionExpired />;
    }

    if (showOrderCompleteNotification) {
      mainContent = <OrderCompleteNotification browseUrl={browseUrl} />;
    }

    if (showEmptyBasketNotification) {
      mainContent = <EmptyBasketNotification handleButtonClick={() => handbackPropsBrowse.onClick()} />;
    }

    if (submitOrderTimeout) {
      mainContent = <OrderTimeoutNotification isGuest={isGuest} />;
    }

    if (posCreditSessionExpired) {
      mainContent = (
        <PosCreditSessionExpiredError
          handleButtonClick={() => handbackPropsBasket.onClick()}
          isInvalidPartnerDiscount={isInvalidPartnerDiscount}
        />
      );
    }
  }

  const deliveryPageActive = isDeliveryPage({
    pathname,
    editBasketModalBaseRoutePath,
    leaveCheckoutModalBaseRoutePath,
  });
  const paymentPageActive = isPaymentPage({
    pathname,
    editBasketModalBaseRoutePath,
    leaveCheckoutModalBaseRoutePath,
  });
  const orderConfirmationActive = isOrderConfirmationPage(pathname);

  const showSidebar =
    showUserActiveContent &&
    sidebarIsVisible &&
    (deliveryPageActive || paymentPageActive || (orderConfirmationActive && !shouldShowMinimalSaveOrder));

  const containerClassNames = cx(styles.container, {
    [styles.centerVertical]: centerVertical,
    [styles.sidebarVisible]: showSidebar,
  });

  const shouldShowCitrusAds =
    showUserActiveContent && orderConfirmationActive && !shouldShowMinimalSaveOrder && orderConfirmationPageSuccess;

  const shouldShowRecommendations =
    showRecommendations &&
    orderConfirmationPageSuccess &&
    orderConfirmationActive &&
    !isLoading &&
    orderComplete &&
    !showFullScreenSignup;

  // TODO clarify with UX on what pages we can show out of stock/limited stock modals
  // If express payments are enabled, allow modal to show on any page
  // If express payments are not enabled, do not show on delivery page
  const showOutOfStockModal =
    outOfStockItems.length > 0 && !showEditBasketModal && (showExpressPayments || !deliveryPageActive);

  const modals = !sessionExpired
    ? [
      { id: 'ageChecker', condition: true, component: <AgeChecker /> },
      { id: 'batchingFailureModal', condition: showBatchingFailureModal, component: <BatchingFailureModal /> },
      { id: 'leaveCheckoutModal', condition: showLeaveCheckoutModal, component: <LeaveCheckoutModal /> },
      { id: 'limitedStockModal', condition: showLimitedStockModal, component: <LimitedStockModal /> },
      {
        id: 'editBasketModal',
        condition: showEditBasketModal,
        component: <EditBasketModal isDeliveryPage={deliveryPageActive} />,
      },
      { id: 'unavailableItemsModal', condition: showUnavailableItemsModal, component: <UnavailableItemsModal /> },
      { id: 'outOfStockModal', condition: showOutOfStockModal, component: <OutOfStockModal /> },
    ] : [];

  return (
    <div className={styles.app}>
      <SessionExpiryCounter />

      {
        /* NOTE: This will not render in production */
        (env.isClientLocal || env.isClientDev) && <FormAutoFill id="urlQueryParams" />
      }

      {shouldShowHeader && <Header />}

      {shouldShowCitrusAds && (
        <Container background="taupe-60" hideForPrint tag="section">
          <Container marginX="auto" maxWidth="xl">
            <ESIBanner
              id="citrus_skinny"
              type="skinny"
              url={`/esi/online-adverts/banner/broad?placement=orderConfirmation&forceFullyQualifiedESI=true&type=skinny&platform=${platformType}`}
            />
          </Container>
        </Container>
      )}

      <main
        className={cx(styles.main, {
          [styles.sidebarVisible]: showSidebar,
          [styles.app]: !shouldShowHeader,
        })}
        data-testid="main"
        id="main"
      >
        {deliveryPageActive && <ToastRack />}

        {shouldShowHeader &&
          !centerVertical &&
          (deliveryPageActive || paymentPageActive || pathname.includes(routeConstants.ORDER_CONFIRMATION)) && (
          <h1 className={cx(styles.mainHeading, { [styles.sidebarVisible]: showSidebar })}>
            {orderComplete ? 'Order confirmation' : 'Checkout'}
          </h1>
        )}

        <LoadingScreen
          isApps={!showHeader}
          message={loadingMessage}
          seeThrough={showDisableSiteSpinner}
          show={isLoading}
        />

        <Container
          flex={{ flexItem: '1-0-auto', justifyContent: 'center' }}
          maxWidth="xl"
          minWidth="xs"
          relative
          tag="section"
          width="full"
        >
          <div className={containerClassNames} data-testid="main-content">
            {mainContent}
          </div>
          {showSidebar && <Sidebar isDeliveryPage={deliveryPageActive} isOrderConfirmationPage={orderComplete} />}
        </Container>

        {modals
          .filter(({ condition }) => condition)
          .map(({ component, id }) => (
            <Fragment key={id}>{component}</Fragment>
          ))}

        {!orderComplete && (
          <>
            {!sessionExpired && !submitOrderTimeout && <SessionTimeoutWarning />}
            <SessionTimeoutRedirect />
          </>
        )}

        {showConnectivityErrorModal && <ConnectivityErrorModal />}

        {env.isClientNonProd && (
          <>
            <DevConsole showHeader={showHeader} />
            {loadPaymentWalletStubs && (
              <>
                <PaymentWalletStubs stubName={paymentWalletStubName} />
                <PaymentWalletStubsModal />
              </>
            )}
          </>
        )}

        {shouldShowKlarnaContainer && <KlarnaContainer />}
      </main>

      {shouldShowRecommendations && <Recommendations />}
    </div>
  );
};

export default App;
