// Config
import { REFRESHING_APPS_ACCESS_TOKEN } from '../../constants/actionConstants';
import appConstants from '../../constants/appConstants';
import { WEB } from '../../constants/applicationTypeConstants';
import {
  URL_CONFIGURATION,
  URL_FEATURES,
  URL_WEB_HANDOVER,
  URL_APPS_HANDOVER,
} from '../../constants/endpointConstants';
import { getAppApplicationTypeSelector } from '../../redux/reducers/app/appSelector';

async function getWebAuthorizationHeader() {
  try {
    const browserAccessToken = await window.Auth0Client.getTokenSilently({
      timeoutInSeconds: appConstants.AUTH0_TOKEN_TIMEOUT,
    });
    if (typeof browserAccessToken === 'string') {
      return {
        Authorization: `Bearer ${browserAccessToken}`,
      };
    }
  } catch (err) {
    // fail silently so as not to interrupt API call
  }

  return {};
}

async function getAppsAuthorizationHeader(params) {
  const { getState, dispatch } = params;

  if (!getState().mobileApps?.refreshingAppsAccessToken) {
    const requestAccessToken = getState().mobileApps?.requestAccessToken;

    if (typeof requestAccessToken === 'function') {
      // TODO investigate why we have both REFRESHING_APPS_ACCESS_TOKEN and APPS_REFRESH_ACCESS_TOKEN_REQUESTED
      dispatch({
        type: REFRESHING_APPS_ACCESS_TOKEN,
      });

      // requests that the app gives us an up to date token, received in mobileAppsReducer
      requestAccessToken();
    }
  }

  const auth0AccessToken = getState().mobileApps?.auth0AccessToken;
  if (auth0AccessToken) {
    return {
      Authorization: `Bearer ${auth0AccessToken}`,
    };
  }

  return {};
}

export default async function getAuthorizationHeader(params) {
  const { path, getState } = params;

  const ignorePaths = [URL_FEATURES, URL_CONFIGURATION, URL_WEB_HANDOVER, URL_APPS_HANDOVER];

  if (ignorePaths.includes(path)) {
    return {};
  }

  const isGuest = getState().user?.isGuest;
  if (isGuest) {
    return {};
  }

  const webAuthorizationHeaderApplications = [WEB];
  const applicationType = getAppApplicationTypeSelector(getState());
  if (webAuthorizationHeaderApplications.includes(applicationType)) {
    return getWebAuthorizationHeader();
  }

  if (!webAuthorizationHeaderApplications.includes(applicationType)) {
    return getAppsAuthorizationHeader(params);
  }

  return {};
}
