// Config
import getErrorMessageObject from './getErrorMessageObject';
import errorCodeConstants from '@constants/errorCodeConstants';
import { SUBMIT_ORDER } from '@constants/actionConstants';

export const globalErrors = [
  errorCodeConstants.SERVER_INTERNAL_ERROR,
  errorCodeConstants.BFF_SERVER_INTERNAL_ERROR,
  errorCodeConstants.CUSTOMER_SYNC_FAILED,
  errorCodeConstants.CODE_UNAUTHORIZED_TOKEN,
  errorCodeConstants.HANDOVER_TOKEN_BAD_SIGNATURE,
  errorCodeConstants.HANDOVER_TOKEN_MALFORMED,
  errorCodeConstants.HANDOVER_TOKEN_EXPIRED,
  errorCodeConstants.CLIENT_UNKNOWN_TECHNICAL_ERROR,
  errorCodeConstants.INCONSISTENT_DELIVERY_INFO,
  errorCodeConstants.CUSTOMER_NOT_FOUND,
  errorCodeConstants.DELIVERY_ORCHESTRATOR_SERVICE_UNAVAILABLE,
  errorCodeConstants.LAZY_COMPONENT_WITH_SUSPENSE_ERROR,
  errorCodeConstants.CATALOGUE_SERVICE_UNAVAILABLE,
];

export function getNonGlobalError(action, prevError, vars, doNotReturnDefault = false) {
  let error = action?.error;
  let code = error?.code;

  const prevRefreshes = prevError?.refreshes;
  const refreshes = prevRefreshes === undefined ? 0 : prevRefreshes + 1;

  const ignoredNonGlobalErrors = [
    errorCodeConstants.BATCHING_FAILURE,
    errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE,
    errorCodeConstants.CLIENT_CONNECTIVITY_ERROR,
  ];

  const shouldModifyGlobalError = action.type === `${SUBMIT_ORDER}.FAILED` && code === errorCodeConstants.SERVER_INTERNAL_ERROR;

  if (shouldModifyGlobalError) {
    code = errorCodeConstants.SUBMIT_ORDER_SERVER_INTERNAL_ERROR;
    error = {
      ...error,
      code,
    };
  }

  if (
    !ignoredNonGlobalErrors?.includes(code) &&
    !globalErrors?.includes(code)
  ) {
    const errorMessageObject = getErrorMessageObject({ error, vars, refreshes, doNotReturnDefault });
    return errorMessageObject ? {
      ...errorMessageObject,
      refreshes,
    } : undefined;
  }

  return undefined;
}

export function getGlobalError(action) {
  const error = action?.error;
  const code = error?.code;

  return !code || globalErrors?.includes(code) ? getErrorMessageObject({ error }) : undefined;
}
