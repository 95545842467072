import { lazy } from 'react';

// Types
import { AddressBookModalProps as LazyComponentProps } from './AddressBookModal.types';

// Components
import AddressBookModalPlaceholder from './AddressBookModalPlaceholder';
import AddressBookModalRemoveOverlay from './AddressBookModalRemoveOverlay';
import withSuspense, { lazyRetry } from '@components/suspense';

const LazyComponent = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "addressBookModal" */ './AddressBookModal')),
);

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'AddressBookModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as AccountManagement, AddressBookModalPlaceholder, AddressBookModalRemoveOverlay };
