import structuredClone from '@ungap/structured-clone';

// Design System
import replaceStringVars from 'jl-design-system/utils/string/replaceStringVars';

// Config
import getErrorArray from './getErrorArray';
import getBreadcrumb from './getBreadcrumb';
import {
  GET_DELIVERY_PAGE,
  GET_LOGIN_PAGE,
  GET_ORDER_CONFIRMATION_PAGE,
  GET_MINIMAL_ORDER_CONFIRMATION_PAGE,
  GET_PAYMENT_PAGE,
  INIT_PAYMENT_PAGE,
  POST_DELIVERY_PAGE,
  ROUTER_LOCATION_CHANGE,
  SESSION_EXPIRED,
} from '@constants/actionConstants';
import routeConstants from '@constants/routeConstants';
import { pageNamesByRoute } from '@utils/analytics/index';
import { deepMerge, getNestedProperties, setNestedProperties } from '@utils/object';

export const getAnalyticsEventName = (config) => {
  if (config.errorType) {
    return 'dataCheckoutError';
  }
  return 'dataStateChange';
};

export const getAnalyticsEventDetails = (eventName, action = {}) => {
  if (action.analyticsEventDetails) {
    return action.analyticsEventDetails;
  }

  if (eventName !== 'dataStateChange') {
    return undefined;
  }

  const pageloadActions = [
    `${GET_DELIVERY_PAGE}.SUCCESS`,
    `${GET_LOGIN_PAGE}.SUCCESS`,
    `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`,
    GET_MINIMAL_ORDER_CONFIRMATION_PAGE,
    `${GET_PAYMENT_PAGE}.SUCCESS`,
    `${INIT_PAYMENT_PAGE}.SUCCESS`,
    `${POST_DELIVERY_PAGE}.SUCCESS`,
    ROUTER_LOCATION_CHANGE,
    SESSION_EXPIRED,
  ];

  if (pageloadActions.includes(action.type)) {
    const orderConfirmationPageAction = [
      `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`,
      GET_MINIMAL_ORDER_CONFIRMATION_PAGE,
    ].includes(action.type);

    return {
      action: 'pageload',
      ...(orderConfirmationPageAction && {
        type: window.location.href.includes(routeConstants.JOIN_MY_JL) ? 'join-my-jl' : 'order-confirmation',
      }),
    };
  }

  return {
    action: 'checkoutDataUpdate',
  };
};

export const getAnalyticsPageNameByRoute = (params) => {
  const { pathname, proceedingToPayment, details, passwordlessOrderConfirmedAndSaved } = params;

  if (passwordlessOrderConfirmedAndSaved === true) {
    return 'jl:checkout:passwordless order saved';
  }

  if (passwordlessOrderConfirmedAndSaved === false) {
    return 'jl:checkout:passwordless error';
  }

  if (proceedingToPayment && details?.action !== 'continueToPayment') {
    return pageNamesByRoute[routeConstants.PAYMENT];
  }

  return pageNamesByRoute[pathname] || 'jl:checkout:page not found';
};

export default function getEventObject({
  action = {},
  state = {},
  config = {},
  analytics = {},
  replaceAnalyticsData = false,
}) {
  const pathname = state?.route?.pathname;

  const {
    eventName,
    eventDetails,
    pageName: configPageName,
    errorType,
    errorMessage,
    replace,
    detailsToKeep,
    replaceByProp,
    additionalProperties,
    ...allOtherProps
  } = config;

  const proceedingToPayment = !!state.proceedingToPayment;

  const event = eventName || getAnalyticsEventName(config);
  let details = eventDetails || getAnalyticsEventDetails(event, action);

  if (eventDetails?.stringVarsToReplace) {
    const targetVar = eventDetails?.stringVarsToReplace[0];
    const { stringVarsToReplace: _stringVarsToReplace, ...rest } = details;

    details = {
      ...rest,
      [targetVar]: replaceStringVars(details[targetVar], {
        [targetVar]: action[targetVar],
      }),
    };
  }

  if (eventDetails?.includeActionFields) {
    // This could be refactored to allow for multiple action fields to be included
    const field = eventDetails.includeActionFields[0];
    const { includeActionFields: _includeActionFields, ...rest } = details;

    details = {
      ...rest,
      [field]: action[field],
    };
  }

  const pageName =
    configPageName ||
    getAnalyticsPageNameByRoute({
      pathname: pathname || window?.location?.pathname,
      proceedingToPayment,
      details,
      passwordlessOrderConfirmedAndSaved: state.passwordlessOrderConfirmedAndSaved,
    });

  const breadCrumb = getBreadcrumb(pageName);

  const { analyticsData: currAnalytics } = state;
  const additionalDetailExists = !!currAnalytics?.checkout?.additionalDetail;

  const irreplaceableAnalyticsData = {
    ...(additionalDetailExists && {
      checkout: currAnalytics?.checkout,
    }),
    monetateServer: currAnalytics?.monetateServer,
    page: {
      pageInfo: {
        sso: currAnalytics?.page?.pageInfo?.sso,
      },
    },
  };
  // analytics updates are deep merged into current analytics data object unless action.replace is TRUE
  const analyticsData = replaceAnalyticsData
    ? deepMerge(structuredClone(analytics), irreplaceableAnalyticsData)
    : deepMerge(structuredClone(currAnalytics), analytics);

  if (replace) {
    replace.forEach((item) => {
      if (Array.isArray(item)) {
        setNestedProperties(analyticsData, item[0], item[1]);
      } else {
        const prop = item;
        setNestedProperties(analyticsData, prop, getNestedProperties(analytics, prop));
      }
    });
  }

  if (replaceByProp) {
    replaceByProp.forEach((item) => {
      if (Array.isArray(item)) {
        const value = getNestedProperties(action, item[1]);
        const defaultValue = getNestedProperties(currAnalytics, item[1]);

        setNestedProperties(analyticsData, item[0], value ?? defaultValue);
      }
    });
  }

  if (detailsToKeep) {
    detailsToKeep.forEach((item) => {
      setNestedProperties(details, item, getNestedProperties(action, item));
    });
  }

  // the page object is updated with default values and config options
  analyticsData.page = analyticsData.page || {};
  analyticsData.page.pageInfo = {
    ...analyticsData.page.pageInfo,
    pageType: 'checkout',
    pageName,
    breadCrumb,
    ...allOtherProps,
  };

  // the error object is updated if errorType is specified in config
  analyticsData.error = errorType
    ? getErrorArray(errorType, errorMessage, action, state, additionalProperties)
    : analyticsData.error;
  if (!analyticsData.error) {
    analyticsData.error = (analytics?.error ?? []).length ? analytics.error : '';
  }
  return {
    event,
    details,
    data: analyticsData,
  };
}
