import { useRef } from 'react';
import cx from 'classnames';

// Design System
import unknownStoreIcon from 'jl-design-system/assets/images/stores/unknown-store-icon.svg';

// Types
import { ClickAndCollectStoreIconProps } from './ClickAndCollectStoreIcon.types';

// Styles
import styles from './click-and-collect-store-icon.scss';

const ClickAndCollectStoreIcon = ({
  promoted = false,
  store = undefined,
  small = false,
}: ClickAndCollectStoreIconProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const setImageSrc = () => {
    if (imageRef.current) {
      imageRef.current.setAttribute('src', unknownStoreIcon);
    }
  };

  if (!store) {
    return null;
  }

  const { icon, type = '' } = store;

  const iconClassnames = cx(styles.icon, {
    [styles.promoted]: promoted,
    [styles.small]: small,
  });

  if (icon) {
    // icon comes from CPS
    return <img ref={imageRef} alt={type} className={iconClassnames} onError={setImageSrc} src={icon} />;
  }

  // placeholder icon comes from Design System
  return <img alt="unknown store icon" className={iconClassnames} src={unknownStoreIcon} />;
};

export default ClickAndCollectStoreIcon;
