import { createSelector } from 'reselect';

// Config
import { getLoadingLazyComponentSelector } from '../../../../redux/reducers/app/appSelector';
import { getConfirmedDeliveryAddressSelector } from '../../../../redux/reducers/delivery/deliverySelector';
import { getIsCardFormValidSelector } from '../../../../redux/reducers/payment/paymentSelector';
import { getIsSignedInWithDataSelector, getSelectedBillingAddressSelector } from '../../../../redux/reducers/user/userSelector';
import { isEmptyObject } from '@utils/object';

const billingAddressExistsSelector = createSelector(
  [getSelectedBillingAddressSelector, getConfirmedDeliveryAddressSelector],
  (selectedBillingAddress, confirmedDeliveryAddress) => {
    const billingAddress = selectedBillingAddress || confirmedDeliveryAddress;
    return !isEmptyObject(billingAddress);
  },
);

const billingDetailsState = createSelector(
  [
    billingAddressExistsSelector,
    getLoadingLazyComponentSelector,
    getIsSignedInWithDataSelector,
    getIsCardFormValidSelector,
  ],
  (billingAddressExists, isLoadingLazyComponent, isSignedInWithData, isCardFormValid) => ({
    billingAddressExists,
    isLoadingLazyComponent,
    isSignedInWithData,
    isCardFormValid,
  }),
);

export default billingDetailsState;
