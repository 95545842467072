import { AnyAction } from 'redux';
import { format, isAfter } from 'date-fns';

// Types
import { PaymentCardProps } from 'types/Payment.types';
import { ProductProps } from 'types/Product.types';
import { UserState } from 'types/RootState.types';

// Config
import { mapSavedCollectionPoints } from '@redux/reducers/click-and-collect/clickAndCollectReducer';
import {
  GET_DELIVERY_PAGE,
  INIT_PAYMENT_PAGE,
  LOGIN_CREDS,
  GET_COLLECTION_POINT_DETAILS,
  GET_ORDER_CONFIRMATION_PAGE,
  USER_AGE_CHECK,
  GET_ADDRESS_BOOK,
  PUT_ADDRESS_BOOK_UPDATE,
  HAND_OVER_RESET,
  SET_PAYMENT_TYPE,
  PUT_DELIVERY_ADDRESS,
  LOGIN_RESET,
  CHANGE_DELIVERY_ADDRESS,
  SET_PARTNER_DISCOUNT,
  GET_PAYMENT_WALLET,
  DELIVERY_ADDRESS_SELECT_ADDRESS,
  EDIT_DELIVERY_ADDRESS,
  REBATCH_ORDER,
  SET_PARTNER_DISCOUNT_PROCESSING,
  SHOW_BATCHING_FAILURE_MODAL,
  SHOW_REMOVE_BATCHING_FAILURE_ERROR,
  AUTH0_CALLBACK,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
  DELETE_COLLECTION_POINT,
  BILLING_ADDRESS_SELECT_ADDRESS,
  HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
  USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS,
  PAYMENT_CARD_DELETE,
  POST_DELIVERY_PAGE,
  USE_DIFFERENT_RESIDENTIAL_ADDRESS,
  GET_SAVED_COLLECTION_POINTS,
  MOUNT_POS_CREDIT_ADDRESS_FORM,
  AUTH0_HEALTH_CHECK,
  USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  AUTH0_CLAIM_ORDER_CALLBACK,
  AUTH0_REGISTRATION_CALLBACK,
  SIGN_OUT_USER,
  GET_AGE_VERIFICATION_SESSION,
  GET_AGE_VERIFICATION_RESULT,
  SET_AGE_VERIFICATION_LOADING_STATE,
  SET_AGE_VERIFICATION_CONFIRMATION_STATE,
  GET_ITEMS,
  REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET,
  APPLY_REWARDS,
} from '@constants/actionConstants';
import deliveryConstants from '@constants/deliveryConstants';
import errorCodeConstants from '@constants/errorCodeConstants';
import { ON_STATUS } from '@constants/partnerDiscountConstants';
import userConstants from '@constants/userConstants';
import { getAddressFromFormValues, getBillingAddressForPaymentType } from '@utils/address/addressHelpers';
import { validateAddressBookRecords, validateAddress } from '@utils/addressBook/validateAddressBookRecords';
import mergeAddressBook from '@utils/addressBook/mergeAddressBookRecords';
import initAddressBook from '@utils/addressBook/initAddressBook';
import { isNameAndNumberComplete } from '@utils/addressBook/isAddressValid';
import getErrorMessageObject from '@utils/error/getErrorMessageObject';
import { getNonGlobalError } from '@utils/error/parseError';
import { BILLING_ADDRESS_FORM_ID, RESIDENTIAL_ADDRESS_FORM_ID } from '@utils/form/configs/billingAddress';
import { DELIVERY_ADDRESS_FORM_ID } from '@utils/form/configs/deliveryAddress';
import { isEmptyObject } from '@utils/object';

export const INITIAL_STATE = {
  isSignedIn: false,
  isGuest: true,
  isSignedInWithData: false,
  addressBook: [],
  collectionPoints: [],
  savedPaymentCards: [],
  loyaltyType: '',
  membershipNumber: '',
  showMyJLAccountPrompt: false,
  isLoyaltyPendingApproval: false,
  showMarketingPreferencesPrompt: false,
  ageCheckDate: '',
  ageCheckError: undefined,
  ageCheckRequired: false,
  ageCheckSuccess: false,
  showAgeCheckModal: false,
  ageRestrictedProducts: [],
  ageRestrictedProductsAllowRemoval: false,
  userDob: '',
  userDobInvalid: false,
  ageCheckMinimumAge: '',
  selectedCollectionPoint: undefined,
  selectedBillingAddress: undefined,
  selectedDeliveryAddress: undefined,
  previousSelectedDeliveryAddress: undefined,
  userEnteredDeliveryAddress: undefined,
  canUseSelectedDeliveryAddress: true,
  defaultAddress: undefined,
  eligibleForPartnerDiscount: false,
  partnerDiscountCardNumber: '',
  partnerDiscountStatus: undefined,
  partnerDiscountEnabled: false,
  isPartnerDiscountApiCallActive: false,
  disableAutoApplyPartnerDiscount: false,
  applyPartnerDiscountError: undefined,
  fetchedPaymentWallet: false,
  postcodeOutOfAreaError: undefined,
  orderFormItemsLength: 0,
  isPartnerDiscountProcessing: false,
  showBatchingFailureModal: false,
  allItemsHaveBatchingFailure: false,
  batchingFailureItems: undefined,
  showRemoveBatchingFailureItemError: false,
  paymentWalletApiCallFailed: false,
  orderSaved: false,
  invalidPOSCreditAddress: undefined,
  shouldUseDeliveryAddressAsResidentialAddress: true,
  yotiCheckSuccess: false,
  showYotiConfirmationModal: false,
};

function getPostcodeOutOfAreaError(action: AnyAction) {
  const errorCode = action?.error?.code;

  if (
    errorCode === errorCodeConstants.POSTCODE_OUT_OF_AREA ||
    errorCode === errorCodeConstants.INVALID_ADDRESS_DETAILS ||
    errorCode === errorCodeConstants.PARTNER_STORE_DELIVERY_ERROR
  ) {
    return getNonGlobalError({
      ...action,
      error: {
        code: errorCode,
      },
    });
  }

  return undefined;
}

function getEarliestDobFromProducts(products: ProductProps[]) {
  return products?.reduce(
    (acc, product) => (!acc || (product.dobNotLaterThan ?? 0) < acc ? (product.dobNotLaterThan ?? 0) : acc),
    0,
  );
}

function getOldestMinimumAge(products: ProductProps[]) {
  const age = products?.reduce(
    (acc, product) => ((product?.minimumAge ?? 0 > acc) ? (product?.minimumAge ?? 0) : acc),
    0,
  );
  return age ? `${age}` : '';
}

function getAgeRestrictedProducts(products: ProductProps[] = [], dob: string | number | Date) {
  const filteredProducts = products.filter((product) => !product.bladedArticle);

  if (!dob) {
    return filteredProducts;
  }

  return filteredProducts.filter((product) => product?.dobNotLaterThan && isAfter(dob, product?.dobNotLaterThan));
}

function getSignedInStatus(signedInStatus: string) {
  if (!signedInStatus) {
    return {};
  }

  // TODO pick one of these to use everywhere and remove the other
  const isGuest = signedInStatus === userConstants.GUEST;
  const isSignedIn = signedInStatus === userConstants.AUTHENTICATED;

  return {
    isGuest,
    isSignedIn,
  };
}

function getLoyaltyInfo(
  loyaltyMembershipInfo: { loyaltyType: string; myJohnLewisMembershipPending: boolean },
  isSignedIn = false,
) {
  if (!loyaltyMembershipInfo) {
    return {};
  }

  const { loyaltyType, myJohnLewisMembershipPending } = loyaltyMembershipInfo;
  const showMyJLAccountPrompt = isSignedIn && loyaltyType === userConstants.LOYALTY_NOT_A_MEMBER;

  return {
    showMyJLAccountPrompt,
    myJohnLewisMembershipPending,
    loyaltyType,
  };
}

export const getDefaultPaymentCardOrFirst = (paymentWallet: PaymentCardProps[]) =>
  paymentWallet.find((card) => card.default) || paymentWallet[0];

const getDOBState = (result: any, state: UserState, userDob: string) => {
  const ageRestrictedProducts = getAgeRestrictedProducts(result?.ageRestrictedProducts, userDob);

  const ageCheckDate = ageRestrictedProducts?.length
    ? format(getEarliestDobFromProducts(ageRestrictedProducts), 'YYYY-MM-DD')
    : '';

  const userDobInvalid = userDob && ageCheckDate ? isAfter(userDob, ageCheckDate) : false;
  const ageCheckRequired = Boolean(ageCheckDate && (state.isGuest || (state.isSignedIn && !userDob)));

  return {
    ageRestrictedProducts,
    ageCheckDate,
    userDobInvalid,
    ageCheckRequired,
    ageRestrictedProductsInBasket: result?.ageRestrictedProducts,
    showAgeCheckModal: !state.ageCheckSuccess && Boolean(userDobInvalid || ageCheckRequired),
  };
};

const userReducer = (state: UserState = INITIAL_STATE, action: AnyAction) => {
  const actionType = action?.type || '';

  switch (actionType) {
    case '@@redux-form/CHANGE': {
      const formId = action?.meta?.form ?? '';
      const field = action?.meta?.field;

      if (formId === BILLING_ADDRESS_FORM_ID) {
        if (field === 'id') {
          const selectedBillingAddress = state.addressBook?.find(
            (addressRecord) => addressRecord.id === action.payload,
          );
          return {
            ...state,
            selectedBillingAddress,
          };
        }
      }

      if (formId === RESIDENTIAL_ADDRESS_FORM_ID) {
        if (field === 'id') {
          const selectedResidentialAddress = state.addressBook?.find(
            (addressRecord) => addressRecord.id === action.payload,
          );
          return {
            ...state,
            selectedResidentialAddress,
            invalidPOSCreditAddress: undefined,
            shouldUseDeliveryAddressAsResidentialAddress: false,
          };
        }
      }

      if (formId === DELIVERY_ADDRESS_FORM_ID) {
        const fieldValue = action.payload;

        if (field === 'id') {
          const previousSelectedDeliveryAddress = state.selectedDeliveryAddress;
          const selectedDeliveryAddress = state.addressBook?.find((addressRecord) => addressRecord.id === fieldValue);

          return {
            ...state,
            selectedDeliveryAddress,
            previousSelectedDeliveryAddress,
            selectedResidentialAddress: state.shouldUseDeliveryAddressAsResidentialAddress
              ? selectedDeliveryAddress
              : state.selectedResidentialAddress,
          };
        }

        return {
          ...state,
          userEnteredDeliveryAddress: {
            ...state.userEnteredDeliveryAddress,
            [field]: fieldValue,
          },
        };
      }

      return state;
    }

    case '@@redux-form/INITIALIZE': {
      const formId = action?.meta?.form ?? '';

      // TODO do we still need to do this?
      if (formId === DELIVERY_ADDRESS_FORM_ID) {
        return {
          ...state,
          userEnteredDeliveryAddress: {
            ...action.payload,
          },
        };
      }

      return state;
    }

    case `${AUTH0_CALLBACK}.SUCCESS`:
    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${LOGIN_CREDS}.SUCCESS`: {
      const result = action.result || {};
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        email: result.customer?.email,
        ...signedInStatus,
        ...loyaltyInfo,
        showBatchingFailureModal: false,
      };
    }

    case `${USER_AGE_CHECK}.FAILED`: {
      const ageRestrictedProducts = getAgeRestrictedProducts(state.ageRestrictedProducts, action.dob);
      const ageRestrictedProductsCanRemove = ageRestrictedProducts.length < (state.orderFormItemsLength ?? 0);
      const errorTitleSuffix = ageRestrictedProducts.length === 1 ? 'this item' : 'these items';

      return {
        ...state,
        ageCheckError: ageRestrictedProductsCanRemove
          ? {
            code: errorCodeConstants.CLIENT_AGE_CHECK_FAILED,
            message: `You are not old enough to purchase ${errorTitleSuffix}`,
            body: 'Please remove this item so that you can continue to order your remaining item(s). Alternatively, you can return back to the basket.',
          } : {
            code: errorCodeConstants.CLIENT_AGE_CHECK_FAILED,
            message: 'You are not old enough to continue with your order',
            body: 'Please return to your basket and remove the age-restricted item(s).',
          },
        showAgeCheckModal: true,
        ageRestrictedProducts,
        ageRestrictedProductsCanRemove,
        ageCheckMinimumAge: getOldestMinimumAge(ageRestrictedProducts),
      };
    }

    case `${USER_AGE_CHECK}.SUCCESS`: {
      return {
        ...state,
        ageRestrictedProducts: [],
        ageCheckDate: '',
        ageCheckRequired: false,
        ageCheckError: undefined,
        ageCheckSuccess: true,
        showAgeCheckModal: false,
        ageCheckMinimumAge: '',
      };
    }

    case `${REBATCH_ORDER}.SUCCESS`: {
      return {
        ...state,
        showAgeCheckModal: false,
        ageCheckRequired: false,
      };
    }

    case SET_PAYMENT_TYPE: {
      const { paymentType, shouldSetBillingAddress } = action;
      const { addressBook, savedPaymentCards } = state;

      const selectedBillingAddress = getBillingAddressForPaymentType({
        addressBook,
        paymentType,
        savedPaymentCards,
        shouldSetBillingAddress,
      });

      return {
        ...state,
        selectedBillingAddress: selectedBillingAddress || state.selectedBillingAddress,
      };
    }

    case `${POST_DELIVERY_PAGE}.SUCCESS`:
    case `${GET_DELIVERY_PAGE}.SUCCESS`: {
      const rawAddressBook = action?.result?.customer?.addressBook ?? [];
      const partnerDiscount = action?.result?.partnerDiscountDetails ?? {};
      const eligibleForPartnerDiscount = action?.result?.partnerDiscountDetails?.eligibleForPartnerDiscount;
      const partnerDiscountStatus = partnerDiscount.status;
      const okToEnablePartnerDiscount = eligibleForPartnerDiscount && partnerDiscountStatus === ON_STATUS;
      const partnerDiscountEnabled = state.disableAutoApplyPartnerDiscount ? false : okToEnablePartnerDiscount;

      const { addressBook, selectedDeliveryAddress, canUseSelectedDeliveryAddress } = initAddressBook(state, action);

      const isSignedInWithData = addressBook.length > 0 && !isEmptyObject(rawAddressBook[0]);

      const result = action.result;
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      const userDob = result?.customer?.dateOfBirth ?? '';
      const collectionPoints = action?.result?.customer?.collectionPoints ?? [];
      const collectionPointsSaved = collectionPoints.length > 0;

      const postcodeOutOfAreaError =
        selectedDeliveryAddress?.unavailabilityError?.code === errorCodeConstants.POSTCODE_OUT_OF_AREA
          ? state.postcodeOutOfAreaError
          : undefined;

      return {
        ...state,
        ...loyaltyInfo,
        email: result.customer?.email,
        customerPreferences: action?.result?.customer?.preferences,
        eligibleForPartnerDiscount,
        partnerDiscountCardNumber: action?.result?.partnerDiscountDetails?.partnerDiscountCardNumber ?? '',
        partnerDiscountStatus,
        partnerDiscountEnabled,
        addressBook,
        defaultAddress: isSignedInWithData ? selectedDeliveryAddress : undefined,
        selectedDeliveryAddress,
        canUseSelectedDeliveryAddress,
        isSignedInWithData,
        deliveryPageLoaded: true,
        userDob,
        showBatchingFailureModal: false,
        collectionPoints: isEmptyObject(state.collectionPoints) ? collectionPoints : state.collectionPoints,
        collectionPointsSaved,
        postcodeOutOfAreaError,
        ...signedInStatus,
        ...!action?.deliveriesV3FeatureActive && getDOBState(result, state, userDob),
      };
    }

    case `${GET_ITEMS}.SUCCESS`:
    case `${APPLY_REWARDS}.SUCCESS`: {
      return {
        ...state,
        orderFormItemsLength: action?.result?.orderForm?.items?.length || state.orderFormItemsLength || 0,
        ...action?.deliveriesV3FeatureActive && getDOBState(action?.result, state, (state.userDob ?? '')),
      };
    }

    case `${GET_SAVED_COLLECTION_POINTS}.SUCCESS`: {
      return {
        ...state,
        collectionPoints: mapSavedCollectionPoints(action?.result?.collectionPoints),
        savedCollectionPointsLoaded: true,
      };
    }

    case `${SET_PARTNER_DISCOUNT}.LOADING`: {
      return {
        ...state,
        isPartnerDiscountApiCallActive: true,
        isPartnerDiscountProcessing: true,
        partnerDiscountEnabled: action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: !action.partnerDiscountEnabled,
      };
    }

    case `${SET_PARTNER_DISCOUNT}.FAILED`: {
      return {
        ...state,
        isPartnerDiscountApiCallActive: false,
        isPartnerDiscountProcessing: false,
        partnerDiscountEnabled: !action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: action.partnerDiscountEnabled,
        applyPartnerDiscountError: getNonGlobalError({
          ...action,
          error: {
            code: errorCodeConstants.APPLY_PARTNER_DISCOUNT_FAILED,
          },
        }),
      };
    }

    case `${SET_PARTNER_DISCOUNT}.SUCCESS`: {
      return {
        ...state,
        isPartnerDiscountApiCallActive: false,
        partnerDiscountEnabled: action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: !action.partnerDiscountEnabled,
      };
    }

    case `${SET_PARTNER_DISCOUNT_PROCESSING}.FALSE`: {
      return {
        ...state,
        isPartnerDiscountProcessing: false,
      };
    }

    case `${GET_COLLECTION_POINT_DETAILS}.SUCCESS`: {
      const collectionPoints = state.collectionPoints ?? [];
      const collectionPoint = action?.result?.collectionPoints?.[0];

      if (collectionPoint) {
        const collectionPointIndex = collectionPoints?.findIndex((point) => point.id === collectionPoint.id);

        const updatedCollectionPoint = {
          ...collectionPoints[collectionPointIndex],
          ...collectionPoint,
        };

        collectionPoints[collectionPointIndex] = updatedCollectionPoint;

        return {
          ...state,
          selectedCollectionPoint: updatedCollectionPoint,
          collectionPoints,
        };
      }

      return {
        ...state,
      };
    }

    case `${INIT_PAYMENT_PAGE}.SUCCESS`: {
      const confirmedDeliveryAddress = action?.result?.orderForm?.deliveryAddress;
      const rawAddressBook = action?.result?.customer?.addressBook ?? [];
      const mergedAddressBook = mergeAddressBook(state.addressBook ?? [], rawAddressBook);
      const addressBook = validateAddressBookRecords(mergedAddressBook);
      const isClickCollectOrder = !!action?.result?.orderForm?.deliveries?.[0]?.fulfilment?.collectionInfo;
      const signedInStatus = getSignedInStatus(action.result?.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(action.result?.customer?.loyaltyMembershipInfo, state.isSignedIn);

      // MARV-8747 handle the scenario where a signed in customer with no saved addresses has
      // an address book created for them during a failed/cancelled card payment
      const isSignedInWithData = addressBook.length > 0 && !isEmptyObject(rawAddressBook[0]);
      const addressBookNewlyCreated = state.addressBook?.length === 0 && isSignedInWithData;

      const contactAddress = addressBook?.find(
        (addressRecord) => addressRecord.contact && addressRecord.address?.countryCode === 'GB',
      );

      let selectedResidentialAddress;

      if (isClickCollectOrder) {
        selectedResidentialAddress = contactAddress;
      } else if (state.shouldUseDeliveryAddressAsResidentialAddress) {
        // use the customer's previously selected delivery address
        selectedResidentialAddress = confirmedDeliveryAddress;
      } else if (state.selectedResidentialAddress) {
        // use the customer's previously selected residential address
        selectedResidentialAddress = state.selectedResidentialAddress;
      } else if (contactAddress) {
        // use the customer's GB contact address as a last resort
        selectedResidentialAddress = contactAddress;
      }

      return {
        ...state,
        addressBook,
        residentialAddressIsDeliveryAddress: state.shouldUseDeliveryAddressAsResidentialAddress,
        selectedResidentialAddress,
        ...(addressBookNewlyCreated
          ? { selectedBillingAddress: addressBook[0], isSignedInWithData }
          : { selectedBillingAddress: state.selectedBillingAddress || contactAddress }),
        ...signedInStatus,
        ...loyaltyInfo,
        email: action.result?.customer?.email,
      };
    }

    case `${GET_PAYMENT_WALLET}.FAILED`: {
      return {
        ...state,
        fetchedPaymentWallet: true,
        paymentWalletApiCallFailed: true,
      };
    }

    case `${PAYMENT_CARD_DELETE}.FAILED`: {
      if (action?.error?.code === errorCodeConstants.CLIENT_PAYMENT_CARD_NOT_DELETED) {
        return {
          ...state,
          fetchedPaymentWallet: false,
          savedPaymentCards: [],
        };
      }

      return state;
    }

    case `${PAYMENT_CARD_DELETE}.SUCCESS`:
    case `${GET_PAYMENT_WALLET}.SUCCESS`: {
      const { selectedPaymentType, addressBook } = state;
      const rawPaymentWallet = action?.result?.paymentWallet ?? [];
      const paymentWallet = [...rawPaymentWallet].sort((a, b) => Number(b.default) - Number(a.default));
      const defaultSavedPaymentDetail = getDefaultPaymentCardOrFirst(paymentWallet) || {};
      const defaultBillingAddressId = defaultSavedPaymentDetail.addressId;

      let paymentCardAddressInAddressBook;

      if (!selectedPaymentType && defaultBillingAddressId) {
        paymentCardAddressInAddressBook =
          addressBook?.find((addressRecord) => addressRecord.id === defaultBillingAddressId) || false;
      }

      const selectedBillingAddress =
        paymentCardAddressInAddressBook ||
        getBillingAddressForPaymentType({
          addressBook,
          paymentType: selectedPaymentType,
          savedPaymentCards: paymentWallet,
        });

      return {
        ...state,
        savedPaymentCards: paymentWallet,
        selectedBillingAddress,
        defaultSavedPaymentDetail,
        fetchedPaymentWallet: true,
        paymentWalletApiCallFailed: false,
      };
    }

    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      // needed in scenarios where customer has left checkout and then returned
      const email = action?.result?.customer?.email;

      return {
        ...state,
        email: state.email || email,
        showMarketingPreferencesPrompt: action?.result?.marketingPreferencesPromptVisible,
      };
    }

    case `${PUT_DELIVERY_ADDRESS}.FAILED`: {
      if (action?.error?.code === errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR) {
        return {
          ...state,
          selectedDeliveryAddress: state.previousSelectedDeliveryAddress,
          previousSelectedDeliveryAddress: undefined,
        };
      }

      const postcodeOutOfAreaError = getPostcodeOutOfAreaError(action);

      if (action.isSavedAddress && !!state.selectedDeliveryAddress) {
        const selectedDeliveryAddressId = state?.selectedDeliveryAddress?.id;
        const addressBook = validateAddressBookRecords(state.addressBook ?? []);
        const selectedDeliveryAddress = addressBook?.find(
          (addressRecord) => addressRecord.id === selectedDeliveryAddressId,
        );

        return {
          ...state,
          addressBook,
          selectedDeliveryAddress,
          canUseSelectedDeliveryAddress: !selectedDeliveryAddress?.notAvailableForDelivery && !postcodeOutOfAreaError,
          postcodeOutOfAreaError,
        };
      }

      return {
        ...state,
        selectedDeliveryAddress: action.address,
        canUseSelectedDeliveryAddress: !postcodeOutOfAreaError,
        postcodeOutOfAreaError,
      };
    }

    case `${PUT_DELIVERY_ADDRESS}.SUCCESS`: {
      // TODO: Why do we persist selectedDeliveryAddress here?
      return {
        ...state,
        selectedDeliveryAddress: action.address,
        postcodeOutOfAreaError: undefined,
        previousSelectedDeliveryAddress: undefined,
      };
    }

    case `${PUT_ADDRESS_BOOK_UPDATE}.SUCCESS`: {
      const rawAddressBook = action?.result?.addressBook ?? [];
      const mergedAddressBook = mergeAddressBook(state.addressBook ?? [], rawAddressBook);

      const addressBook = validateAddressBookRecords(mergedAddressBook);

      return {
        ...state,
        addressBook,
      };
    }

    case `${GET_ADDRESS_BOOK}.SUCCESS`: {
      const rawAddressBook = action?.result?.addressBook ?? [];
      const mergedAddressBook = mergeAddressBook(state.addressBook ?? [], rawAddressBook);
      const addressBook = validateAddressBookRecords(mergedAddressBook);
      const isSignedInWithData = addressBook?.length > 0;

      return {
        ...state,
        addressBook,
        isSignedInWithData,
      };
    }

    case `${AUTH0_REGISTRATION_CALLBACK}.SUCCESS`: {
      const loginResponse = action.result?.loginResponse || {};
      const signedInStatus = getSignedInStatus(loginResponse.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(loginResponse.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        ...signedInStatus,
        ...loyaltyInfo,
        auth0RegistrationSuccess: true,
      };
    }

    case `${AUTH0_CLAIM_ORDER_CALLBACK}.SUCCESS`: {
      const result = action.result || {};
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        ...signedInStatus,
        ...loyaltyInfo,
        auth0ClaimOrderCallbackSuccess: true,
        orderSaved: true,
        validSession: result.validSession,
        claimOrderCallbackHappened: true,
      };
    }

    case `${AUTH0_CLAIM_ORDER_CALLBACK}.FAILED`: {
      return {
        ...state,
        auth0CallbackError: getErrorMessageObject({ error: action.error }),
        orderSaved: false,
        validSession: ![
          errorCodeConstants.SAVE_ORDER_TECHNICAL_ERROR_NO_SESSION,
          errorCodeConstants.SAVE_ORDER_INVALID_LINK,
        ].includes(action.error.code),
        claimOrderCallbackHappened: true,
      };
    }

    case `${AUTH0_HEALTH_CHECK}.FAILED`: {
      return {
        ...state,
        auth0CallbackError: getErrorMessageObject({ error: action.error }),
      };
    }

    case BILLING_ADDRESS_SELECT_ADDRESS: {
      return {
        ...state,
        previewSelectedBillingAddress: true,
      };
    }

    case USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS: {
      return {
        ...state,
        previewSelectedBillingAddress: false,
      };
    }

    case HIDE_SELECTED_BILLING_ADDRESS_PREVIEW: {
      return {
        ...state,
        previewSelectedBillingAddress: false,
      };
    }

    case MOUNT_POS_CREDIT_ADDRESS_FORM: {
      const invalidPOSCreditAddress = state.selectedResidentialAddress;

      return {
        ...state,
        invalidPOSCreditAddress,
        selectedResidentialAddress: undefined,
      };
    }

    case USE_DIFFERENT_RESIDENTIAL_ADDRESS: {
      return {
        ...state,
        selectedResidentialAddress: undefined,
        invalidPOSCreditAddress: undefined,
        shouldUseDeliveryAddressAsResidentialAddress: false,
      };
    }

    case USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS: {
      return {
        ...state,
        selectedResidentialAddress: action.confirmedDeliveryAddress,
        invalidPOSCreditAddress: undefined,
        shouldUseDeliveryAddressAsResidentialAddress: true,
      };
    }

    case DELIVERY_ADDRESS_SELECT_ADDRESS: {
      const addressValues = {
        ...state.userEnteredDeliveryAddress,
        ...action.address,
      };

      if (!isNameAndNumberComplete(state.userEnteredDeliveryAddress)) {
        return state;
      }

      return {
        ...state,
        selectedDeliveryAddress: validateAddress(getAddressFromFormValues(addressValues)),
      };
    }

    case CHANGE_DELIVERY_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddress: undefined,
        postcodeOutOfAreaError: undefined,
        canUseSelectedDeliveryAddress: true,
      };
    }

    case EDIT_DELIVERY_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddress: undefined,
        postcodeOutOfAreaError: undefined,
      };
    }

    case LOGIN_RESET:
      return {
        ...state,
        isSignedInWithData: false,
        addressBook: [],
        collectionPoints: [],
        savedPaymentCards: [],
        showMarketingPreferencesPrompt: false,
        selectedCollectionPoint: undefined,
        selectedBillingAddress: undefined,
        selectedDeliveryAddress: undefined,
        userEnteredDeliveryAddress: undefined,
        canUseSelectedDeliveryAddress: true,
        defaultAddress: undefined,
        eligibleForPartnerDiscount: false,
        partnerDiscountCardNumber: '',
        partnerDiscountStatus: '',
        partnerDiscountEnabled: false,
        isPartnerDiscountApiCallActive: false,
        disableAutoApplyPartnerDiscount: false,
        applyPartnerDiscountError: undefined,
        fetchedPaymentWallet: false,
        postcodeOutOfAreaError: undefined,
      };

    case SHOW_BATCHING_FAILURE_MODAL:
      return {
        ...state,
        allItemsHaveBatchingFailure: action.allItemsHaveBatchingFailure,
        batchingFailureItems: action.batchingFailureItems,
        showBatchingFailureModal: true,
      };

    case SHOW_REMOVE_BATCHING_FAILURE_ERROR:
      return {
        ...state,
        showRemoveBatchingFailureItemError: true,
      };

    case `${DELETE_COLLECTION_POINT}.SUCCESS`:
      return {
        ...state,
        collectionPoints: mapSavedCollectionPoints(action?.result?.collectionPoints ?? []),
      };

    case HAND_OVER_RESET:
      return INITIAL_STATE;

    case SET_SELECTED_DELIVERY_CHOICE_ID:
      if (action.id === deliveryConstants.CLICK_AND_COLLECT) {
        return {
          ...state,
          shouldUseDeliveryAddressAsResidentialAddress: false,
          selectedResidentialAddress: undefined,
        };
      }

      return state;

    case `${SIGN_OUT_USER}.LOADING`: {
      return {
        ...state,
        signOutApiCallActive: true,
      };
    }

    case `${SIGN_OUT_USER}.FAILED`: {
      return {
        ...state,
        signOutApiCallActive: false,
      };
    }

    case `${GET_AGE_VERIFICATION_SESSION}.LOADING`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: true,
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_SESSION}.SUCCESS`: {
      const { sessionId, sdkId } = action.result ?? {};

      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        yotiLoading: false,
        yotiQueryParams: `sessionId=${sessionId}&sdkId=${sdkId}`,
        yotiSessionId: sessionId,
      };
    }

    case SET_AGE_VERIFICATION_LOADING_STATE:
      return {
        ...state,
        yotiLoading: action.loadingState,
      };

    case SET_AGE_VERIFICATION_CONFIRMATION_STATE:
      return {
        ...state,
        showYotiConfirmationModal: action.confirmationState,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.FAILED`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        yotiError: action.error.code,
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.LOADING`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: true,
        yotiError: '',
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.SUCCESS`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        showYotiConfirmationModal: true,
        yotiError: '',
        yotiCheckSuccess: true,
        yotiLoading: true,
      };

    case REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET:
      return {
        ...state,
        showAgeCheckModal: false,
      };

    default:
      return state;
  }
};

export default userReducer;
