/**
 * Parses an analytics pageName into an analytics breadcrumb array
 * @param pageName {string} : 'jl:checkout:login'
 * @returns {array} : [{ value: 'checkout', clickable: 1 }, { value: 'login', clickable: 1 }]
 */
export default function getBreadcrumb(pageName = '') {
  if (typeof pageName !== 'string') {
    return [];
  }

  const chunks = pageName.split(':');

  return chunks.reduce((crumbs, value) => {
    if (!value || value === 'jl') {
      return crumbs;
    }
    return crumbs.concat({
      value,
      clickable: 1,
    });
  }, []);
}
