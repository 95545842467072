// Types
import { AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import { importRavelin } from './ravelin.config';
import featureConstants from '@constants/featureConstants';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { reportErrorToNewRelic } from '@utils/logging/logging-utils';

export const initRavelin = () => async (_dispatch: AppDispatch, getState: AppGetState) => {
  const featureDisabled = isFeatureActive(getState(), featureConstants.RAVELINJS_DISABLED);
  const api = window.env?.RAVELIN_API;
  const key = window.env?.RAVELIN_KEY;

  if (!featureDisabled && key) {
    try {
      const Ravelin = await importRavelin();

      if (typeof Ravelin !== 'function') {
        reportErrorToNewRelic({
          errorDescription: 'Ravelin is not a function',
        });
        return;
      }

      const ravelinOptions: { api?: string; key: string } = { key };
      if (api) ravelinOptions.api = api;

      new Ravelin(ravelinOptions);
    } catch (error) {
      if (error instanceof Error) {
        reportErrorToNewRelic({
          error,
          errorDescription: 'Failed to initialise Ravelin',
        });
      }
    }
  }
};
