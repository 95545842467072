import { replace } from 'connected-react-router';

// Types
import { AppDispatch, AppGetState } from 'types/RootState.types';
import { FeatureFlagType } from 'types/FeatureFlag.types';

// Config
import { recordImpressions } from '@redux/actions/app/appActions';
import { SHOULD_USE_FASTER_COLLECTION } from '@constants/actionConstants';
import featureConstants from '@constants/featureConstants';
import routeConstants from '@constants/routeConstants';
import { isFeatureActive } from '@redux/reducers/config/configReducer';

export const shouldUseFasterCollection = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const collectionPointsSaved = state?.user?.collectionPointsSaved;
  const isSignedIn = state?.user?.isSignedIn;
  const features = state?.config?.features;

  if (collectionPointsSaved && isSignedIn) {
    const fasterCollectionFeature = features?.find(
      (feature) => feature.id === featureConstants.FASTER_COLLECTION_PAGE,
    ) ?? {} as FeatureFlagType;

    const impressionId = fasterCollectionFeature.impressionId;
    if (impressionId) {
      dispatch(recordImpressions(impressionId));
    }

    if (isFeatureActive(state, featureConstants.FASTER_COLLECTION_PAGE)) {
      dispatch(replace(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED));
      dispatch({
        type: SHOULD_USE_FASTER_COLLECTION,
      });

      return true;
    }
  }

  return false;
};
