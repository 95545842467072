// Types
import { AppDispatch, AppClient, AppGetState } from 'types/RootState.types';

//
import {
  APPLY_REWARDS,
  REMOVE_REWARDS,
} from '@constants/actionConstants';
import {
  URL_REWARDS_APPLY,
  URL_REWARDS_REMOVE,
} from '@constants/endpointConstants';
import { getItems } from '@redux/actions/edit-basket/editBasketActions';
import { shouldApplyRewards } from '@utils/payment/shouldApplyRewards';
//
import determineSelectedPaymentType from './selectedPaymentTypeActions';
import { getPayments } from './paymentActions';

export const applyRewards = ({
  shouldGetPayments = false,
  isCustomerAction = false,
  shouldFallbackToItems = true,
  deliveriesV3FeatureActive = false,
} = {}) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();

  if (!isCustomerAction && !shouldApplyRewards(state)) return;

  const response = await dispatch({
    type: APPLY_REWARDS,
    request: (client: AppClient) => client({ path: URL_REWARDS_APPLY, config: { method: 'POST' } }),
    isCustomerAction,
    deliveries: state?.delivery?.deliveries,
    ...deliveriesV3FeatureActive && { deliveriesV3FeatureActive },
  });

  if (response?.type?.includes('FAILED') && shouldFallbackToItems) await dispatch(getItems({ deliveriesV3FeatureActive }));

  if (shouldGetPayments) {
    await dispatch(getPayments());
    dispatch(determineSelectedPaymentType());
  }

  return response;
};

export const removeRewards = (campaignId: string) => async (dispatch: AppDispatch) => {
  await dispatch({
    type: REMOVE_REWARDS,
    request: (client: AppClient) => client({ path: URL_REWARDS_REMOVE(campaignId), config: { method: 'DELETE' } }),
  });
  await dispatch(getPayments());
  dispatch(determineSelectedPaymentType());
};
