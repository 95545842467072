// Design System
import amexLogo from 'jl-design-system/assets/images/payment-icons/amex.svg';
import applePayLogo from 'jl-design-system/assets/images/payment-icons/apple-pay.svg';
import businessAccountLogo from 'jl-design-system/assets/images/payment-icons/business-account.png';
import googlePayLogo from 'jl-design-system/assets/images/payment-icons/google-pay.svg';
import maestroLogo from 'jl-design-system/assets/images/payment-icons/maestro.svg';
import mastercardLogo from 'jl-design-system/assets/images/payment-icons/mastercard.svg';
import partnership2022Logo from 'jl-design-system/assets/images/payment-icons/partnership-2022.png';
import paypalAccountLogo from 'jl-design-system/assets/images/payment-icons/paypal.svg';
import visaLogo from 'jl-design-system/assets/images/payment-icons/visa.svg';

// Types
import { PaymentType } from 'types/Payment.types';
import { PaymentDetailsProps } from './PaymentDetails.types';

// Config
import { paymentTypeConfig, paymentTypeLabels } from './PaymentDetails.constants';
import clearpayLogo from '@assets/images/logos/clearpay.png';
import klarnaLogo from '@assets/images/logos/klarna.svg';
import paymentTypeConstants from '@constants/paymentTypeConstants';
import supportedCreditCardTypes from '@constants/supportedCreditCardTypes';
import { getLast4Digits } from '@utils/helpers/cardDetails';

// Components
import CreditRemainingAmount from '@components/account-credit-remaining-amount';

// Styles
import styles from './payment-details.scss';

const PaymentDetails = ({ payments = [] }: PaymentDetailsProps) => {
  const cardTypeMapper = (cardType: string) =>
    ({
      [supportedCreditCardTypes.AMERICAN_EXPRESS]: amexLogo,
      [supportedCreditCardTypes.MAESTRO]: maestroLogo,
      [supportedCreditCardTypes.MASTERCARD]: mastercardLogo,
      [supportedCreditCardTypes.ANY_DAY]: mastercardLogo,
      [supportedCreditCardTypes.NEW_DAY]: partnership2022Logo,
      [supportedCreditCardTypes.VISA]: visaLogo,
      [supportedCreditCardTypes.VISA_DEBIT]: visaLogo,
      [supportedCreditCardTypes.BUSINESS_ACCOUNT]: businessAccountLogo,
      paypal: paypalAccountLogo,
      clearpay: clearpayLogo,
      klarna: klarnaLogo,
    })[cardType.toLowerCase()];

  const paymentItems = payments.map((payment = {}) => {
    const paymentType: PaymentType = (payment.type ?? '').split(':')[0] as PaymentType;
    let imageProps;

    if (!paymentType) {
      return null;
    }

    switch (paymentType) {
      case paymentTypeConstants.CREDIT_CARD:
      case paymentTypeConstants.TOKENIZED_PAYMENT_CARD:
      case paymentTypeConstants.SAVED_PAYMENT_CARD: {
        const type = payment?.details?.creditCardType ?? '';

        imageProps = {
          src: cardTypeMapper(type),
          alt: type,
          className:
            type === supportedCreditCardTypes.BUSINESS_ACCOUNT
              ? styles.paymentTypeLogoBusinessAccount
              : styles.paymentTypeLogo,
        };
        break;
      }
      case paymentTypeConstants.PAYPAL:
        imageProps = {
          src: paypalAccountLogo,
          alt: paymentType,
          className: styles.paymentTypeLogo,
        };
        break;
      case paymentTypeConstants.APPLE_PAY:
        imageProps = {
          src: applePayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogoApplePay,
        };
        break;
      case paymentTypeConstants.GOOGLE_PAY:
        imageProps = {
          src: googlePayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogoGooglePay,
        };
        break;
      case paymentTypeConstants.CLEARPAY:
        imageProps = {
          src: clearpayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogo,
        };
        break;
      case paymentTypeConstants.KLARNA:
        imageProps = {
          src: klarnaLogo,
          alt: paymentType,
          className: styles.paymentTypeLogo,
        };
        break;

      default:
    }

    const cardType =
      paymentType === paymentTypeConstants.CREDIT_CARD ||
      paymentType === paymentTypeConstants.TOKENIZED_PAYMENT_CARD ||
      paymentType === paymentTypeConstants.SAVED_PAYMENT_CARD;

    const cardWithRemainingBalance = payment?.cardWithRemainingBalance;
    const isPosCredit = paymentType?.includes(paymentTypeConstants.POS_CREDIT) || false;
    const amountLabel = isPosCredit ? 'Agreement no.' : 'Amount:';
    const amountText = isPosCredit
      ? `ending in ${payment?.posCreditDetails?.agreementNumberLastDigits}`
      : payment?.amount;

    const cardTestId = paymentTypeConfig[paymentType] || '';
    let cardNumber = payment?.details?.maskedNumber ?? payment?.details?.lastDigits ?? '';

    if (cardNumber.length > 4) {
      cardNumber = getLast4Digits(cardNumber);
    }

    return (
      <div key={paymentType}>
        <dl data-testid="payment-details-item">
          {(!cardType || (cardType && !!cardTypeMapper(payment?.details?.creditCardType ?? ''))) && (
            <div data-testid="payment-details-item-type">
              <dt>Payment type:</dt>
              {imageProps ? (
                <dd>
                  <img {...imageProps} alt={imageProps.alt} data-testid={`payment-details-image-${paymentType}`} />
                </dd>
              ) : (
                <dd>{paymentTypeLabels[paymentType]}</dd>
              )}
            </div>
          )}

          {cardNumber && (
            <div data-testid={cardTestId}>
              <dt>Card number:</dt>
              <dd>{cardNumber}</dd>
            </div>
          )}

          <div data-testid="payment-details-item-amount">
            <dt>{amountLabel}</dt>
            <dd>{amountText}</dd>
          </div>

          {paymentType === paymentTypeConstants.GIFT_CARD && cardWithRemainingBalance && (
            <CreditRemainingAmount
              amount={cardWithRemainingBalance.remainingBalance}
              cardEnding={cardWithRemainingBalance.endNumber}
              className={styles.creditRemaining}
              testId="payment-details-gift-card-credit-remaining"
              type={paymentTypeConstants.GIFT_CARD}
            />
          )}
        </dl>
      </div>
    );
  });

  return <div className={styles.container}>{paymentItems}</div>;
};

export default PaymentDetails;
