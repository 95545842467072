import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { shouldShowLimitedStockModal } from './components/limited-stock-modal';
import featureConstants from './constants/featureConstants';
import { shouldLoadPaymentWalletStubs } from './non-prod-utils/utils/paymentWallet/loadPaymentWalletStubs';
import { shouldShowAgeVerificationCheck } from './redux/actions/age-verification/ageVerificationActions';
import { getPlatformType, isApps, isIosApp } from './redux/reducers/app/appReducer';
import { getBasketUrlSelector, getBrowseUrlSelector } from './redux/reducers/bff/bffSelector';
import { isFeatureActive } from './redux/reducers/config/configReducer';
import {
  getEditBasketModalBaseRoutePathSelector,
  getShowEditBasketModalSelector,
} from './redux/reducers/delivery/deliverySelector';
import { getErrorSelector } from './redux/reducers/error/errorSelector';
import { getRouterLocationPathname } from './redux/reducers/router/routerSelector';
import { getUserIsGuestSelector, getShowBatchingFailureModalSelector } from './redux/reducers/user/userSelector';
import {
  getAppLoadingMessageSelector,
  getAppIsInvalidPartnerDiscountSelector,
  getLeaveCheckoutModalBaseRoutePathSelector,
  getAppOrderCompleteSelector,
  getAppOrderConfirmationPageSuccessSelector,
  getAppPosCreditSessionExpiredSelector,
  getAppSessionExpiredSelector,
  getAppShowConnectivityErrorModalSelector,
  getAppShowDisableSiteSpinnerSelector,
  getAppShowEmptyBasketNotificationSelector,
  getAppShowExpressPayments,
  getAppShowLeaveCheckoutSelector,
  getAppSubmitOrderTimeoutSelector,
} from './redux/reducers/app/appSelector';
import {
  getOrderFormOrderNeedsToBeRebatchedSelector,
  getOrderFormOutOfStockItemsSelector,
} from './redux/reducers/order-form/orderFormSelector';
import { shouldCenterVertical } from './utils/app/shouldCenterVertical';
import { shouldShowUserActiveContent } from './utils/app/shouldShowUserActiveContent';
import shouldShowFullScreenSignUp from './utils/myjl/fullScreenSignUp';
import showMinimalSaveOrder from './utils/orderform/showMinimalSaveOrder';
import { shouldShowKlarnaFeature } from './utils/payment/isBasketKlarnaEligible';

const getShouldCenterVertical = (state: RootState) => shouldCenterVertical(state);
const getLoadPaymentWalletStubs = (state: RootState) => shouldLoadPaymentWalletStubs(state);
const getPaymentWalletStubName = (state: RootState) => isIosApp(state) ? 'applePayPaymentInitOnIosApp' : 'applePayPaymentInitOnWeb';
const getPlatformTypeSelector = (state: RootState) => getPlatformType(state);
const getShouldShowKlarnaFeature = (state: RootState) => shouldShowKlarnaFeature(state);
const getShowMinimalSaveOrder = (state: RootState) => showMinimalSaveOrder(state) || false;
const getShouldShowFullScreenSignUp = (state: RootState) => shouldShowFullScreenSignUp(state);
const getShowHeader = (state: RootState) => !isApps(state);
const getShowLimitedStockModal = (state: RootState) => {
  return state?.orderForm?.limitedStockItems
    ? shouldShowLimitedStockModal({
      amendQuantitiesApiCallActive: state?.orderForm?.amendQuantitiesApiCallActive,
      limitedStockItems: state?.orderForm?.limitedStockItems,
    }) : false;
};
const getShowRecommendations = (state: RootState) =>
  !isApps(state) && isFeatureActive(state, featureConstants.SHOW_RECOMMENDATIONS);
const getShowUnavailableItemsModal = (state: RootState) => {
  const unavailableItemsInBasket = state?.orderForm?.unavailableItemInfo?.unavailableItemsInBasket ?? [];
  return unavailableItemsInBasket?.length > 0;
};
const getShouldShowUserActiveContent = (state: RootState) => shouldShowUserActiveContent(state);
const getIsLoading = (state: RootState) => {
  const ageVerificationFeatureActive = shouldShowAgeVerificationCheck(state);
  const hasItemsNeedingAgeVerification =
    !state?.user?.ageCheckSuccess &&
    ((ageVerificationFeatureActive && (state?.delivery?.itemsNeedingAgeVerification?.length ?? 0) > 0) ||
      state?.user?.ageCheckRequired);
  return (state?.app?.isLoading || (!state?.app?.sessionExpired && hasItemsNeedingAgeVerification));
};

const appState = createSelector(
  [
    getBasketUrlSelector,
    getBrowseUrlSelector,
    getShouldCenterVertical,
    getEditBasketModalBaseRoutePathSelector,
    getErrorSelector,
    getUserIsGuestSelector,
    getAppIsInvalidPartnerDiscountSelector,
    getIsLoading,
    getLeaveCheckoutModalBaseRoutePathSelector,
    getLoadPaymentWalletStubs,
    getAppLoadingMessageSelector,
    getAppOrderCompleteSelector,
    getAppOrderConfirmationPageSuccessSelector,
    getOrderFormOrderNeedsToBeRebatchedSelector,
    getOrderFormOutOfStockItemsSelector,
    getRouterLocationPathname,
    getPaymentWalletStubName,
    getPlatformTypeSelector,
    getAppPosCreditSessionExpiredSelector,
    getAppSessionExpiredSelector,
    getShouldShowKlarnaFeature,
    getShowMinimalSaveOrder,
    getShowBatchingFailureModalSelector,
    getAppShowConnectivityErrorModalSelector,
    getAppShowDisableSiteSpinnerSelector,
    getShowEditBasketModalSelector,
    getAppShowEmptyBasketNotificationSelector,
    getAppShowExpressPayments,
    getShouldShowFullScreenSignUp,
    getShowHeader,
    getAppShowLeaveCheckoutSelector,
    getShowLimitedStockModal,
    getShowRecommendations,
    getShowUnavailableItemsModal,
    getShouldShowUserActiveContent,
    getAppSubmitOrderTimeoutSelector,
  ],
  (
    basketUrl,
    browseUrl,
    centerVertical,
    editBasketModalBaseRoutePath,
    globalError,
    isGuest,
    isInvalidPartnerDiscount,
    isLoading,
    leaveCheckoutModalBaseRoutePath,
    loadPaymentWalletStubs,
    loadingMessage,
    orderComplete,
    orderConfirmationPageSuccess,
    orderNeedsToBeRebatched,
    outOfStockItems,
    pathname,
    paymentWalletStubName,
    platformType,
    posCreditSessionExpired,
    sessionExpired,
    shouldShowKlarnaContainer,
    shouldShowMinimalSaveOrder,
    showBatchingFailureModal,
    showConnectivityErrorModal,
    showDisableSiteSpinner,
    showEditBasketModal,
    showEmptyBasketNotification,
    showExpressPayments,
    showFullScreenSignup,
    showHeader,
    showLeaveCheckoutModal,
    showLimitedStockModal,
    showRecommendations,
    showUnavailableItemsModal,
    showUserActiveContent,
    submitOrderTimeout,
  ) => {

    return {
      basketUrl,
      browseUrl,
      centerVertical,
      editBasketModalBaseRoutePath,
      globalError,
      isGuest,
      isInvalidPartnerDiscount,
      isLoading,
      leaveCheckoutModalBaseRoutePath,
      loadPaymentWalletStubs,
      loadingMessage,
      orderComplete,
      orderConfirmationPageSuccess,
      orderNeedsToBeRebatched,
      outOfStockItems,
      pathname,
      paymentWalletStubName,
      platformType,
      posCreditSessionExpired,
      sessionExpired,
      shouldShowKlarnaContainer,
      shouldShowMinimalSaveOrder,
      showBatchingFailureModal,
      showConnectivityErrorModal,
      showDisableSiteSpinner,
      showEditBasketModal,
      showEmptyBasketNotification,
      showExpressPayments,
      showFullScreenSignup,
      showHeader,
      showLeaveCheckoutModal,
      showLimitedStockModal,
      showRecommendations,
      showUnavailableItemsModal,
      showUserActiveContent,
      submitOrderTimeout,
    };
  },
);

export default appState;
