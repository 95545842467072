export function isFormMounted(form) {
  if (!form || !form?.registeredFields) {
    return false;
  }

  return !!Object.values(form.registeredFields).find(({ count }) => !!count);
}

export function getRegisteredFormFieldNames(form, mountedOnly = false) {
  const fields = form?.registeredFields ?? {};

  if (mountedOnly) {
    return Object.keys(fields).reduce((acc, fieldName) => (
      fields[fieldName].count ? [...acc, fieldName] : acc
    ), []);
  }

  return Object.keys(fields).map(fieldName => fieldName);
}
