function deepMerge(obj1: any, obj2: any) {
  if (!obj1 || typeof obj1 !== 'object' || obj1 === null) return obj2;
  if (!obj2 || typeof obj2 !== 'object' || obj2 === null) return obj1;

  return [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])].reduce((acc: any, key: any) => {
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      acc[key] = obj1[key].map((item: any, index: number) => {
        if (typeof item === 'object' && obj2[key][index]) {
          return deepMerge(item, obj2[key][index]);
        }
        return obj2[key][index] !== undefined ? obj2[key][index] : item;
      });

      if (obj2[key].length > obj1[key].length) {
        acc[key] = [...acc[key], ...obj2[key].slice(obj1[key].length)];
      }
    } else if (
      obj1[key] &&
      typeof obj1[key] === 'object' &&
      obj1[key] !== null &&
      obj2[key] &&
      typeof obj2[key] === 'object' &&
      obj2[key] !== null
    ) {
      acc[key] = deepMerge(obj1[key], obj2[key]);
    } else {
      acc[key] = obj2[key] !== undefined ? obj2[key] : obj1[key];
    }
    return acc;
  }, {});
}

export default deepMerge;
