import { goBack } from 'connected-react-router';

// Design System
import { setFieldValue } from 'jl-design-system/redux/actions/form/formActions';

// Config
import {
  POST_ADDRESS_BOOK_CREATE,
  PUT_ADDRESS_BOOK_UPDATE,
  GET_ADDRESS_BOOK,
  SET_ADDRESS_BOOK_EDITABLE_ADDRESS,
  PUT_DELIVERY_ADDRESS,
  REMOVE_ADDRESS,
  SHOW_REMOVE_ADDRESS_OVERLAY,
  HIDE_REMOVE_ADDRESS_OVERLAY,
  UPDATE_SAVED_AS_DEFAULT_ADDRESS,
} from '@constants/actionConstants';
import { URL_ADDRESSES, URL_REMOVE_ADDRESS } from '@constants/endpointConstants';
import errorCodeConstants from '@constants/errorCodeConstants';
import { setDeliveryAddressAndGetDeliveryMethods } from '@redux/actions/delivery/deliveryActions';
import { setScrollToDeliveryDetails } from '@redux/actions/app/appActions';
import { getAddressBookAddressPayloadFromFormValues } from '@utils/address/addressHelpers';
import { BILLING_ADDRESS_FORM_ID, RESIDENTIAL_ADDRESS_FORM_ID } from '@utils/form/configs/billingAddress';
import { deepEqual, isEmptyObject } from '@utils/object';

const selectBillingAddress = ({
  selectedAddressId,
  handleModalClose,
  formId,
}) => async (dispatch) => {
  await dispatch(setFieldValue(formId, 'id', selectedAddressId));
  handleModalClose();
};

const selectDeliveryAddress = ({
  selectedAddressId,
  handleModalClose,
  formId,
  unsavedAddress,
}) => async (dispatch, getState) => {

  const selectedAddress = selectedAddressId
    ? getState().user.addressBook?.find(addressRecord => addressRecord.id === selectedAddressId) : unsavedAddress;

  if (selectedAddressId) {
    await dispatch(setFieldValue(formId, 'id', selectedAddressId));
  }

  dispatch(setScrollToDeliveryDetails(true));

  const response = await dispatch(setDeliveryAddressAndGetDeliveryMethods({ address: selectedAddress }));

  if (response?.type === `${PUT_DELIVERY_ADDRESS}.FAILED` && response?.error?.code === errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR) {
    return;
  }

  if (!response) {
    return;
  }

  if (response.type === `${PUT_DELIVERY_ADDRESS}.SUCCESS`) {
    const getDeliveryMethodsError = getState()?.delivery?.getDeliveryMethodsError;

    if (getDeliveryMethodsError) {
      dispatch(goBack());
    } else if (handleModalClose) {
      handleModalClose();
    }
    return;
  }

  if (typeof handleModalClose === 'function') {
    handleModalClose();
  }
};

export const selectAddress = ({
  selectedAddressId,
  handleModalClose,
  formId,
  unsavedAddress,
}) => (dispatch) => {

  if (formId === RESIDENTIAL_ADDRESS_FORM_ID) {
    return dispatch(selectBillingAddress({
      selectedAddressId,
      handleModalClose,
      formId,
      unsavedAddress,
    }));
  }

  if (formId === BILLING_ADDRESS_FORM_ID) {
    return dispatch(selectBillingAddress({
      selectedAddressId,
      handleModalClose,
      formId,
      unsavedAddress,
    }));
  }

  return dispatch(selectDeliveryAddress({
    selectedAddressId,
    handleModalClose,
    formId,
    unsavedAddress,
  }));
};

export const removeAddress = addressId => async (dispatch) => {
  const response = await dispatch({
    type: REMOVE_ADDRESS,
    request: client => client({
      path: URL_REMOVE_ADDRESS(addressId),
      config: {
        method: 'DELETE',
      },
    }),
  });

  if (response.type === `${REMOVE_ADDRESS}.SUCCESS`) {
    dispatch({
      type: GET_ADDRESS_BOOK,
      request: client => client({ path: URL_ADDRESSES, config: { method: 'GET' } }),
    });
  }
};

export const editAddress = (selectedAddressId, invalid) => ({
  type: SET_ADDRESS_BOOK_EDITABLE_ADDRESS,
  selectedAddressId,
  invalid,
});

export const updateSavedAsDefaultAddressId = addressId => ({
  type: UPDATE_SAVED_AS_DEFAULT_ADDRESS,
  addressId,
});

export const showRemoveAddressOverlay = addressId => ({
  type: SHOW_REMOVE_ADDRESS_OVERLAY,
  addressId,
});

export const hideRemoveAddressOverlay = () => ({
  type: HIDE_REMOVE_ADDRESS_OVERLAY,
});

export const createAddress = ({ formValues = {}, handleModalClose, formId, addressPayload }) => dispatch => (
  new Promise(async (resolve) => {
    const submitPayload = addressPayload || getAddressBookAddressPayloadFromFormValues(formValues);

    const response = await dispatch({
      type: POST_ADDRESS_BOOK_CREATE,
      request: client => client({
        path: URL_ADDRESSES,
        config: {
          method: 'POST',
          body: submitPayload,
        },
      }),
    });

    const getAddressBookResponse = await dispatch({
      type: GET_ADDRESS_BOOK,
      request: client => client({ path: URL_ADDRESSES, config: { method: 'GET' } }),
    });

    if (response.type === `${POST_ADDRESS_BOOK_CREATE}.SUCCESS` &&
    getAddressBookResponse.type === `${GET_ADDRESS_BOOK}.SUCCESS`) {
      await dispatch(selectAddress({
        selectedAddressId: response.result.id,
        handleModalClose,
        formId,
      }));
    } else if (response.type === `${POST_ADDRESS_BOOK_CREATE}.FAILED` ||
    getAddressBookResponse.type === `${GET_ADDRESS_BOOK}.FAILED`) {
      await dispatch(selectAddress({
        handleModalClose,
        formId,
        unsavedAddress: submitPayload,
      }));
    }

    resolve();
  })
);

export const putUpdatedAddress = ({ body }) => ({
  type: PUT_ADDRESS_BOOK_UPDATE,
  request: client => client({
    path: URL_ADDRESSES,
    config: {
      method: 'PUT',
      body,
    },
  }),
});

export const updateAddress = ({
  formId,
  formValues = {},
  handleModalClose = () => {},
}) => (dispatch, getState) => (
  new Promise(async (resolve) => {
    const submitPayload = getAddressBookAddressPayloadFromFormValues(formValues);

    if (!getState()?.user?.isSignedIn) {
      await dispatch(selectAddress({
        handleModalClose,
        formId,
        unsavedAddress: {
          ...submitPayload,
          id: undefined,
        },
      }));

      resolve();
      return;
    }

    const response = await dispatch(putUpdatedAddress({ body: submitPayload }));

    if (response.type === `${PUT_ADDRESS_BOOK_UPDATE}.SUCCESS`) {
      // re-select previously selected address for editing to remove empty fields flash
      // and propery handle navigation back to edit address modal
      const addressBook = response?.result?.addressBook;
      const removeEmpty = (address = {}) => Object.fromEntries(
        Object.entries(address).filter(([_, value]) => value != null && !isEmptyObject(value)),
      );

      const formattedPayload = {
        address: removeEmpty(submitPayload.address),
        addressee: { ...submitPayload.addressee },
        phoneNumber: submitPayload.phoneNumber,
      };

      const foundAddress = addressBook.find((addressRecord) => {
        const formattedAddress = {
          address: removeEmpty(addressRecord.address),
          addressee: { ...addressRecord.addressee },
          phoneNumber: addressRecord.phoneNumber,
        };

        return deepEqual(formattedAddress, formattedPayload);
      });

      if (foundAddress && foundAddress.id) {
        const { editableAddress, addressIdToBeSavedAsDefault } = getState()?.addressBook ?? {};
        if (addressIdToBeSavedAsDefault === editableAddress) {
          await dispatch(updateSavedAsDefaultAddressId(foundAddress.id));
        }
        await dispatch(editAddress(foundAddress.id));
      }

      // FIXME What is this contact logic for?
      let selectedAddressId = formValues.id;
      if (submitPayload.contact) {
        const foundAddress = response.result.addressBook.find(address => address.contact) || { id: null };
        selectedAddressId = foundAddress.id;
      }
      await dispatch(selectAddress({
        selectedAddressId,
        handleModalClose,
        formId,
      }));
    } else if (response.type === `${PUT_ADDRESS_BOOK_UPDATE}.FAILED`) {
      await dispatch(selectAddress({
        handleModalClose,
        formId,
        unsavedAddress: {
          ...submitPayload,
          id: undefined,
        },
      }));
    }

    resolve();
  })
);
