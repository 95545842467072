import { ReactNode } from 'react';
import cx from 'classnames';

// Types
import { CreditRemainingAmountProps } from './CreditRemainingAmount.types';

// Config
import paymentTypeConstants from '@constants/paymentTypeConstants';
import isZeroAmount from '@utils/payment/isZeroAmount';

// Components
import Container from '@components/container';

// Styles
import styles from './credit-remaining-amount.scss';

const CreditRemainingAmount = ({
  amount = '',
  cardEnding = '',
  className = '',
  testId,
  infoMessage = '',
  type,
}: CreditRemainingAmountProps) => {
  if (!type || isZeroAmount(amount)) {
    return null;
  }

  let text: string | ReactNode = '';
  if (type === paymentTypeConstants.GIFT_CARD) {
    text = `${amount} still remains on your gift card ending in ${cardEnding}. Keep it safe!`;
  }

  if (type === paymentTypeConstants.GIFT_VOUCHER) {
    text = (
      <>
        As your total order amount is less than your gift voucher value, <strong>{amount}</strong> will be lost and you
        won&apos;t be able to use it for future purchases.
      </>
    );
  }

  return (
    <Container className={cx({ [className]: !!className })} marginBottom="2" testId={testId}>
      <Container
        className={cx(styles.content, {
          [styles.warning]: type === paymentTypeConstants.GIFT_VOUCHER,
        })}
        flex={{ alignItems: 'start' }}
      >
        <p className={styles.amount}>{text}</p>
      </Container>
      {infoMessage && <p className={styles.caption}>{infoMessage}</p>}
    </Container>
  );
};

export default CreditRemainingAmount;
